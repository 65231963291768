import { JobClient } from "../config"

export const createJobPost = async (jobData) => {
  try
  {
    const response = await JobClient.post(`create`, jobData);
    return response;
  } catch (error)
  {
    if (error.response && error.response.status === 500)
    {
      return error.response;
    }
    throw error;
  }
};

export const sendJobApplication = async (jobData) => {
  try
  {
    const response = await JobClient.post(`send-job-application`, jobData);
    return response;
  } catch (error)
  {
    if (error.response && error.response.status === 500)
    {
      return error.response;
    }
    throw error;
  }
};


export const getAllJobPosts = async (limit, offset, filter = {}) => {
  console.log(limit, offset, filter)
  try
  {
    const response = await JobClient.get("all", {
      params: { limit, offset, ...filter }
    });
    return response;
  } catch (error)
  {
    console.log(error)
    if (error.response && error.response.status === 500)
    {
      return error.response;
    }
    throw error;
  }
}


export const updateJobPost = async (jobPostId, data) => {
  try
  {
    const response = await JobClient.put(`${jobPostId}`, data);
    return response;
  } catch (error)
  {
    if (error.response && error.response.status === 500)
    {
      return error.response;
    }
    throw error;
  }
};

export const deleteJobPost = async (jobPostId, userId) => {
  try
  {
    const response = await JobClient.delete(`delete`, {
      params: { jobPostId, userId }
    });
    return response;
  } catch (error)
  {
    if (error.response && error.response.status === 500)
    {
      return error.response;
    }
    throw error;
  }
};


export const toggleBookmarkJob = async (data, action) => {
  try
  {
    const response = await JobClient.post(`bookmark`, data, {
      params: { action: action },
    });
    return response;
  } catch (error)
  {
    if (error.response && error.response.status === 500)
    {
      return error.response;
    }
    throw error;
  }
}
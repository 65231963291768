import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Alert, Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useState, useEffect } from "react";
import OtpInput from "react-otp-input";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { sendOtp, verifyOtp } from "../../api/User/user";
import FormBtn from "../../component/Common/FormBtn";
import { TermsAndPolicyFooter } from "../../component/Common/TermsAndPolicyFooter";
import { LOADING_STATUS } from "../../constants/constants";
import { setOnBoardingData } from "../../redux/Slices/onBoardingSlice";

const OtpVarification = ({}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [otp, setOtp] = useState("");
  const [otpStatus, setOtpStatus] = useState("");
  const [otpFetchingError, setOtpFetchingError] = useState("");
  const [otpError, setOtpError] = useState("");
  const [isOtpInputDisabled, setIsOtpInputDisbled] = useState(true);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [isFirstTimeOtpSend, setIsFirstTimeOtpSend] = useState(true);
  const [timer, setTimer] = useState(29);
  const [canResendOtp, setCanResendOtp] = useState(false);

  const formData = useSelector((state) => state.onboarding);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleChange = (code) => {
    setOtpError("");
    setOtp(code);
  };

  const startTimer = () => {
    setCanResendOtp(false);
    setTimer(29); // Reset the timer to 29 seconds
  };

  // Countdown timer logic
  useEffect(() => {
    let countdown;
    if (timer > 0) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setCanResendOtp(true); // Enable resend button after timer ends
    }
    return () => clearInterval(countdown); // Cleanup the interval on component unmount
  }, [timer]);

  const onContinue = async () => {
    try {
      let phoneNumber = `+${formData.phone_number}`;
      const response = await verifyOtp({ phone: phoneNumber, otp });
      if (response.data.data.isPhoneVerified) {
        dispatch(
          setOnBoardingData({
            ...formData,
            is_phone_verified: true,
            active_onboarding_step: 1,
          })
        );
        navigate("/onboarding");
      } else {
        dispatch(
          setOnBoardingData({
            ...formData,
            is_phone_verified: false,
          })
        );
        setOtpError("Invalid Otp.");
      }
    } catch (error) {
      setOtpError("Error while verifying otp.");
    }
  };

  const onCaptchaChange = async (value) => {
    let phoneNumber = `+${formData.phone_number}`;
    if (value) {
      setRecaptchaToken(value);
      setIsOtpInputDisbled(false); // Enable OTP input once captcha is solved
      try {
        if (isFirstTimeOtpSend) {
          setOtpStatus(LOADING_STATUS.LOADING);
          const response = await sendOtp({ phone: phoneNumber });
          setOtpStatus(LOADING_STATUS.LOADED);
          setIsFirstTimeOtpSend(false);
          startTimer(); // Start the timer after the first OTP is sent
        }
      } catch (error) {
        setOtpStatus(LOADING_STATUS.FAILED);
        setOtpFetchingError("Error while sending OTP");
        console.error("Error while sending OTP", error);
      }
    }
  };

  const onCaptchaExpired = () => {
    setRecaptchaToken(null);
    setIsOtpInputDisbled(true); // Disable OTP input if captcha expires
  };

  const resendOtp = async () => {
    setOtpError("");
    let phoneNumber = `+${formData.phone_number}`;
    try {
      setOtpStatus(LOADING_STATUS.LOADING);
      const response = await sendOtp({ phone: phoneNumber });
      setOtpStatus(LOADING_STATUS.LOADED);
      startTimer(); // Restart the timer after resending OTP
      setCanResendOtp(false);
    } catch (error) {
      setOtpStatus(LOADING_STATUS.FAILED);
      setOtpFetchingError("Error while resending OTP");
      console.error("Error while resending OTP", error);
    }
  };

  return (
    <Box noValidate>
      {/* ReCAPTCHA */}
      <Box>
        <StyledOtpTitle>Verify You're Human</StyledOtpTitle>
        <StyledOtpSubTitle>
          Please complete the CAPTCHA below to help us prevent spam and ensure
          you're a genuine user.
        </StyledOtpSubTitle>
        <Box mt={2}>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            onChange={onCaptchaChange}
            onExpired={onCaptchaExpired}
          />
        </Box>
      </Box>

      {/* Display OTP Error */}
      {otpError && (
        <Box mt={2}>
          <Alert severity="error">{otpError}</Alert>
        </Box>
      )}

      {/* OTP Section only after CAPTCHA verification */}
      {recaptchaToken && (
        <Box mt={2}>
          <StyledOtpTitle>6-digit code</StyledOtpTitle>
          {otpFetchingError ? (
            <StyledOtpSubTitle
              sx={{ fontWeight: 500, color: theme.palette.text.red }}
            >
              {otpFetchingError}
            </StyledOtpSubTitle>
          ) : (
            <StyledOtpSubTitle>
              {` Code sent to +${formData.phone_number} unless you already have an account`}
            </StyledOtpSubTitle>
          )}
          <OtpInput
            isDisabled={isOtpInputDisabled}
            value={otp}
            onChange={handleChange}
            numInputs={6}
            separator={
              <span style={{ width: isMobile ? "12px" : "16px" }}></span>
            }
            isInputNum={true}
            shouldAutoFocus={true}
            containerStyle={{ marginTop: "24px" }}
            inputStyle={{
              border: `1px solid ${theme.palette.text.neutral[400]}`,
              borderRadius: "4px",
              width: isMobile ? "36px" : "44px",
              height: isMobile ? "48px" : "54px",
              fontSize: "14px",
              color: "#000",
              fontWeight: "400",
              caretColor: theme.palette.common.purple,
            }}
            focusStyle={{
              border: `1px solid ${theme.palette.common.purple}`,
              outline: "none",
            }}
          />

          {/* Timer and Resend OTP Section */}
          <StyledTimerContainer>
            {canResendOtp ? (
              <StyledOtpSubTitle
                sx={{ cursor: "pointer", color: theme.palette.common.purple }}
                onClick={resendOtp}
              >
                Resend code
              </StyledOtpSubTitle>
            ) : (
              <StyledOtpSubTitle sx={{ fontWeight: 400 }}>
                Resend code in 00:{timer < 10 ? `0${timer}` : timer}
              </StyledOtpSubTitle>
            )}

            <StyledOtpSubTitle>
              Already have an account?
              <StyledLoginLink component={"span"} to={"/signin"}>
                Login
              </StyledLoginLink>
            </StyledOtpSubTitle>
          </StyledTimerContainer>

          {/* Continue Button */}
          <StyledBtnContainer>
            <FormBtn disabled={otp.length < 6} onClick={onContinue}>
              Continue
            </FormBtn>
          </StyledBtnContainer>
          <TermsAndPolicyFooter />
        </Box>
      )}
    </Box>
  );
};

// Styled Components
const StyledOtpTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  font-size: 24px;
  font-weight: 500;
`;

const StyledLoginLink = styled(Link)`
  color: ${({ theme }) => theme.palette.common.purple};
  font-size: 12px;
  font-weight: 500;
  text-decoration: underline;
  margin-left: 4px;
  cursor: pointer;
`;

const StyledOtpSubTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[600]};
  font-size: 12px;
  font-weight: 400;
  word-wrap: break-word;
  margin-top: 4px;

  ${(props) =>
    props.sx &&
    css`
      ${props.sx}
    `}
`;

const StyledBtnContainer = styled(Box)`
  margin-top: 24px;
`;

const StyledTimerContainer = styled(Box)`
  margin-top: 24px;
`;

export default OtpVarification;

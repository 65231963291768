import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { EMPLOYMENT_STATUS, EMPLOYMENT_TYPE, JOB_POST_STATUS } from "../constants/constants";

dayjs.extend(utc);
dayjs.extend(timezone);


// Utility function to format event date and time
export const formatEventDateTime = (startDate, endDate) => {
  const start = dayjs(startDate);
  const end = dayjs(endDate);

  const startMonth = start.format("MMM"); // Short month format
  const startDay = start.format("D");
  const startTime = start.format("HH:mm");

  const endMonth = end.format("MMM"); // Short month format
  const endDay = end.format("D");
  const endTime = end.format("HH:mm");

  const startYear = start.format("YYYY");
  const endYear = end.format("YYYY");

  // If start and end are in the same day
  if (start.isSame(end, "day"))
  {
    if (start.isSame(new Date(), "year"))
    {
      return `${startMonth} ${startDay}, ${startTime} - ${endTime}`;
    } else
    {
      return `${startMonth} ${startDay}, ${startYear}, ${startTime} - ${endTime}`;
    }
  } else
  {
    if (start.isSame(new Date(), "year") && end.isSame(new Date(), "year"))
    {
      return `${startMonth} ${startDay}, ${startTime} - ${endMonth} ${endDay}, ${endTime}`;
    } else
    {
      return `${startMonth} ${startDay}, ${startYear}, ${startTime} - ${endMonth} ${endDay}, ${endYear}, ${endTime}`;
    }
  }
};



export function timeAgo(createdAt) {
  const createdTime = new Date(createdAt);

  // Get the current time
  const currentTime = new Date();

  const diffInMs = currentTime - createdTime;

  const diffInSeconds = Math.floor(diffInMs / 1000);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);

  if (diffInDays >= 1)
  {
    return `${diffInDays} day${diffInDays > 1 ? 's' : ''} ago`;
  } else if (diffInHours >= 1)
  {
    return `${diffInHours} hour${diffInHours > 1 ? 's' : ''} ago`;
  } else if (diffInMinutes >= 1)
  {
    return `${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''} ago`;
  } else
  {
    return `${diffInSeconds} second${diffInSeconds > 1 ? 's' : ''} ago`;
  }
}


export const truncateText = (text, limit) => {
  if (text.length <= limit)
  {
    return text;
  }
  return text.slice(0, limit) + "...";
};

export const getStatusText = (status) => {
  if (JOB_POST_STATUS.IN_REVIEW === status)
  {
    return "In review"
  } else if (JOB_POST_STATUS.ACTIVE === status)
  {
    return "Active"
  } else if (JOB_POST_STATUS.REJECTED === status)
  {
    return "Rejected"
  } else if (JOB_POST_STATUS.CLOSED === status)
  {
    return "Closed"
  }
}

export const EMPLOYMENT_STATUS_OPTIONS = [
  { value: "CURRENTLY_EMPLOYED", label: "Currently employed & referral advocate" },
  { value: "ACTIVELY_LOOKING_FOR_JOB", label: "Actively looking for jobs" },
  { value: "EMPLOYED_AND_SEEKING_JOB", label: "Employed & seeking job" },
];

export const getEmploymentStatus = (employmentStatus) => {

  if (EMPLOYMENT_STATUS.ACTIVELY_LOOKING_FOR_JOB === employmentStatus)
  {
    return "Actively looking for jobs"
  } else if (EMPLOYMENT_STATUS.CURRENTLY_EMPLOYED === employmentStatus)
  {
    return "Currently employed & referral advocate"
  } else if (EMPLOYMENT_STATUS.EMPLOYED_AND_SEEKING_JOB === employmentStatus)
  {
    return "Employed & seeking job"
  } else  
  {
    return "--"
  }
}

export const getEmploymentType = (employmentType) => {
  if (EMPLOYMENT_TYPE.FULL_TIME === employmentType)
  {
    return "Full time"
  } else if (EMPLOYMENT_TYPE.PART_TIME === employmentType)
  {
    return "Part time"
  } else if (EMPLOYMENT_TYPE.INTERNSHIP === employmentType)
  {
    return "Internship"
  } else if (EMPLOYMENT_TYPE.THESIS === employmentType)
  {
    return "Thesis"
  }
  else  
  {
    return "--"
  }
}




export function waitForSeconds(seconds) {
  return new Promise((resolve) => {
    setTimeout(resolve, seconds * 1000);
  });
}

export const formatDate = (date) => {
  return dayjs(date).format("DD.MM.YYYY");
};

export const formatTime = (date) => {
  return dayjs(date).format("hh:mm A");
};

import styled from "@emotion/styled";
import { Alert, Avatar, Box, Grid, Typography, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../api/User/user";
import FormBtn from "../../component/Common/FormBtn";
import { RequiredMark } from "../../component/Common/RequiredMark";
import { JobCardSkeleton } from "../../component/Jobs/JobsCardSkeleton";
import { EMPLOYMENT_STATUS, GET_STATUS } from "../../constants/constants";
import { getEmploymentStatus, getEmploymentType } from "../../utils/helpers";
import { useNavigate } from "react-router-dom";
import { setUserProfileData } from "../../redux/Slices/userProfileSlice";

export const Profile = () => {
  const [profileFetchingError, setProfileFetchingError] = useState("");
  const [profileFetchingStatus, setProfileFetchingStatus] = useState();
  const [userProfile, setUserProfile] = useState();
  const { userId, firstName, lastName } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userProfileState = useSelector((state) => state.userProfile);

  const handleProfileFetchFailure = () => {
    setProfileFetchingError("Error while fetching user profile.");
    setProfileFetchingStatus(GET_STATUS.FAILED);
  };

  const getDomains = (domains) => {
    return domains.map((domain) => domain.domain_name);
  };

  const getCompanies = (companies) => {
    return companies.map((company) => company.company_name);
  };

  const fetchUserProfile = async () => {
    try {
      setProfileFetchingError("");
      setProfileFetchingStatus(GET_STATUS.FETCHING);

      const response = await getUserProfile(userId);
      if (response.status === 200) {
        const user = response.data.data;
        dispatch(
          setUserProfileData({
            ...userProfileState,
            first_name: user?.first_name,
            last_name: user?.last_name,
            country: user?.country,
            state: user?.state,
            city: user?.city,
            country_id: user?.country_id,
            state_id: user?.state_id,
            city_id: user?.city_id,
            phone_number: user?.phone_number,
            platform: user?.platform,
            platform_url: user?.platform_url,
            referred_by: user?.referred_by,
            employment_status: user?.employment_status,
            current_job_title: user?.current_job_title,
            current_company: user?.current_company,
            salary_range: [user?.min_salary, user?.max_salary],
            employment_type: user?.employment_type,
            preferred_position: user?.preferred_position,
            is_active: false,
            is_user_verified: user?.is_user_verified,
            is_phone_verified: user?.is_phone_verified,
            is_email_verified: user?.is_email_verified,
            is_user_onboarded: user?.is_user_onboarded,
            domains: user?.domains ? getDomains(user?.domains) : [],
            past_companies: user?.pastCompanies
              ? getCompanies(user?.pastCompanies)
              : [],
          })
        );
        setUserProfile(user);
        setProfileFetchingStatus(GET_STATUS.FETCHED);
      } else {
        handleProfileFetchFailure();
      }
    } catch (error) {
      handleProfileFetchFailure();
    }
  };

  const getSalaryRange = () => {
    return `€${userProfile?.min_salary} - €${userProfile?.max_salary}`;
  };

  const handleClickEdit = () => {
    navigate(`/profile/edit/${userId}`, { state: { userProfile } });
  };
  useEffect(() => {
    fetchUserProfile();
  }, [userId]);
  return (
    <Container maxWidth="xl" sx={{ py: 3, pt: 1 }}>
      <WelcomeBox>
        <StyledWelcomeText>{`Welcome ${firstName} ${lastName}`}</StyledWelcomeText>
        <StyledSubText>
          Manage your personal details with ease in this section
        </StyledSubText>
      </WelcomeBox>

      {profileFetchingStatus === GET_STATUS.FETCHING ? (
        <JobCardSkeleton />
      ) : profileFetchingStatus === GET_STATUS.FAILED ? (
        <Box mt={1}>
          <Alert severity="error">
            Error fetching profile. Please try again later.
          </Alert>
        </Box>
      ) : !userProfile ? (
        <Box mt={1}>
          <Alert severity="info">User profile not found.</Alert>
        </Box>
      ) : (
        <Box>
          <UserContainer>
            <AvatarContainer>
              <Avatar
                sx={{ height: 64, width: 64 }}
                src="/assets/images/avatar-1.png"
              />
            </AvatarContainer>
            <UserDetails>
              <FormBtn sx={{ padding: "8px 0px" }}>Upload new photo</FormBtn>
              <UserRole>At least 800*800 px recommended</UserRole>
            </UserDetails>
          </UserContainer>
          <ProfileCard>
            <ProfileHeader>
              <ProfileTitle>Profile</ProfileTitle>
              <FormBtn
                sx={{ width: "auto", padding: "8px 24px" }}
                onClick={handleClickEdit}
              >
                Edit
              </FormBtn>
            </ProfileHeader>
            <Box p={2}>
              <StyledLable>
                {" "}
                <StyledRequiredMark component={"span"}>* </StyledRequiredMark>
                About me
              </StyledLable>
              <StyledDescription>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam
              </StyledDescription>
              <Grid container mt={1.5}>
                <Grid item xs={12} sm={12} md={6}>
                  <StyledLable>
                    {" "}
                    <StyledRequiredMark component={"span"}>
                      *{" "}
                    </StyledRequiredMark>
                    First name
                  </StyledLable>
                  <StyledDescription>
                    {" "}
                    {userProfile?.first_name}
                  </StyledDescription>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <StyledLable>
                    {" "}
                    <StyledRequiredMark component={"span"}>
                      *{" "}
                    </StyledRequiredMark>
                    Last name
                  </StyledLable>
                  <StyledDescription>
                    {" "}
                    {userProfile?.last_name}
                  </StyledDescription>
                </Grid>
              </Grid>
              <Grid container mt={1.5}>
                <Grid item xs={12} sm={12} md={6}>
                  <StyledLable>
                    {" "}
                    <StyledRequiredMark component={"span"}>
                      *{" "}
                    </StyledRequiredMark>
                    Phone number
                  </StyledLable>
                  <StyledDescription>
                    {" "}
                    {userProfile?.phone_number}
                  </StyledDescription>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <StyledLable>
                    {" "}
                    <StyledRequiredMark component={"span"}>
                      *{" "}
                    </StyledRequiredMark>
                    Country
                  </StyledLable>
                  <StyledDescription> {userProfile?.country}</StyledDescription>
                </Grid>
              </Grid>
              <Grid container mt={1.5}>
                <Grid item xs={12} sm={12} md={6}>
                  <StyledLable>
                    {" "}
                    <StyledRequiredMark component={"span"}>
                      *{" "}
                    </StyledRequiredMark>
                    City
                  </StyledLable>
                  <StyledDescription> {userProfile?.city}</StyledDescription>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <StyledLable>
                    {" "}
                    <StyledRequiredMark component={"span"}>
                      *{" "}
                    </StyledRequiredMark>
                    LinkedIn or Xing
                  </StyledLable>
                  <StyledDescription>
                    {userProfile?.platform_url}{" "}
                  </StyledDescription>
                </Grid>
              </Grid>
              <Grid container mt={1.5}>
                <Grid item xs={12} sm={12} md={6}>
                  <StyledLable>
                    {" "}
                    <StyledRequiredMark component={"span"}>
                      *{" "}
                    </StyledRequiredMark>
                    Employment type
                  </StyledLable>
                  <StyledDescription>
                    {" "}
                    {getEmploymentType(userProfile?.employment_type)}
                  </StyledDescription>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <StyledLable>
                    {" "}
                    <StyledRequiredMark component={"span"}>
                      *{" "}
                    </StyledRequiredMark>
                    Employment status
                  </StyledLable>
                  <StyledDescription>
                    {" "}
                    {getEmploymentStatus(userProfile?.employment_status)}
                  </StyledDescription>
                </Grid>
              </Grid>

              {(userProfile?.employment_status ===
                EMPLOYMENT_STATUS.CURRENTLY_EMPLOYED ||
                userProfile?.employment_status ===
                  EMPLOYMENT_STATUS.EMPLOYED_AND_SEEKING_JOB) && (
                <Grid container mt={1.5}>
                  <Grid item xs={12} sm={12} md={6}>
                    <StyledLable>
                      {" "}
                      <StyledRequiredMark component={"span"}>
                        *{" "}
                      </StyledRequiredMark>
                      Current Profile
                    </StyledLable>
                    <StyledDescription>
                      {" "}
                      {userProfile?.current_job_title}
                    </StyledDescription>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <StyledLable>Current company</StyledLable>
                    <StyledDescription>
                      {" "}
                      {userProfile?.current_company}
                    </StyledDescription>
                  </Grid>
                </Grid>
              )}

              {(userProfile?.employment_status ===
                EMPLOYMENT_STATUS.ACTIVELY_LOOKING_FOR_JOB ||
                userProfile?.employment_status ===
                  EMPLOYMENT_STATUS.EMPLOYED_AND_SEEKING_JOB) && (
                <Grid container mt={1.5}>
                  <Grid item xs={12} sm={12} md={6}>
                    <StyledLable>
                      {" "}
                      <StyledRequiredMark component={"span"}>
                        *{" "}
                      </StyledRequiredMark>
                      Preferred position
                    </StyledLable>
                    <StyledDescription>
                      {userProfile?.preferred_position}
                    </StyledDescription>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <StyledLable>Salary range</StyledLable>
                    <StyledDescription>{getSalaryRange()}</StyledDescription>
                  </Grid>
                </Grid>
              )}

              <Grid container mt={1.5}>
                <Grid item xs={12} sm={12} md={6}>
                  <StyledLable> Referred by</StyledLable>
                  <StyledDescription>
                    {userProfile?.referred_by ? userProfile?.referred_by : "--"}
                  </StyledDescription>
                </Grid>
              </Grid>
              <Grid container mt={1.5}>
                <Grid item xs={12} sm={12} md={6}>
                  <StyledLable>
                    <RequiredMark />
                    Domain
                  </StyledLable>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "8px",
                      marginTop: "8px",
                    }}
                  >
                    {userProfile?.domains.length > 0
                      ? userProfile?.domains.map((domain) => (
                          <Tag component="span"> {domain?.domain_name}</Tag>
                        ))
                      : "--"}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <StyledLable>Past companies</StyledLable>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "8px",
                      marginTop: "8px",
                    }}
                  >
                    {userProfile?.pastCompanies.length > 0
                      ? userProfile?.pastCompanies.map((pc) => (
                          <Tag component="span"> {pc?.company_name}</Tag>
                        ))
                      : "--"}{" "}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </ProfileCard>
        </Box>
      )}
    </Container>
  );
};

const AvatarContainer = styled(Box)`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  z-index: 0;
`;

const UserContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  margin-top: 24px;
`;

const UserRole = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  font-size: 12px;
  font-family: Inter;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.24px;
  word-wrap: break-word;
`;

const UserDetails = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledRequiredMark = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.crimson};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`;

const StyledLable = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[600]};
  font-size: 14px;
  font-weight: 400;
  word-wrap: break-word;
`;

const StyledDescription = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  font-size: 14px;
  font-weight: 600;
  word-wrap: break-word;
`;

const Tag = styled(Typography)`
  background: ${({ theme }) => theme.palette.common.purple};
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  padding: 4px 14px;
  border-radius: 4px;
`;

const StyledWelcomeText = styled(Typography)`
  font-size: 16px;
  font-weight: 600;
  color: #27272a;
  text-transform: capitalize;
`;

const StyledSubText = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  color: #52525b;
  margin-top: 4px;
`;

const WelcomeBox = styled(Box)`
  margin-top: 16px;
`;

const ProfileBox = styled(Box)`
  margin-top: 24px;
`;

const ProfileCard = styled(Box)`
  padding: 16px;
  border: 1px solid #a1a1aa;
  border-radius: 8px;
  margin-top: 24px;
`;

const ProfileHeader = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px;
`;

const ProfileTitle = styled(Typography)`
  color: #52525b;
  font-size: 20px;
  font-weight: 500;
`;

const ProfileContent = styled(Box)`
  padding: 16px;
`;

import React from "react";
import styled from "@emotion/styled";
import { Button as MuiButton, CircularProgress } from "@mui/material";
import { css } from "@emotion/react";

const StyledButton = styled(MuiButton)`
  width: 100%;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.common.white};
  padding: 12px 8px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  word-wrap: break-word;
  cursor: pointer;
  text-transform: none;
  transition: all 0.3s ease;

  &:hover {
    background-color: #4c00ad;
  }

  &:disabled {
    opacity: 0.5;
    color: ${({ theme }) => theme.palette.common.white};
    cursor: not-allowed;
  }

  ${(props) =>
    props.sx &&
    css`
      ${props.sx}
    `}

  ${({ theme }) => theme.breakpoints.down("sm")} {
    font-size: 14px;
  }
`;

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px; /* Space between loader and text */
`;

const FormBtn = ({ children, sx, disabled, loading, ...props }) => {
  return (
    <StyledButton disabled={disabled || loading} sx={sx} {...props}>
      {loading ? (
        <ButtonContent>
          <CircularProgress size={24} color="inherit" />
          <span>{children}</span>
        </ButtonContent>
      ) : (
        children
      )}
    </StyledButton>
  );
};

export default FormBtn;

import { PostClient } from "../config"

export const createPost = async (jobData) => {
  try
  {
    const response = await PostClient.post(`create`, jobData);
    return response;
  } catch (error)
  {
    if (error.response && error.response.status === 500)
    {
      return error.response;
    }
    throw error;
  }
};

export const updatePost = async (postId, data) => {
  try
  {
    const response = await PostClient.put(`${postId}`, data);
    return response;
  } catch (error)
  {
    if (error.response && error.response.status === 500)
    {
      return error.response;
    }
    throw error;
  }
};


export const deletePost = async (postId, userId) => {
  try
  {
    const response = await PostClient.delete(`delete`, {
      params: { postId, userId }
    });
    return response;
  } catch (error)
  {
    if (error.response && error.response.status === 500)
    {
      return error.response;
    }
    throw error;
  }
};

export const getAllPosts = async (limit, offset) => {
  try
  {
    const response = await PostClient.get("all", {
      params: { limit, offset }
    });
    return response;
  } catch (error)
  {
    console.log(error)
    if (error.response && error.response.status === 500)
    {
      return error.response;
    }
    throw error;
  }
}
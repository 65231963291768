import React from "react";
import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";

const ProfileActivityContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  gap: 12px;
`;

const IconContainer = styled(Box)`
  border-radius: 8px;
  padding: 8px;
  background: ${({ type, theme }) =>
    type === "user"
      ? theme.palette.background.info[100]
      : theme.palette.background.positive[100]};
`;

const UsernameTypography = styled(Typography)`
  font-size: 14px;
  color: #333333;
  font-weight: 500;
`;

const ViewDetailsTypography = styled(Typography)`
  font-size: 12px;
  color: #52525b;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
`;

const renderIcon = (type) => {
  if (type === "user") {
    return (
      <PersonOutlinedIcon sx={{ color: "#E1A730", width: 24, height: 24 }} />
    );
  } else if (type === "post") {
    return (
      <WorkOutlineOutlinedIcon
        sx={{ color: "#059669", width: 24, height: 24 }}
      />
    );
  } else {
    return (
      <EventOutlinedIcon sx={{ color: "#059669", width: 24, height: 24 }} />
    );
  }
};

const renderTitle = (type) => {
  if (type === "user") {
    return "User Request";
  } else if (type === "post") {
    return "Event posted";
  } else {
    return "New job posted - title";
  }
};

// Component
export const ProfileActivityItem = ({ type }) => {
  return (
    <ProfileActivityContainer>
      <IconContainer type={type}>{renderIcon(type)}</IconContainer>
      <Box>
        <UsernameTypography>{renderTitle(type)}</UsernameTypography>
        <ViewDetailsTypography>View details</ViewDetailsTypography>
      </Box>
    </ProfileActivityContainer>
  );
};

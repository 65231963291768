import styled from "@emotion/styled";
import { Checkbox, FormControlLabel } from "@mui/material";
import React from "react";
import { Label } from "./Label";
import { css } from "@emotion/react";

export const StyledCheckboxLabel = (props) => {
  return (
    <StyledFormControlLabel
      sx={props.containerStyle}
      control={
        <StyledCheckbox checked={props.checked} onChange={props.onChange} />
      }
      label={<Label>{props.label}</Label>}
    />
  );
};

const StyledFormControlLabel = styled(FormControlLabel)`
  margin-top: 8px;
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  font-size: 14px;
  font-weight: 400;
  .MuiFormControlLabel-label {
    margin-left: 8px;
  }

  ${(props) =>
    props.sx &&
    css`
      ${props.sx}
    `}
`;

const StyledCheckbox = styled(Checkbox)`
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  padding: 8px;
`;

import React from "react";
import { Tab } from "@mui/material";
import styled from "@emotion/styled";

const StyledTab = styled(Tab)`
  text-transform: none;
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  min-width: 0 !important;
  width: auto !important;
`;

const CustomTab = ({ label, ...props }) => {
  return <StyledTab label={label} {...props} />;
};

export default CustomTab;

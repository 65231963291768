import React from "react";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { css } from "@emotion/react";

export const Tag = ({ title, sx }) => {
  return <StyledTag sx={sx}>{title}</StyledTag>;
};

const StyledTag = styled(Typography)`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.text.prim[800]};
  border-radius: 8px;
  padding: 4px 12px;
  background: ${({ theme }) => theme.palette.background.primary[200]};

  ${(props) =>
    props.sx &&
    css`
      ${props.sx}
    `}
`;

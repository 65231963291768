import styled from "@emotion/styled";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Avatar, Box, Typography, Button } from "@mui/material";
import React from "react";
import { ReactSVG } from "react-svg";
import { timeAgo } from "../../utils/helpers";

export const CommentTemplate = ({
  comment,
  isReply, // True if a reply is being written for this comment
  onClickReply, // Function to handle clicking reply
  onClickCancel, // Function to cancel reply mode
  isReplyTemplate,
  isReplyEnabled,
}) => {
  return (
    <CommentContainer isReplyTemplate={isReplyTemplate}>
      <TopSection>
        <UserContainer>
          <AvatarContainer>
            <Avatar src="/assets/images/avatar-1.png" />
          </AvatarContainer>
          <UserDetails>
            <UserName>
              {`${comment?.user?.first_name}`} {`${comment?.user?.last_name}`}
            </UserName>
            <UserTitle>{comment?.user?.current_job_title}</UserTitle>
          </UserDetails>
        </UserContainer>
        <TimeStamp>
          <TimeStamp sx={{ mr: 2 }}>{timeAgo(comment?.createdAt)}</TimeStamp>
          <Typography component={"span"} sx={{ pt: 1 }}>
            <MoreHorizIcon />
          </Typography>
        </TimeStamp>
      </TopSection>

      <CommentText>{comment?.content}</CommentText>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          marginTop: "16px",
          gap: 1,
        }}
      >
        {comment?.parent_comment_id === comment.comment_id && (
          <ChatButton
            onClick={() => onClickReply(comment.comment_id, comment)}
            startIcon={
              <ReactSVG
                src="/assets/icons/message-chat-square.svg"
                beforeInjection={(svg) => {
                  svg.setAttribute("style", "width: 16px; height: 16px");
                }}
              />
            }
          >
            {`${comment?.repliesCount ? comment?.repliesCount : 0} replies`}
          </ChatButton>
        )}

        {!isReply && comment?.parent_comment_id === comment.comment_id && (
          <ChatButton onClick={() => onClickReply(comment.comment_id, comment)}>
            Reply
          </ChatButton>
        )}
        {isReplyEnabled &&
          comment?.parent_comment_id === comment.comment_id && (
            <ChatButton onClick={onClickCancel}>Cancel</ChatButton>
          )}
      </Box>
    </CommentContainer>
  );
};

const CommentContainer = styled(Box)`
  padding: 24px 12px 0px 12px;
  padding-left: ${({ isReplyTemplate }) => (isReplyTemplate ? "64px" : "12px")};
`;

const TopSection = styled(Box)`
  width: 100%;
  align-self: stretch;
  padding-bottom: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
`;

const AvatarContainer = styled(Box)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  z-index: 0;
`;

const UserContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const TimeStamp = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.text.neutral[600]};
`;

const UserDetails = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 1px;
`;

const UserName = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[800]};
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
  text-transform: capitalize;
`;

const UserTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.24px;
  word-wrap: break-word;
`;

const CommentText = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[800]};
  font-size: 12px;
  font-weight: 400;
  margin-top: 8px;
  word-wrap: break-word;
`;

const StyledButton = styled(Button)`
  height: 32px;
  padding: ${({ istext }) => (istext ? "4px 12px" : "4px")};
  background: white;
  border: 1px solid ${({ theme }) => theme.palette.border.neutral[200]};
  border-radius: 8px;
  color: #400091;
  font-size: 12px;
  font-weight: 500;
  text-transform: none;
  display: inline-flex;
  align-items: center;

  &:hover {
    background: #f5f5f5;
  }
`;

const ChatButton = styled(Button)`
  height: 32px;
  padding: 4px 12px;
  background: white;
  border: 1px solid ${({ theme }) => theme.palette.border.neutral[200]};
  border-radius: 8px;
  color: ${({ theme }) => theme.palette.common.purple};
  font-size: 12px;
  font-weight: 500;
  text-transform: none;
  &:hover {
    background: #f5f5f5;
  }
`;

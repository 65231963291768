import React from "react";
import styled from "@emotion/styled";
import { Button } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { css } from "@emotion/react";

const StyledAddButton = styled(Button)`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.common.purple};
  padding: 8px 16px;
  border-radius: 4px;
  text-transform: none;
  background: #fff;
  border: 1px ${({ theme }) => theme.palette.border.neutral[200]} solid;

  ${(props) =>
    props.sx &&
    css`
      ${props.sx}
    `}
`;

const AddButton = ({
  onClick,
  children,
  style,
  isNormalBtn = false,
  isFullWidth = false, // Default to false
  icon: Icon = AddOutlinedIcon, // Use AddOutlinedIcon as default
}) => {
  return (
    <StyledAddButton
      onClick={onClick}
      startIcon={!isNormalBtn && <Icon />} // Use the icon prop or default to AddOutlinedIcon
      fullWidth={isFullWidth} // Pass fullWidth to StyledAddButton
      sx={{ ...style }} // Use spread operator to merge any custom styles
    >
      {children}
    </StyledAddButton>
  );
};

export default AddButton;

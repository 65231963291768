import React, { useState } from "react";
import styled from "@emotion/styled";
import { Typography, Box, Avatar, Button } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Tag } from "../Common/Tag";
import { ReactSVG } from "react-svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getStatusText, timeAgo, truncateText } from "../../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import EditButton from "../Common/EditButton";
import DeleteButton from "../Common/DeleteButton";
import { EditPostDialog } from "./EditPostDialog";
import { resetPostState, setPostData } from "../../redux/Slices/postSlice";
import { DeleteDialog } from "../Common/DeleteDialog";
import InverseBtn from "../Common/InverseBtn";

export const PostsCard = ({
  userRole,
  post,
  postId,
  isOwner = false,
  onOpenSnackbar,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const userId = useSelector((state) => state.user.userId);
  const postState = useSelector((state) => state.post);

  const pathname = location.pathname;
  const [openEditPostDialog, setOpenEditPostDialog] = useState(false);
  const [openDeletePostDialog, setOpenDeletePostDialog] = useState(false);

  const shouldNotShowChatButton = pathname.startsWith("/post");

  const handleOpenEditPostDialog = (event) => {
    const formData = {
      title: post?.title,
      description: post?.description,
      post_status: post?.post_status,
      created_by: post?.created_by,
    };
    dispatch(
      setPostData({
        ...postState,
        ...formData,
      })
    );
    setOpenEditPostDialog(true);
  };

  const handleCloseEditPostDialog = (event) => {
    dispatch(resetPostState());
    setOpenEditPostDialog(false);
  };

  const handleOpenDeletePostDialog = (event) => {
    setOpenDeletePostDialog(true);
  };
  const handleCloseDeletePostDialog = (event) => {
    setOpenDeletePostDialog(false);
  };

  const handleViewPost = () => {
    navigate(`/post/${postId}`, { state: { userRole, post, postId } });
  };

  return (
    <>
      <CardContainer>
        <TopSection>
          <UserContainer>
            <AvatarContainer>
              <Avatar src="/assets/images/avatar-1.png" />
            </AvatarContainer>
            <UserDetails>
              <UserName>{`${post?.user?.first_name} ${post?.user?.last_name}`}</UserName>
              <UserRole>{post?.user?.current_job_title}</UserRole>
            </UserDetails>
          </UserContainer>
          <TimeStampContainer>
            <TimeStamp>{timeAgo(post?.createdAt)}</TimeStamp>
            <Typography component={"span"} sx={{ pt: 1 }}>
              <MoreHorizIcon />
            </Typography>
          </TimeStampContainer>
        </TopSection>
        {isOwner && (
          <TagContainer>
            <Tag title={getStatusText(post?.post_status)} />
          </TagContainer>
        )}

        <PostDetailsContainer>
          {post?.title && <Title> {truncateText(post?.title, 100)}</Title>}
          {post?.description && (
            <Description>{truncateText(post?.description, 500)}</Description>
          )}
        </PostDetailsContainer>

        <BtnContainer>
          <ChatButton
            startIcon={
              <ReactSVG
                src="/assets/icons/message-chat-square.svg"
                beforeInjection={(svg) => {
                  svg.setAttribute("style", "width: 16px; height: 16px");
                }}
              />
            }
          >
            {`${post?.comment_count} ${
              post?.comment_count === 1 ? "reply" : "replies"
            }`}
          </ChatButton>
          {!shouldNotShowChatButton && (
            <InverseBtn
              onClick={handleViewPost}
              sx={{ fontSize: "12px", fontWeight: 500, padding: "4px 12px" }}
            >
              View post
            </InverseBtn>
          )}

          {userId === post?.created_by && pathname === "/my-posts" && (
            <EditButton onClick={handleOpenEditPostDialog} />
          )}
          {userId === post?.created_by && pathname === "/my-posts" && (
            <DeleteButton onClick={handleOpenDeletePostDialog} />
          )}
        </BtnContainer>
      </CardContainer>
      <EditPostDialog
        openPostDialog={openEditPostDialog}
        handleClose={handleCloseEditPostDialog}
        onOpenSnackbar={onOpenSnackbar}
        post={post}
      />
      <DeleteDialog
        id={post?.post_id}
        userId={userId}
        type="post"
        onOpenSnackbar={onOpenSnackbar}
        openDeleteDialog={openDeletePostDialog}
        handleClose={handleCloseDeletePostDialog}
      />
    </>
  );
};

// Styled Components Definitions (unchanged)...
const CardContainer = styled(Box)`
  padding: 16px 12px;
  background: white;
  border-radius: 8px;
  margin-top: 16px;
  border: 1px solid ${({ theme }) => theme.palette.border.neutral[400]};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const TopSection = styled(Box)`
  width: 100%;
  align-self: stretch;
  padding-bottom: 4px;
  border-bottom: 1px solid #e4e4e7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
`;

const AvatarContainer = styled(Box)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  z-index: 0;
`;

const UserContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const BtnContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-top: 16px;
`;

const TimeStampContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TimeStamp = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[600]};
  font-size: 12px;
  font-weight: 400;
  word-wrap: break-word;
  margin-right: 16px;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin-right: 8px;
  }
`;

const UserDetails = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 1px;
`;

const UserName = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[800]};
  font-size: 16px;
  font-family: Inter;
  font-weight: 500;
  line-height: 24px;
  text-transform: capitalize;
  word-wrap: break-word;
`;

const TagContainer = styled(Box)`
  margin-top: 16px;
`;

const PostDetailsContainer = styled(Box)`
  margin-top: 8px;
`;

const UserRole = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  font-size: 12px;
  font-family: Inter;
  font-weight: 400;
  line-height: 18px;
  text-transform: capitalize;
  word-wrap: break-word;
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.palette.text.neutral[800]};
  font-size: 16px;
  font-weight: 500;
  margin: 4px 0px;
  letter-spacing: 0.16;

  word-wrap: break-word;
`;

const Description = styled.p`
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.24px;
  word-break: break-all;
  box-sizing: border-box;
  white-space: normal;
  margin-top: 8px;
`;

const ChatButton = styled(Button)`
  height: 32px;
  padding: 4px 12px;
  background: white;
  border: 1px solid ${({ theme }) => theme.palette.border.neutral[200]};
  border-radius: 8px;
  color: ${({ theme }) => theme.palette.common.purple};
  font-size: 12px;
  font-weight: 500;
  text-transform: none;
  &:hover {
    background: #f5f5f5;
  }
`;

const PollContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  margin-top: 12px;
  margin-bottom: 12px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  display: block;
  cursor: pointer;
`;

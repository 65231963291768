import styled from "@emotion/styled";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { Alert, Box, TextField, Typography, Container } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import IconButtonV2 from "../../component/Common/IconButtnV2";
import { PostsCard } from "../../component/Posts/PostsCard";
import { CommentTemplate } from "../../component/Posts/CommentTemplate";
import {
  getAllCommentReplies,
  getAllComments,
  postComment,
} from "../../api/Comment/comment";
import { GET_STATUS, POST_STATUS } from "../../constants/constants";
import { CommentTemplateSkeleton } from "../../component/Posts/CommentTemplateSkeleton";
import { useDispatch, useSelector } from "react-redux";

export const PostDescription = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [commentText, setCommentText] = useState("");
  const [isCommentPosted, setIsCommentPosted] = useState(false);

  const [commentFetchingError, setCommentFetchingError] = useState("");
  const [commentFetchingStatus, setCommentFetchingStatus] = useState();
  const [repliesFetchingError, setRepliesFetchingError] = useState("");
  const [repliesStatus, setRepliesStatus] = useState();
  const [commentPostingError, setCommentPostingError] = useState("");
  const [commentPostingStatus, setCommentPostingStatus] = useState();
  const [comments, setComments] = useState([]);
  const [replies, setReplies] = useState([]);
  const [limit, setLimit] = useState(3);
  const [offset, setOffset] = useState(0);
  const [isReply, setIsReply] = useState(false);
  const [parentCommentId, setParentCommentId] = useState();
  const [selectedComment, setSelectedComment] = useState();
  const postDetail = location?.state?.post;
  const postId = postDetail?.post_id;
  const { userId } = useSelector((state) => state.user);

  const handleFetchRepliesFailure = () => {
    setRepliesFetchingError("Error while fetching comment replies.");
    setRepliesStatus(GET_STATUS.FAILED);
  };

  const fetchComments = async () => {
    try {
      setCommentFetchingError("");
      setCommentFetchingStatus(GET_STATUS.FETCHING);
      const response = await getAllComments(limit, offset, postId);
      if (response.status === 200) {
        const data = response.data.data;
        // Remove duplicates from newJobs based on job.id
        // const uniqueJobs = [
        //   ...jobs,
        //   ...newJobs.filter(
        //     (job) =>
        //       !jobs.some(
        //         (existingJob) => existingJob.job_post_id === job.job_post_id
        //       )
        //   ),
        // ];
        setComments(data);
        setOffset((prevOffset) => prevOffset + limit);

        // if (newJobs.length < limit) {
        //   setHasMore(false); // No more posts to load
        // }

        setCommentFetchingStatus(GET_STATUS.FETCHED);
      } else {
        setCommentFetchingError("Error while fetching comments.");
        setCommentFetchingStatus(GET_STATUS.FAILED);
      }
    } catch (error) {
      setCommentFetchingError("Error while fetching comments.");
      setCommentFetchingStatus(GET_STATUS.FAILED);
    }
  };

  const fetchReplies = async (commentId) => {
    try {
      setReplies([]);
      setCommentFetchingError("");
      setRepliesStatus(GET_STATUS.FETCHING);
      const response = await getAllCommentReplies(commentId);
      if (response.status === 200) {
        const data = response.data.data;
        setReplies(data);
        setRepliesStatus(GET_STATUS.FETCHED);
      } else {
        handleFetchRepliesFailure();
      }
    } catch (error) {
      handleFetchRepliesFailure();
    }
  };

  const handlePostSuccess = () => {
    setIsCommentPosted(true);
    setCommentText("");
    setIsReply(false);
    setParentCommentId("");
    setSelectedComment({});
  };

  const handlePostFailure = () => {
    // onOpenSnackbar("Error while creating post.", "error");
  };

  const handleClickComment = async () => {
    if (!commentText) {
      return;
    }
    try {
      setCommentPostingError("");
      const data = {
        content: commentText,
        created_by: userId,
        post_id: postId,
        parent_comment_id: parentCommentId,
      };
      if (!isReply) {
        delete data.parent_comment_id;
      }

      setCommentPostingStatus(POST_STATUS.POSTING);
      const response = await postComment(data);
      if (response.status === 201) {
        setCommentPostingStatus(POST_STATUS.SAVED);
        handlePostSuccess();
      } else {
        setCommentPostingError("Error while creating post.");
        setCommentPostingStatus(POST_STATUS.FAILED);
        handlePostFailure();
      }
    } catch (error) {
      console.log(error.message);
      setCommentPostingError("Error while creating post.");
      setCommentPostingStatus(POST_STATUS.FAILED);
      handlePostFailure();
    }
  };

  const handleOnChange = (value) => {
    setCommentText(value);
  };

  const handleOnClickReply = async (parentId, comment) => {
    await fetchReplies(parentId);
    setIsReply(true);
    setParentCommentId(parentId);
    setSelectedComment(comment);
  };

  const handleOnClickCancel = () => {
    setIsReply(false);
    setParentCommentId("");
    setSelectedComment({});
  };

  useEffect(() => {
    fetchComments();
  }, [postId, isCommentPosted]);

  return (
    <>
      <StyledBox>
        <Container maxWidth="xl" sx={{ py: 3 }}>
          <BackBtnContainer>
            <ArrowBackIosNewOutlinedIcon onClick={() => navigate("/")} />
            <BackBtnText>All posts</BackBtnText>
          </BackBtnContainer>

          <ContentWrapper>
            <PostsCard post={postDetail} userRole="senior software developer" />

            {commentFetchingStatus === GET_STATUS.FETCHING ? (
              <CommentTemplateSkeleton />
            ) : commentFetchingStatus === GET_STATUS.FAILED ? (
              <Box mt={3}>
                <Alert severity="error">Error fetching comments.</Alert>
              </Box>
            ) : comments.length === 0 ? (
              <Box mt={3}>
                <Alert severity="info">No comments found.</Alert>
              </Box>
            ) : (
              <Box>
                {isReply ? (
                  <>
                    <CommentTemplate
                      comment={selectedComment}
                      isReply={true}
                      onClickReply={handleOnClickReply}
                      onClickCancel={handleOnClickCancel}
                      isReplyTemplate={false}
                      isReplyEnabled={isReply}
                    />
                    {repliesStatus === GET_STATUS.FETCHING ? (
                      <CommentTemplateSkeleton />
                    ) : commentFetchingStatus === GET_STATUS.FAILED ? (
                      <Box mt={3}>
                        <Alert severity="error">Error fetching replies.</Alert>
                      </Box>
                    ) : replies.length === 0 ? (
                      <Box mt={3}></Box>
                    ) : (
                      replies.map((comment) => (
                        <Box>
                          <CommentTemplate
                            comment={comment}
                            isReply={false}
                            onClickReply={handleOnClickReply}
                            onClickCancel={handleOnClickCancel}
                            isReplyTemplate={true}
                            isReplyEnabled={isReply}
                          />
                        </Box>
                      ))
                    )}
                  </>
                ) : (
                  comments.map((comment) => (
                    <Box>
                      <CommentTemplate
                        comment={comment}
                        isReply={false}
                        onClickReply={handleOnClickReply}
                        onClickCancel={handleOnClickCancel}
                        isReplyTemplate={false}
                        isReplyEnabled={isReply}
                      />
                    </Box>
                  ))
                )}
              </Box>
            )}
          </ContentWrapper>
        </Container>
        <StickyBox>
          <InputContainer>
            <AddOutlinedIcon sx={{ color: "#400091" }} />
            <StyledTextField
              value={commentText}
              fullWidth
              placeholder="Add your view"
              onChange={(e) => handleOnChange(e.target.value)}
            />
            <IconButtonV2
              disabled={commentPostingStatus === POST_STATUS.POSTING}
              iconPath={"/assets/icons/send.svg"}
              onClick={handleClickComment}
            />
          </InputContainer>
        </StickyBox>
      </StyledBox>
    </>
  );
};

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  min-height: 90vh;
`;

const ContentWrapper = styled(Box)`
  flex: 1;
  padding-bottom: 100px; /* Adjust to accommodate StickyBox height */
`;

const StickyBox = styled(Box)`
  width: 100%;
  padding: 16px;
  background: #f4f4f5;
  position: fixed;
  bottom: 0;
  z-index: 1000;
  box-sizing: border-box;
  max-width: calc(100vw - 280px); /* Set max-width to 100vw - 280px */
  ${({ theme }) => theme.breakpoints.down("sm")} {
    max-width: none;
  }
`;

const InputContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    border-radius: 4px;
    background-color: white;
    border: 1px solid ${({ theme }) => theme.palette.border.neutral[400]};
    margin-top: 4px;
    font-size: 14px;
    font-weight: 400;
    border: none;

    &:hover fieldset {
      border: 1px solid ${({ theme }) => theme.palette.common.purple};
    }

    &.Mui-focused fieldset {
      border: 1px solid ${({ theme }) => theme.palette.common.purple};
    }
  }

  .MuiOutlinedInput-input {
    padding: 12px;
  }
`;
const BackBtnText = styled(Typography)`
  font-size: 12px;
  color: #400091;
  font-weight: 500;
  text-decoration: none;
`;

const BackBtnContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

import styled from "@emotion/styled";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { USER_PROFILE_STATUS } from "../../constants/constants";

export const RejectionReasonDialog = ({
  openRejectionReasoneDialog,
  handleClose,
  reason,
  status,
}) => {
  const title =
    status === USER_PROFILE_STATUS.REJECTED
      ? "Reason for rejection"
      : "Reason for profile on hold";
  const { firstName, lastName } = useSelector((state) => state.user);

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={openRejectionReasoneDialog}
      onClose={handleClose}
    >
      <CardContainer>
        <DialogTitle>
          <Wrapper>
            <StyledDialogTitle>{title}</StyledDialogTitle>
            <CloseOutlinedIcon
              sx={{ color: "#333333", padding: "8px", cursor: "pointer" }}
              onClick={handleClose}
            />
          </Wrapper>
        </DialogTitle>
        <DialogContent sx={{ justifyContent: "center" }}>
          <StyledDialogSubTitle>{reason}</StyledDialogSubTitle>
          <StyledDialogSubTitle
            mt={2}
          >{`Admin : ${firstName} ${lastName}`}</StyledDialogSubTitle>
        </DialogContent>
      </CardContainer>
    </Dialog>
  );
};

const StyledDialogTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  font-size: 20px;
  font-weight: 500;
`;

const StyledDialogSubTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[600]};
  font-size: 14px;
  font-weight: 500;
  text-transform:capitalize;
`;

const CardContainer = styled(Box)`
  padding: 16px;
  background: white;
  border-radius: 8px;
`;

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

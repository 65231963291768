import { EventClient } from "../config"

export const createEvent = async (eventData) => {
  try
  {
    const response = await EventClient.post(`create`, eventData);
    return response;
  } catch (error)
  {
    if (error.response && error.response.status === 500)
    {
      return error.response;
    }
    throw error;
  }
};


export const getAllEvents = async (eventType, userId) => {
  try
  {
    const response = await EventClient.get("all", {
      params: { eventType, userId }
    });
    // const response = await EventClient.get("all", {
    //   params: { limit, offset }
    // });
    return response;
  } catch (error)
  {
    console.log(error)
    if (error.response && error.response.status === 500)
    {
      return error.response;
    }
    throw error;
  }
}

export const toggleBookmarkEvent = async (data, action) => {
  try
  {
    const response = await EventClient.post(`bookmark`, data, {
      params: { action: action },
    });
    return response;
  } catch (error)
  {
    if (error.response && error.response.status === 500)
    {
      return error.response;
    }
    throw error;
  }
}




export const updateEvent = async (eventId, data) => {
  try
  {
    const response = await EventClient.put(`${eventId}`, data);
    return response;
  } catch (error)
  {
    if (error.response && error.response.status === 500)
    {
      return error.response;
    }
    throw error;
  }
};

export const deleteEvent = async (eventId, userId) => {
  try
  {
    const response = await EventClient.delete(`delete`, {
      params: { eventId, userId }
    });
    return response;
  } catch (error)
  {
    if (error.response && error.response.status === 500)
    {
      return error.response;
    }
    throw error;
  }
};
import styled from "@emotion/styled";
import React, { useState } from "react";

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { POST_STATUS, USER_PROFILE_STATUS } from "../../constants/constants";
import { setUserProfileData } from "../../redux/Slices/userProfileSlice";
import { formatDate, formatTime } from "../../utils/helpers";
import FormBtn from "../Common/FormBtn";
import InverseBtn from "../Common/InverseBtn";
import { updateProfileStatus } from "../../api/Admin/admin";
import dayjs from "dayjs";
import { setActionData } from "../../redux/Slices/eventListnerSlice";
import { RejectionReasonDialog } from "./RejectionReasonDialog";

const ProfileRequestTable = ({
  status,
  users,
  handleOpenUserProfileDialog,
  handleCloseUserProfileDialog,
  onOpenSnackbar,
}) => {
  const dispatch = useDispatch();
  const userProfileState = useSelector((state) => state.userProfile);
  const [profileActionStatus, setProfileActionStatus] = useState();
  const eventListenerData = useSelector((state) => state.eventListener);
  const [openRejectReasonDialog, setOpenRejectReasonDialog] = useState(false); // State for second dialog
  const [rejectionType, setRejectionType] = useState("");
  const [rejectionReason, setRejectionReason] = useState("");
  const reason = "";

  const getDomains = (domains) => {
    return domains.map((domain) => domain.domain_name);
  };

  const getCompanies = (companies) => {
    return companies.map((company) => company.company_name);
  };

  const handleClickViewProfile = (user) => {
    dispatch(
      setUserProfileData({
        ...userProfileState,
        user_id: user?.user_id,
        first_name: user?.first_name,
        last_name: user?.last_name,
        country: user?.country,
        state: user?.state,
        city: user?.city,
        country_id: user?.country_id,
        state_id: user?.state_id,
        city_id: user?.city_id,
        phone_number: user?.phone_number,
        platform: user?.platform,
        platform_url: user?.platform_url,
        referred_by: user?.referred_by,
        employment_status: user?.employment_status,
        current_job_title: user?.current_job_title,
        current_company: user?.current_company,
        salary_range: [user?.min_salary, user?.max_salary],
        employment_type: user?.employment_type,
        preferred_position: user?.preferred_position,
        is_active: false,
        is_user_verified: user?.is_user_verified,
        is_phone_verified: user?.is_phone_verified,
        is_email_verified: user?.is_email_verified,
        is_user_onboarded: user?.is_user_onboarded,
        domains: user?.domains ? getDomains(user?.domains) : [],
        past_companies: user?.pastCompanies
          ? getCompanies(user?.pastCompanies)
          : [],
      })
    );
    handleOpenUserProfileDialog();
  };

  const handleProfileUpdateFailure = () => {
    onOpenSnackbar("Error while updating profile status.", "error");
    setProfileActionStatus(POST_STATUS.FAILED);
    handleCloseUserProfileDialog();
  };

  const handleProfileUpdateSuccess = (user_id) => {
    setProfileActionStatus(POST_STATUS.SAVED);
    dispatch(
      setActionData({
        ...eventListenerData,
        profileRequestActionId: `profile_status_${user_id}_${dayjs()}`,
      })
    );
    handleCloseUserProfileDialog();
    onOpenSnackbar("Profile status updated successfully!", "success");
  };
  const handleClickAction = async (status, user_id) => {
    try {
      const data = {
        user_id,
        status,
        reason,
      };
      setProfileActionStatus(POST_STATUS.POSTING);
      const response = await updateProfileStatus(data);
      if (response.status === 200) {
        handleProfileUpdateSuccess(user_id);
      } else {
        handleProfileUpdateFailure();
      }
    } catch (error) {
      handleProfileUpdateFailure();
    }
  };

  const handleOpenRejectReasonDialog = (reason) => {
    setRejectionReason(reason);
    setRejectionType(status);
    setOpenRejectReasonDialog(true);
  };

  const handleCloseRejectReasonDialog = () => {
    setRejectionReason("");
    setRejectionType("");
    setOpenRejectReasonDialog(false);
  };
  return (
    <Box mt={2}>
      <StyledTableContainer>
        <Table>
          <TableBody>
            {users
              .filter((user) => user?.active_onboarding_step !== null)
              .map((user, index) => (
                <StyledTableRow key={index}>
                  <TableCell sx={{ flexGrow: 1, width: "100%" }}>
                    <StyledUsername>
                      {user?.first_name} {user?.last_name}{" "}
                    </StyledUsername>
                  </TableCell>
                  {/* <TableCell sx={{ whiteSpace: "nowrap", paddingX: 1 }}>
                  <Tag
                    title="Accept"
                    sx={{
                      background: theme.palette.background.positive[100],
                      color: theme.palette.text.positive[800],
                    }}
                  />
                </TableCell> */}
                  {status === USER_PROFILE_STATUS.IN_REVIEW && (
                    <TableCell sx={{ whiteSpace: "nowrap", paddingX: 2 }}>
                      <StyledTimestamp>
                        {formatDate(user?.createdAt)}{" "}
                      </StyledTimestamp>
                    </TableCell>
                  )}
                  {status === USER_PROFILE_STATUS.IN_REVIEW && (
                    <TableCell sx={{ whiteSpace: "nowrap", paddingX: 2 }}>
                      <StyledTimestamp>
                        {" "}
                        {formatTime(user?.createdAt)}{" "}
                      </StyledTimestamp>
                    </TableCell>
                  )}

                  {(status === USER_PROFILE_STATUS.REJECTED ||
                    status === USER_PROFILE_STATUS.ON_HOLD) && (
                    <TableCell sx={{ whiteSpace: "nowrap", paddingX: 2 }}>
                      <ViewProfileLink
                        onClick={() =>
                          handleOpenRejectReasonDialog(user?.remark)
                        }
                      >
                        Comment
                      </ViewProfileLink>
                    </TableCell>
                  )}
                  {(status === USER_PROFILE_STATUS.IN_REVIEW ||
                    status === USER_PROFILE_STATUS.ACTIVE ||
                    status === USER_PROFILE_STATUS.ON_HOLD) && (
                    <TableCell sx={{ whiteSpace: "nowrap", paddingX: 2 }}>
                      <ViewProfileLink
                        onClick={() => handleClickViewProfile(user)}
                      >
                        View Profile
                      </ViewProfileLink>
                    </TableCell>
                  )}

                  {status === USER_PROFILE_STATUS.REJECTED && (
                    <TableCell sx={{ whiteSpace: "nowrap", paddingX: 2 }}>
                      <RejectText>Rejected</RejectText>
                    </TableCell>
                  )}

                  {status === USER_PROFILE_STATUS.ON_HOLD && (
                    <TableCell sx={{ whiteSpace: "nowrap" }}>
                      <FormBtn
                        sx={{
                          width: "auto",
                          padding: "8px 12px",
                          fontSize: 12,
                          fontWeight: 500,
                        }}
                        onClick={() =>
                          handleClickAction(
                            USER_PROFILE_STATUS.ACTIVE,
                            user?.user_id
                          )
                        }
                      >
                        Accept
                      </FormBtn>
                    </TableCell>
                  )}
                  {/* {status === USER_PROFILE_STATUS.ON_HOLD && (
                    <TableCell sx={{ whiteSpace: "nowrap", paddingX: 2 }}>
                      <InverseBtn
                        sx={{
                          padding: "8px 12px",
                          fontSize: 12,
                          fontWeight: 500,
                        }}
                        onClick={() =>
                          handleClickAction(
                            USER_PROFILE_STATUS.REJECTED,
                            user?.user_id
                          )
                        }
                      >
                        Decline
                      </InverseBtn>{" "}
                    </TableCell>
                  )} */}
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
        <RejectionReasonDialog
          openRejectionReasoneDialog={openRejectReasonDialog}
          handleClose={handleCloseRejectReasonDialog}
          reason={rejectionReason}
          status={rejectionType}
        />
      </StyledTableContainer>
    </Box>
  );
};

export default ProfileRequestTable;

const StyledTableContainer = styled(TableContainer)`
  border: 1px solid ${({ theme }) => theme.palette.border.neutral[200]};
  padding: 0; // Remove any padding from the TableContainer
  margin: 0; // Remove any margin
  border-radius: 4px;
`;

const StyledTimestamp = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.text.neutral[600]};
`;

const StyledUsername = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.text.neutral[600]};
`;

const ViewProfileLink = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.common.purple};
  text-decoration: underline;
  cursor: pointer;
`;

const RejectText = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.text.negative[600]};
`;

const StyledTableRow = styled(TableRow)`
  &:not(:last-child) {
    border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
  }
`;

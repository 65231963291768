import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Alert,
  Box,
  Checkbox,
  Container,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { useTheme } from "@mui/material/styles";
import styled from "@emotion/styled";
import FormBtn from "../../component/Common/FormBtn";
import FormInput from "../../component/Common/FormInput";
import { TermsAndPolicyFooter } from "../../component/Common/TermsAndPolicyFooter";
import CorosoleContent from "../../component/OnBoarding/CorosoulContent";
import { Link, useNavigate } from "react-router-dom";
import { signUp } from "../../api/Auth/auth";
import { setUserProfile } from "../../redux/Slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { POST_STATUS } from "../../constants/constants";
import { StyledFormApiErrorText } from "../../component/Common/StyledFormApiErrorText";
import {
  resetOnboardingState,
  setOnBoardingData,
} from "../../redux/Slices/onBoardingSlice";
import { StyledCheckboxLabel } from "../../component/Common/StyledFormControlLabel";

export const SignUp = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);
  const formData = useSelector((state) => state.onboarding);

  const [signUpError, setSignUpError] = useState("");
  const [signUpStatus, setSignUpStatus] = useState();
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
    rpassword: "",
  });
  const [termsChecked, setTermsChecked] = useState(false);
  const [privacyChecked, setPrivacyChecked] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const {
    handleSubmit,
    control,
    watch,
    trigger,
    formState: { errors },
  } = useForm();

  const onSubmit = async () => {
    setSignUpStatus(POST_STATUS.INITIAL);

    const user = {
      email: formValues.email,
      password: formValues.password,
    };

    try {
      dispatch(resetOnboardingState());
      setSignUpStatus(POST_STATUS.POSTING);
      setSignUpError("");
      const response = await signUp(user);
      if (response.status === 201) {
        const { userId, accessToken, refreshToken } = response?.data?.data;
        Cookies.set("accessToken", accessToken);
        Cookies.set("refreshToken", refreshToken);
        dispatch(
          setUserProfile({
            ...userData,
            userId,
          })
        );

        setSignUpStatus(POST_STATUS.SAVED);
        navigate("/onboarding");
      } else if (response.status === 400) {
        setSignUpError("Email already exists.");
        setSignUpStatus(POST_STATUS.FAILED);
      } else {
        setSignUpError("Unexpected error occurred.");
        setSignUpStatus(POST_STATUS.FAILED);
      }
    } catch (error) {
      setSignUpError("Error while signing up.");
      setSignUpStatus(POST_STATUS.FAILED);
    }
  };

  const handleInputChange = async (name, value) => {
    setSignUpError("");
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
    await trigger(name);
  };

  useEffect(() => {
    if (!termsChecked || !privacyChecked) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [termsChecked, privacyChecked]);

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid item xs={12} sm={6} md={6} component={Paper} elevation={6} square>
        <Container maxWidth="sm">
          <Box
            sx={{
              mx: { xs: 1, sm: 4, md: 8 },
              my: { xs: 4, sm: 6, md: 10 },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit(onSubmit)}
              sx={{ mt: 1 }}
            >
              <Box sx={{ alignSelf: "flex-start", mb: 2 }}>
                <StyledTitle>Join Community</StyledTitle>
                <StyledSubTitle>
                  or
                  <StyledSignInLink to={"/signin"}>Login</StyledSignInLink>
                </StyledSubTitle>
              </Box>
              {signUpError && (
                <Alert sx={{ mb: 1 }} severity="error">
                  {signUpError}
                </Alert>
              )}

              <Controller
                name="email"
                control={control}
                defaultValue=""
                rules={{
                  required: "Email is required",
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    message: "Invalid email address",
                  },
                }}
                render={({ field }) => (
                  <FormInput
                    label="Email"
                    id="email"
                    type="email"
                    placeholder="Enter valid e-mail id"
                    required
                    error={!!errors.email}
                    helperText={errors.email ? errors.email.message : ""}
                    value={formValues.email}
                    onChange={(e) => {
                      field.onChange(e);
                      handleInputChange("email", e.target.value);
                    }}
                  />
                )}
              />

              <Controller
                name="password"
                control={control}
                defaultValue=""
                rules={{
                  required: "Password is required",
                  minLength: {
                    value: 8,
                    message: "Password must be at least 8 characters long",
                  },
                }}
                render={({ field }) => (
                  <FormInput
                    label="Password"
                    id="password"
                    type="password"
                    required
                    error={!!errors.password}
                    helperText={errors.password ? errors.password.message : ""}
                    value={formValues.password}
                    onChange={(e) => {
                      field.onChange(e);
                      handleInputChange("password", e.target.value);
                    }}
                  />
                )}
              />

              <Controller
                name="rpassword"
                control={control}
                defaultValue=""
                rules={{
                  required: "Please confirm your password",
                  validate: (value) =>
                    value === watch("password") || "Passwords do not match",
                }}
                render={({ field }) => (
                  <FormInput
                    label="Rewrite password"
                    id="rpassword"
                    type="password"
                    required
                    error={!!errors.rpassword}
                    helperText={
                      errors.rpassword ? errors.rpassword.message : ""
                    }
                    value={formValues.rpassword}
                    onChange={(e) => {
                      field.onChange(e);
                      handleInputChange("rpassword", e.target.value);
                    }}
                  />
                )}
              />
              <Box
                sx={{
                  marginTop: { xs: 2, md: 2 }, // Responsive margin-top
                }}
              >
                <FormBtn
                  type="submit"
                  disabled={
                    isButtonDisabled || signUpStatus === POST_STATUS.POSTING
                  }
                  loading={signUpStatus === POST_STATUS.POSTING}
                >
                  Sign up
                </FormBtn>
              </Box>
              <TermsAndPolicyFooter />
              <Box>
                <StyledCheckboxLabel
                  checked={termsChecked} // bind checked state
                  onChange={(e) => setTermsChecked(e.target.checked)} // handle change
                  label={
                    <>
                      I have read and agree to the{" "}
                      <StyledFooterLink to="/terms-and-conditions">
                        Terms and Conditions
                      </StyledFooterLink>
                    </>
                  }
                />
                <StyledCheckboxLabel
                  checked={privacyChecked}
                  onChange={(e) => setPrivacyChecked(e.target.checked)} // toggle state
                  containerStyle={{ marginTop: "0px" }}
                  label={
                    <>
                      I consent to the collection and use of my data as outlined
                      in the{" "}
                      <StyledFooterLink to="/privacy-policy">
                        Privacy Policy
                      </StyledFooterLink>
                    </>
                  }
                />
              </Box>
            </Box>
          </Box>
        </Container>
      </Grid>
      <Grid
        item
        xs={false}
        sm={6}
        md={6}
        sx={{
          display: { xs: "none", sm: "block" },
          backgroundColor: theme.palette.primary[200],
        }}
      >
        <CorosoleContent />
      </Grid>
    </Grid>
  );
};

const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  font-size: 24px;
  font-style: normal;
  font-weight: 500;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    font-size: 16px;
  }
`;

const StyledSubTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
`;

const StyledSignInLink = styled(Link)`
  color: ${({ theme }) => theme.palette.common.purple};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  text-decoration-line: underline;
  cursor: pointer;
  margin-left: 6px;
`;

const StyledFooterLink = styled.span`
  color: ${({ theme }) => theme.palette.text.neutral[600]};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  text-decoration-line: underline;
  word-wrap: break-word;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    font-size: 12px;
  }
`;

import styled from "@emotion/styled";
import React from "react";

const TextArea = styled.textarea`
  width: 100%;
  box-sizing: border-box;
  background: #fff;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.palette.text.secondary};
  padding: 8px;
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 14px;
  font-weight: 400;
  font-family: "Inter";
`;

const StyledTextArea = ({ value, onChange, placeholder, ...props }) => {
  return (
    <TextArea
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      {...props}
    />
  );
};

// Default export of the component
export default StyledTextArea;

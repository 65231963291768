import styled from "@emotion/styled";
import Home from "@mui/icons-material/Home";
import { Box, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const isActive = (pathname, href) => {
  if (href === "/") {
    return pathname === "/" || pathname.startsWith("/post");
  } else if (href === "/jobs") {
    return pathname.startsWith("/jobs");
  } else if (href === "/events") {
    return pathname.startsWith("/events");
  } else if (href === "/mentoring") {
    return pathname.startsWith("/mentoring");
  } else if (href === "/profile") {
    return pathname.startsWith("/profile");
  } else if (href === "/bookmarks") {
    return pathname.startsWith("/bookmarks");
  } else if (href === "/my-posts") {
    return pathname.startsWith("/my-posts");
  } else if (href === "/admin/manage-requests") {
    return href === pathname;
  } else if (href === "/admin/people-management") {
    return href === pathname;
  } else if (href === "/admin/monitoring-job-posts") {
    return href === pathname;
  } else if (href === "/admin/events") {
    return href === pathname;
  }

  return false;
};

export function NavItem({ disabled, external, href, pathname, title, icon }) {
  const active = isActive(pathname, href);
  const Icon = icon;

  return (
    <li>
      <StyledNavBox
        as={href ? (external ? "a" : Link) : "button"}
        to={href && !external ? href : undefined}
        href={href && external ? href : undefined}
        target={external ? "_blank" : undefined}
        rel={external ? "noreferrer" : undefined}
        role={!href ? "button" : undefined}
        disabled={disabled}
        active={active}
      >
        <IconContainer>
          {Icon ? (
            <Icon
              fill={
                active
                  ? "var(--NavItem-icon-active-color)"
                  : "var(--NavItem-icon-color)"
              }
              fontSize="var(--icon-fontSize-md)"
              weight={active ? "fill" : undefined}
            />
          ) : null}
        </IconContainer>
        <TitleContainer>
          <StyledNavItemTitle active={active}>{title}</StyledNavItemTitle>
        </TitleContainer>
      </StyledNavBox>
    </li>
  );
}

const StyledNavItemTitle = styled(Typography)(({ theme, active }) => ({
  color: active ? "#ffffff" : theme.palette.text.neutral[700],
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "28px",
}));

const StyledNavBox = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== "disabled" && prop !== "active" && prop !== "as",
})(({ disabled, active }) => ({
  alignItems: "center",
  borderRadius: "8px",
  color: "var(--NavItem-color)",
  cursor: "pointer",
  display: "flex",
  flex: "0 0 auto",
  gap: "8px",
  padding: "8px 16px",
  position: "relative",
  textDecoration: "none",
  whiteSpace: "nowrap",
  ...(disabled && {
    backgroundColor: "var(--NavItem-disabled-background)",
    color: "var(--NavItem-disabled-color)",
    cursor: "not-allowed",
  }),
  ...(active && {
    backgroundColor: "var(--NavItem-active-background)",
    color: "var(--NavItem-active-color)",
  }),
}));

const IconContainer = styled(Box)({
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  flex: "0 0 auto",
});

const TitleContainer = styled(Box)({
  flex: "1 1 auto",
});

import { JobClient, PostClient, EventClient } from "../config"

export const getAllPosts = async (postType, userId) => {

  var Client;
  switch (postType)
  {
    case "post":
      Client = PostClient;
      break;
    case "job":
      Client = JobClient;
      break;
    case "event":
      Client = EventClient;
      break;
    default:
      Client = PostClient;
  }

  try
  {
    let response;
    if (postType === "event")
    {
      response = await Client.get(`/all/${userId}`);

    } else
    {
      response = await Client.get(`/all`, {
        params: { created_by: userId }
      });
    }
    return response;
  } catch (error)
  {
    if (error.response && error.response.status === 500)
    {
      return error.response;
    }
    throw error;
  }
};




export const getAllBookmarks = async (postType, userId) => {
  var Client;
  switch (postType)
  {
    case "job":
      Client = JobClient;
      break;
    case "event":
      Client = EventClient;
      break;
    default:
      Client = JobClient;
  }
  try
  {
    const response = await Client.get(`bookmark/${userId}`);
    return response;
  } catch (error)
  {
    if (error.response && error.response.status === 500)
    {
      return error.response;
    }
    throw error;
  }
};
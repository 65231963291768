import styled from "@emotion/styled";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import AddButton from "../Common/AddButton";
import FormBtn from "../Common/FormBtn";
import { Label } from "../Common/Label";
import { RequiredMark } from "../Common/RequiredMark";
import { useTheme } from "@emotion/react";
import { DELETE_STATUS } from "../../constants/constants";
import { deletePost } from "../../api/Post/post";
import { deleteJobPost } from "../../api/Job/job";

import { useDispatch, useSelector } from "react-redux";
import { setActionData } from "../../redux/Slices/eventListnerSlice";
import dayjs from "dayjs";
import { deleteEvent } from "../../api/Event/event";
import { useNavigate } from "react-router-dom";
import { waitForSeconds } from "../../utils/helpers";

export const DeleteDialog = ({
  openDeleteDialog,
  handleClose,
  type,
  id,
  userId,
  onOpenSnackbar,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const eventListenerData = useSelector((state) => state.eventListener);
  const [deleteError, setDeleteError] = useState("");
  const [deleteStatus, setDeleteStatus] = useState();
  const navigate = useNavigate();

  const handleDeletePostSuccess = async () => {
    onOpenSnackbar(
      `${type.charAt(0).toUpperCase() + type.slice(1)} deleted successfully!`,
      "success"
    );
    handleClose();
    await waitForSeconds(2);
    if (type === "event") {
      navigate("/events");
    }
  };

  const handleDeletePostFailure = () => {
    onOpenSnackbar(`Error while deleting ${type}`, "error");
    handleClose();
  };

  const handleDelete = async () => {
    try {
      setDeleteError("");
      setDeleteStatus(DELETE_STATUS.DELETING);

      var response;
      switch (type) {
        case "post":
          response = await deletePost(id, userId);
          break;
        case "job":
          response = await deleteJobPost(id, userId);
          break;
        case "event":
          response = await deleteEvent(id, userId);
          break;
        default:
          return;
      }
      if (response.status === 200) {
        setDeleteStatus(DELETE_STATUS.DELETED);
        dispatch(
          setActionData({
            ...eventListenerData,
            eventActionId: `post_delete_${id}_${dayjs()}`,
          })
        );
        handleDeletePostSuccess();
      } else {
        setDeleteError("Error while updating post.");
        setDeleteStatus(DELETE_STATUS.FAILED);
        handleDeletePostFailure();
      }
    } catch (error) {
      console.error("Error:", error.message);
      setDeleteError("Error while updating post.");
      setDeleteStatus(DELETE_STATUS.FAILED);
      handleDeletePostFailure();
    }
  };

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={openDeleteDialog}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <CardContainer>
        <DialogTitle>
          <DialogTitleWrapper>
            <StyledDialogTitle>{`Remove ${type} : Are you sure?`}</StyledDialogTitle>
            <CloseOutlinedIcon
              sx={{ color: "#333333", padding: "8px", cursor: "pointer" }}
              onClick={handleClose}
            />
          </DialogTitleWrapper>
        </DialogTitle>
        <DialogContent>
          <StyledDialogSubTitle>
            {`Remove this ${type} from the platform. This action cannot be undone.`}
          </StyledDialogSubTitle>
        </DialogContent>
        <DialogActionsStyled>
          <ButtonContainer>
            <AddButton
              onClick={handleClose}
              isNormalBtn={true}
              isFullWidth={true}
              style={{
                padding: "8px 16px",
                width: "auto",
                color: theme.palette.text.negative[600],
              }}
            >
              Cancel
            </AddButton>
            <FormBtn
              onClick={handleDelete}
              sx={{
                background: theme.palette.background.negative[600],
                width: "auto",
                padding: "8px 16px",
              }}
              disabled={deleteStatus === DELETE_STATUS.DELETING}
              loading={deleteStatus === DELETE_STATUS.DELETING}
            >
              {`Delete ${type}`}
            </FormBtn>
          </ButtonContainer>
        </DialogActionsStyled>
      </CardContainer>
    </Dialog>
  );
};

const StyledDialogTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  font-size: 20px;
  font-weight: 500;
`;

const StyledDialogSubTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[600]};
  font-size: 14px;
  font-weight: 500;
`;

const CardContainer = styled(Box)`
  padding: 16px;
  background: white;
  border-radius: 8px;
`;

const DialogTitleWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const DialogActionsStyled = styled(DialogActions)`
  justify-content: center;
  padding: 16px;
`;

const ButtonContainer = styled(Box)`
  display: flex;
  gap: 16px;
  justify-content: center;
`;

import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
export const navItems = [
  { key: 'home', title: 'Home', href: "/", icon: HomeOutlinedIcon },
  { key: 'jobs', title: 'Jobs', href: "/jobs", icon: WorkOutlineOutlinedIcon },
  { key: 'events', title: 'Events', href: "/events", icon: EventOutlinedIcon },
  { key: 'bookmarks', title: 'Bookmarks', href: "/bookmarks", icon: BookmarkBorderOutlinedIcon },
  { key: 'profile', title: 'Profile', href: "/profile", icon: PersonOutlineOutlinedIcon },
  { key: 'my-posts', title: 'My posts', href: "/my-posts", icon: PersonOutlineOutlinedIcon },
];


export const bottomNavItems = [
  { key: 'aboutus', title: 'About us', href: "/about-us" },
  { key: 'faqs', title: 'FAQs', href: "/faqs" },
  { key: 'legal', title: 'Legal', href: "/legal" },
  { key: 'community', title: 'Community', href: "/community" },

];


export const adminNavItems = [
  { key: 'manage-requests', title: 'Manage requests', href: "/admin/manage-requests", icon: HomeOutlinedIcon },
  { key: 'people-management', title: 'People management', href: "/admin/people-management", icon: WorkOutlineOutlinedIcon },
  { key: 'monitoring-job-posts', title: 'Monitoring job post', href: "/admin/monitoring-job-posts", icon: EventOutlinedIcon },
  { key: 'events', title: 'Events', href: "/admin/events", icon: WorkOutlineOutlinedIcon },
];



import { AdminClient } from "../config"



export const getProfileRequests = async (status, page) => {
  try
  {
    const response = await AdminClient.get(`users`, {
      params: { status, page }
    });
    return response;
  } catch (error)
  {
    console.log(error)
    if (error.response && error.response.status === 500)
    {
      return error.response;
    }
    throw error;
  }
}


export const updateProfileStatus = async (data) => {
  try
  {
    const response = await AdminClient.put(`update-profile`, data);
    return response;
  } catch (error)
  {
    if (error.response && error.response.status === 500)
    {
      return error.response;
    }
    throw error;
  }
};




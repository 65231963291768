import styled from "@emotion/styled";
import ReorderOutlinedIcon from "@mui/icons-material/ReorderOutlined";
import { Avatar, IconButton, Typography, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import AddButton from "../Common/AddButton";
import { CreateEventDialog } from "../Events/CreateEventDialog";
import { JobPostDialog } from "../Jobs/JobPostDialog";
import { MobileNav } from "./MobileNav";
import SearchInput from "./SearchInput";
import { useSelector } from "react-redux";

export function MainNav({ onOpenSnackbar }) {
  const [openNav, setOpenNav] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const location = useLocation();
  const pathname = location.pathname;
  const { firstName, lastName } = useSelector((state) => state.user);

  const shouldShowBorder = () => {
    return (
      pathname.startsWith("/bookmarks") ||
      pathname.startsWith("/profile") ||
      pathname.startsWith("/my-posts") ||
      pathname.startsWith("/admin")
    );
  };

  const getPageName = () => {
    if (pathname.startsWith("/post")) {
      return "Post";
    } else if (pathname.startsWith("/jobs")) {
      return "Jobs";
    } else if (pathname.startsWith("/events")) {
      return "Events";
    } else if (pathname.startsWith("/bookmarks")) {
      return "Bookmarks";
    } else if (pathname.startsWith("/profile")) {
      return "Profile";
    } else if (pathname.startsWith("/my-posts")) {
      return "Posts";
    }
    return "Home";
  };
  return (
    <React.Fragment>
      <HeaderContainer shouldShowBorder={shouldShowBorder()}>
        <HeaderStack direction="column">
          <TitleStack>
            <TitleTypography>{getPageName()}</TitleTypography>
            <IconButton
              onClick={() => {
                setOpenNav(true);
              }}
              sx={{ display: { lg: "none" } }}
            >
              <ReorderOutlinedIcon />
            </IconButton>
            {!isMobile && (
              <StyledUserBox>
                <Avatar
                  src={"/assets/images/avatar-1.png"}
                  style={{ width: "24px", height: "24px" }}
                />
                <StyledUserName>{`${firstName} ${lastName}`}</StyledUserName>
              </StyledUserBox>
            )}
          </TitleStack>
        </HeaderStack>
      </HeaderContainer>

      <MobileNav
        onClose={() => {
          setOpenNav(false);
        }}
        open={openNav}
      />
    </React.Fragment>
  );
}

const HeaderContainer = styled(Box)`
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 1000;
  padding: 0px 24px;
  overflow-x: hidden;
  box-sizing: border-box;
  ${({ theme, shouldShowBorder }) =>
    shouldShowBorder &&
    `border-bottom: 1px solid ${theme.palette.border.neutral[200]};`}

  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding: 0px 8px 0px 16px;
  }
`;

const HeaderStack = styled(Stack)`
  align-items: center;
  justify-content: space-between;
  min-height: 48px;
  ${({ theme }) => theme.breakpoints.down("sm")} {
  }
`;

const TitleStack = styled(Stack)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 0px;
  justify-content: space-between;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding: 8px 0px;
  }
`;

const TitleTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  font-size: 20px;
  font-weight: 600;
  word-wrap: break-word;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    font-size: 16px;
  }
`;

const StyledUserBox = styled(Box)`
  align-items: center;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  padding: 4px 12px;
  gap: 12px;
`;

const StyledUserName = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
`;

export default MainNav;

import CssBaseline from "@mui/material/CssBaseline";
import { useTheme } from "@mui/material/styles";
import * as React from "react";

import styled from "@emotion/styled";
import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import FormBtn from "../../component/Common/FormBtn";
import FormInput from "../../component/Common/FormInput";
import { TermsAndPolicyFooter } from "../../component/Common/TermsAndPolicyFooter";

import "swiper/css";
import "swiper/css/pagination";
import CorosoleContent from "../../component/OnBoarding/CorosoulContent";

export const ForgotPassword = () => {
  const theme = useTheme();

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
      password: data.get("password"),
    });
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid item xs={12} sm={6} md={6} component={Paper} elevation={6} square>
        <Container maxWidth="sm">
          <Box
            sx={{
              mx: { xs: 1, sm: 6 },
              my: { xs: 4, sm: 6, md: 10 },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ my: 1 }}
            >
              <Box sx={{ alignSelf: "flex-start", mb: 2 }}>
                <StyledTitle>Forgot password</StyledTitle>
                <StyledSubTitle>
                  No problem, we’ll send link to your email to reset your
                  password
                </StyledSubTitle>
              </Box>
              <FormInput
                label="Email"
                id="email"
                name="email"
                type="email"
                placeholder="Enter your registered e-mail"
                required
              />

              <Box
                sx={{
                  mt: 2,
                }}
              >
                <FormBtn>Reset password</FormBtn>
              </Box>

              <TermsAndPolicyFooter />
            </Box>
          </Box>
        </Container>
      </Grid>
      <Grid
        item
        xs={false}
        sm={6}
        md={6}
        sx={{
          display: { xs: "none", sm: "block" },
          backgroundColor: theme.palette.primary[200],
        }}
      >
        <CorosoleContent />
      </Grid>
    </Grid>
  );
};

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin: 6px;
  }

  ${({ theme }) => theme.breakpoints.down("md")} {
    margin: 10px 8px;
  }

  ${({ theme }) => theme.breakpoints.down("lg")} {
    margin: 16px;
  }
`;

const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  font-size: 24px;
  font-style: normal;
  font-weight: 500;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    font-size: 16px;
  }
`;

const StyledSubTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
`;

import React from "react";
import Skeleton from "@mui/material/Skeleton";
import { Box as MuiBox, Card as MuiCard } from "@mui/material";
import styled from "@emotion/styled";

export const CommentTemplateSkeleton = () => {
  return (
    <>
      <Card>
        <FlexBox>
          <Skeleton
            animation="wave"
            variant="circular"
            width={40}
            height={40}
          />
          <Box flexGrow={1}>
            <Skeleton animation="wave" height={15} width="30%" />
            <Skeleton animation="wave" height={15} width="50%" />
          </Box>
        </FlexBox>

        <Box mt={2}>
          <Skeleton animation="wave" height={15} width="100%" />
          <Skeleton animation="wave" height={15} width="100%" />
        </Box>
      </Card>
      <Card ml={10}>
        <FlexBox>
          <Skeleton
            animation="wave"
            variant="circular"
            width={40}
            height={40}
          />
          <Box flexGrow={1}>
            <Skeleton animation="wave" height={15} width="30%" />
            <Skeleton animation="wave" height={15} width="50%" />
          </Box>
        </FlexBox>

        <Box mt={2}>
          <Skeleton animation="wave" height={15} width="100%" />
          <Skeleton animation="wave" height={15} width="100%" />
        </Box>
      </Card>
    </>
  );
};

// Styled components using Emotion
const Card = styled(MuiBox)`
  padding: 16px;
  margin-top: 16px;
`;

const FlexBox = styled(MuiBox)`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const Box = styled(MuiBox)`
  margin-top: ${({ mt }) => mt * 8}
  flex-grow: ${({ flexGrow }) => flexGrow || "initial"};
`;

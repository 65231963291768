import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {
  Avatar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateProfileStatus } from "../../api/Admin/admin";
import {
  EMPLOYMENT_STATUS,
  POST_STATUS,
  USER_PROFILE_STATUS,
} from "../../constants/constants";
import { setActionData } from "../../redux/Slices/eventListnerSlice";
import { getEmploymentStatus, getEmploymentType } from "../../utils/helpers";
import FormBtn from "../Common/FormBtn";
import InverseBtn from "../Common/InverseBtn";
import dayjs from "dayjs";
import { DeleteProfileDialog } from "./DeleteProfileDialog";

export const ProfileDetailsDialog = ({
  openProfileDetailsDialog,
  handleCloseUserProfileDialog,
  handleClose,
  onOpenSnackbar,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.userProfile);
  const [profileActionStatus, setProfileActionStatus] = useState();
  const [openRejectDialog, setOpenRejectDialog] = useState(false); // State for second dialog
  const [rejectionType, setRejectionType] = useState("");
  const eventListenerData = useSelector((state) => state.eventListener);
  const reason = "";

  const getSalaryRange = () => {
    return `€${userProfile?.min_salary} - €${userProfile?.max_salary}`;
  };

  const handleProfileUpdateFailure = () => {
    onOpenSnackbar("Error while updating profile status.", "error");
    setProfileActionStatus(POST_STATUS.FAILED);
    handleCloseUserProfileDialog();
  };

  const handleProfileUpdateSuccess = (user_id) => {
    setProfileActionStatus(POST_STATUS.SAVED);
    dispatch(
      setActionData({
        ...eventListenerData,
        profileRequestActionId: `profile_status_${user_id}_${dayjs()}`,
      })
    );
    handleCloseUserProfileDialog();
    onOpenSnackbar("Profile status updated successfully!", "success");
  };

  const handleClickAction = async (status, user_id) => {
    try {
      const data = {
        user_id,
        status,
        reason,
      };
      setProfileActionStatus(POST_STATUS.POSTING);
      const response = await updateProfileStatus(data);
      if (response.status === 200) {
        handleProfileUpdateSuccess(user_id);
      } else {
        handleProfileUpdateFailure();
      }
    } catch (error) {
      handleProfileUpdateFailure();
    }
  };

  const handleOpenRejectDialog = (type) => {
    setRejectionType(type);
    setOpenRejectDialog(true);
  };

  const handleCloseRejectDialog = () => {
    setRejectionType("");
    setOpenRejectDialog(false);
  };

  return (
    <>
      <Dialog
        maxWidth="md"
        fullWidth
        open={openProfileDetailsDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <CardContainer>
          <DialogTitle>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <StyledDialogTitle>Profile details</StyledDialogTitle>
              <CloseOutlinedIcon
                sx={{ color: "#333333", padding: "8px", cursor: "pointer" }}
                onClick={handleClose}
              />
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box>
              <UserContainer>
                <AvatarContainer>
                  <Avatar
                    sx={{ height: 64, width: 64 }}
                    src="/assets/images/avatar-1.png"
                  />
                </AvatarContainer>
                <UserDetails>
                  <StyledUsername>
                    {userProfile?.first_name} {userProfile?.last_name}{" "}
                  </StyledUsername>
                  <FormBtn sx={{ padding: "8px 16px" }}>
                    Write to {userProfile?.first_name} {userProfile?.last_name}{" "}
                  </FormBtn>{" "}
                </UserDetails>
              </UserContainer>
              <Box
                sx={{
                  borderRadius: "8px",
                  marginTop: "24px",
                }}
              >
                <Box p={2}>
                  <StyledLable>
                    {" "}
                    <StyledRequiredMark component={"span"}>
                      *{" "}
                    </StyledRequiredMark>
                    About me
                  </StyledLable>
                  <StyledDescription>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam
                  </StyledDescription>

                  <Grid container mt={1.5}>
                    <Grid item xs={12} sm={12} md={6}>
                      <StyledLable>
                        {" "}
                        <StyledRequiredMark component={"span"}>
                          *{" "}
                        </StyledRequiredMark>
                        Phone number
                      </StyledLable>
                      <StyledDescription>
                        {" "}
                        {userProfile?.phone_number}
                      </StyledDescription>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <StyledLable>
                        {" "}
                        <StyledRequiredMark component={"span"}>
                          *{" "}
                        </StyledRequiredMark>
                        Country
                      </StyledLable>
                      <StyledDescription>
                        {" "}
                        {userProfile?.country}
                      </StyledDescription>
                    </Grid>
                  </Grid>
                  <Grid container mt={1.5}>
                    <Grid item xs={12} sm={12} md={6}>
                      <StyledLable>
                        {" "}
                        <StyledRequiredMark component={"span"}>
                          *{" "}
                        </StyledRequiredMark>
                        City
                      </StyledLable>
                      <StyledDescription>
                        {" "}
                        {userProfile?.city}
                      </StyledDescription>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <StyledLable>
                        {" "}
                        <StyledRequiredMark component={"span"}>
                          *{" "}
                        </StyledRequiredMark>
                        LinkedIn or Xing
                      </StyledLable>
                      <StyledDescription>
                        {userProfile?.platform_url}{" "}
                      </StyledDescription>
                    </Grid>
                  </Grid>
                  <Grid container mt={1.5}>
                    <Grid item xs={12} sm={12} md={6}>
                      <StyledLable>
                        {" "}
                        <StyledRequiredMark component={"span"}>
                          *{" "}
                        </StyledRequiredMark>
                        Employment type
                      </StyledLable>
                      <StyledDescription>
                        {" "}
                        {getEmploymentType(userProfile?.employment_type)}
                      </StyledDescription>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <StyledLable>
                        {" "}
                        <StyledRequiredMark component={"span"}>
                          *{" "}
                        </StyledRequiredMark>
                        Employment status
                      </StyledLable>
                      <StyledDescription>
                        {" "}
                        {getEmploymentStatus(userProfile?.employment_status)}
                      </StyledDescription>
                    </Grid>
                  </Grid>

                  {(userProfile?.employment_status ===
                    EMPLOYMENT_STATUS.CURRENTLY_EMPLOYED ||
                    userProfile?.employment_status ===
                      EMPLOYMENT_STATUS.EMPLOYED_AND_SEEKING_JOB) && (
                    <Grid container mt={1.5}>
                      <Grid item xs={12} sm={12} md={6}>
                        <StyledLable>
                          {" "}
                          <StyledRequiredMark component={"span"}>
                            *{" "}
                          </StyledRequiredMark>
                          Current Profile
                        </StyledLable>
                        <StyledDescription>
                          {" "}
                          {userProfile?.current_job_title}
                        </StyledDescription>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <StyledLable>Current company</StyledLable>
                        <StyledDescription>
                          {" "}
                          {userProfile?.current_company}
                        </StyledDescription>
                      </Grid>
                    </Grid>
                  )}

                  {(userProfile?.employment_status ===
                    EMPLOYMENT_STATUS.ACTIVELY_LOOKING_FOR_JOB ||
                    userProfile?.employment_status ===
                      EMPLOYMENT_STATUS.EMPLOYED_AND_SEEKING_JOB) && (
                    <Grid container mt={1.5}>
                      <Grid item xs={12} sm={12} md={6}>
                        <StyledLable>
                          {" "}
                          <StyledRequiredMark component={"span"}>
                            *{" "}
                          </StyledRequiredMark>
                          Preferred position
                        </StyledLable>
                        <StyledDescription>
                          {userProfile?.preferred_position}
                        </StyledDescription>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <StyledLable>Salary range</StyledLable>
                        <StyledDescription>
                          {getSalaryRange()}
                        </StyledDescription>
                      </Grid>
                    </Grid>
                  )}

                  <Grid container mt={1.5}>
                    <Grid item xs={12} sm={12} md={6}>
                      <StyledLable> Referred by</StyledLable>
                      <StyledDescription>
                        {userProfile?.referred_by
                          ? userProfile?.referred_by
                          : "--"}
                      </StyledDescription>
                    </Grid>
                  </Grid>
                  <Grid container mt={1.5}>
                    <Grid item xs={12} sm={12} md={6}>
                      <StyledLable>Domain</StyledLable>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "8px",
                          marginTop: "8px",
                        }}
                      >
                        {userProfile?.domains.length > 0
                          ? userProfile?.domains.map((domain) => (
                              <Tag component="span"> {domain}</Tag>
                            ))
                          : "--"}
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <StyledLable>Past companies</StyledLable>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "8px",
                          marginTop: "8px",
                        }}
                      >
                        {userProfile?.past_companies.length > 0
                          ? userProfile?.past_companies.map((pc) => (
                              <Tag component="span"> {pc}</Tag>
                            ))
                          : "--"}{" "}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center", marginTop: "16px" }}>
            <FormBtn
              sx={{
                width: "auto",
                padding: "6px 16px",
              }}
              onClick={() =>
                handleClickAction(
                  USER_PROFILE_STATUS.ACTIVE,
                  userProfile?.user_id
                )
              }
            >
              Accept
            </FormBtn>
            <InverseBtn onClick={() => handleOpenRejectDialog("remove")}>
              Decline
            </InverseBtn>

            <InverseBtn onClick={() => handleOpenRejectDialog("hold")}>
              On hold
            </InverseBtn>
          </DialogActions>
        </CardContainer>
      </Dialog>
      <DeleteProfileDialog
        openDeleteProfileDialog={openRejectDialog}
        handleCloseUserProfileDialog={handleCloseUserProfileDialog}
        handleClose={handleCloseRejectDialog}
        onOpenSnackbar={onOpenSnackbar}
        type={rejectionType}
        userId={userProfile?.user_id}
      />
    </>
  );
};

const StyledDialogTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  font-size: 20px;
  font-weight: 500;
`;

const StyledUsername = styled(Typography)`
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.text.neutral[600]};
  text-transform: capitalize;
`;

const CardContainer = styled(Box)`
  padding: 16px;
  background: white;
  border-radius: 8px;
`;

const AvatarContainer = styled(Box)`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  z-index: 0;
`;

const UserContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  margin-top: 24px;
`;

const UserDetails = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledRequiredMark = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.crimson};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`;

const StyledLable = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[600]};
  font-size: 14px;
  font-weight: 400;
  word-wrap: break-word;
`;

const StyledDescription = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  font-size: 14px;
  font-weight: 600;
  word-wrap: break-word;
`;

const Tag = styled(Typography)`
  background: ${({ theme }) => theme.palette.common.purple};
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  padding: 4px 14px;
  border-radius: 4px;
`;

import { css } from "@emotion/react";
import styled from "@emotion/styled";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Avatar, Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { getStatusText, timeAgo } from "../../utils/helpers";
import FormBtn from "../Common/FormBtn";
import InverseBtn from "../Common/InverseBtn";
import { Tag } from "../Common/Tag";

import {
  addJobPostBookmark,
  removeJobPostBookmark,
} from "../../redux/Slices/userSlice";
import EditButton from "../Common/EditButton";
import DeleteButton from "../Common/DeleteButton";
import {
  resetJobPostState,
  setJobPostData,
} from "../../redux/Slices/jobPostSlice";
import { EditJobPostDialog } from "./EditJobPostDialog";
import { DeleteDialog } from "../Common/DeleteDialog";
import { sendJobApplication, toggleBookmarkJob } from "../../api/Job/job";
import { POST_STATUS } from "../../constants/constants";
import { setActionData } from "../../redux/Slices/eventListnerSlice";
import dayjs from "dayjs";

export const JobsCard = ({ job, onOpenSnackbar }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const location = useLocation();
  const [bookmarkStatus, setBookmarkStaus] = useState("");
  const bookmarks = useSelector((state) => state.user.jobPostBookmarks);
  const isJobPostBookMarked = bookmarks?.includes(job?.job_post_id);
  const { userId, email, firstName, lastName, country, state, city } =
    useSelector((state) => state.user);
  const jobPostState = useSelector((state) => state.jobPost);
  const pathname = location.pathname;
  const [openEditJobPostDialog, setOpenEditJobPostDialog] = useState(false);
  const [openDeleteJobPostDialog, setOpenDeleteJobPostDialog] = useState(false);
  const [jobApplicationError, setJobApplicationError] = useState("");
  const [jobApplicationStatus, setJobApplicationStatus] = useState("");
  const eventListenerData = useSelector((state) => state.eventListener);

  const handleOpenEditJobPostDialog = () => {
    const formData = {
      company_name: job?.company_name,
      position: job?.position,
      job_type: job?.job_type,
      region: job?.region,
      job_link: job?.job_link,
      description: job?.description,
      salary_range: [job?.min_salary, job?.max_salary],
      job_post_status: job?.job_post_status,
    };
    dispatch(
      setJobPostData({
        ...jobPostState,
        ...formData,
      })
    );
    setOpenEditJobPostDialog(true);
  };

  const handleCloseEditJobPostDialog = (event) => {
    dispatch(resetJobPostState());
    setOpenEditJobPostDialog(false);
  };

  const handleOpenDeleteJobPostDialog = (event) => {
    setOpenDeleteJobPostDialog(true);
  };
  const handleCloseDeleteJobPostDialog = (event) => {
    setOpenDeleteJobPostDialog(false);
  };

  const handleJobApplicationSuccess = () => {
    setJobApplicationStatus(POST_STATUS.SAVED);
    dispatch(
      setActionData({
        ...eventListenerData,
        jobPostActionId: `send_job_application${dayjs()}`,
      })
    );
    onOpenSnackbar(
      "Job application submitted successfully! Best of luck!",
      "success"
    );
  };

  const handleJobApplicationFailure = () => {
    setJobApplicationStatus(POST_STATUS.FAILED);
    setJobApplicationError(
      "Error submitting your job application. Please try later."
    );
    onOpenSnackbar(
      "Error submitting your job application. Please try later.",
      "error"
    );
  };

  const sentJobApplication = async () => {
    try {
      setJobApplicationError("");
      setJobApplicationStatus(POST_STATUS.POSTING);
      const jobApplicationData = {
        job_poster: `${job?.user?.first_name} ${job?.user?.last_name}`,
        job_poster_email: `${email}`,
        applicant_id: userId,
        applicant_name: `${firstName} ${lastName}`,
        applicant_city: city,
        applicant_country: country,
        applicant_state: state,
        job_position: `${job?.position}`,
        job_company: `${job?.company_name}`,
        job_id: `${job?.job_post_id}`,
      };
      const response = await sendJobApplication(jobApplicationData);
      if (response.status === 200) {
        handleJobApplicationSuccess();
      } else {
        handleJobApplicationFailure();
      }
    } catch (error) {
      handleJobApplicationFailure();
    }
  };

  const toggleBookmark = () => {
    if (isJobPostBookMarked) {
      dispatch(removeJobPostBookmark(job?.job_post_id));
      postBookmarkJob("delete");
    } else {
      dispatch(addJobPostBookmark(job.job_post_id));
      postBookmarkJob("create");
    }
  };

  const postBookmarkJob = async (action) => {
    try {
      const data = {
        job_post_id: job?.job_post_id,
        user_id: userId,
      };
      setBookmarkStaus(POST_STATUS.POSTING);
      const response = await toggleBookmarkJob(data, action);
      if (response.status === 200) {
        setBookmarkStaus(POST_STATUS.SAVED);
      } else {
        console.log("Error while bookmarking job.");
        setBookmarkStaus(POST_STATUS.FAILED);
      }
    } catch (error) {
      console.log("Error while bookmarking job", error.message);
      setBookmarkStaus(POST_STATUS.FAILED);
    }
  };

  const hasUserApplied = job?.jobApplications?.some(
    (application) => application?.user_id === userId
  );
  return (
    <CardContainer>
      <TopSection>
        <UserContainer>
          <AvatarContainer>
            <Avatar src="/assets/images/avatar-1.png" />
          </AvatarContainer>
          <UserDetails>
            <UserName>{`${job?.user?.first_name} ${job?.user?.last_name}`}</UserName>
            <UserTitle>Senior software developer</UserTitle>
          </UserDetails>
        </UserContainer>
        <TimeStampContainer>
          <TimeStamp>{timeAgo(job?.createdAt)}</TimeStamp>
          <Typography component={"span"} sx={{ pt: 1 }}>
            <MoreHorizIcon />
          </Typography>
        </TimeStampContainer>
      </TopSection>
      <TopSection isCompany={true}>
        <UserContainer>
          <AvatarContainer>
            <Avatar
              sx={{ width: 52, height: 52 }}
              src="/assets/images/compony-logo.png"
            />
          </AvatarContainer>
          <UserDetails isCompany={true}>
            <UserName>{job?.company_name}</UserName>
            <UserTitle>{job?.region}</UserTitle>
          </UserDetails>
        </UserContainer>
        {isJobPostBookMarked ? (
          <BookmarkIcon
            onClick={toggleBookmark}
            sx={{ color: theme.palette.common.purple }}
          />
        ) : (
          <BookmarkBorderOutlinedIcon
            onClick={toggleBookmark}
            sx={{ color: "#71717A" }}
          />
        )}{" "}
      </TopSection>
      <JobDetailsContainer>
        {job?.position && <Title>{job?.position}</Title>}
        <Description>Description</Description>
        {job?.description && (
          <JobDescription>{job?.description}</JobDescription>
        )}
      </JobDetailsContainer>
      <LinkContainer>
        <Link to={job?.job_link}>
          <img src="/assets/icons/link-external.svg" />
        </Link>
        <LinkText>{job?.job_link}</LinkText>
      </LinkContainer>
      <TagContainer sx={{ marginTop: "12px" }}>
        {/* <Tag title="Vollzeit" />
        <Tag title="Hybrid" /> */}
        {userId === job?.created_by && pathname === "/my-posts" && (
          <Tag title={getStatusText(job?.job_post_status)} />
        )}
      </TagContainer>
      {hasUserApplied && (
        <TagContainer
          sx={{
            marginTop: "8px",
          }}
        >
          <Tag
            title="Applied"
            sx={{
              color: theme.palette.text.positive[800],
              background: theme.palette.background.positive[100],
            }}
          />
        </TagContainer>
      )}

      {userId === job?.created_by && pathname === "/my-posts" ? (
        <BtnContainer>
          <EditButton onClick={handleOpenEditJobPostDialog} />
          <DeleteButton onClick={handleOpenDeleteJobPostDialog} />
        </BtnContainer>
      ) : (
        <BtnContainer>
          <InverseBtn sx={{ padding: "8px 12px" }}>Write a message</InverseBtn>
          {!hasUserApplied && (
            <FormBtn
              loading={jobApplicationStatus === POST_STATUS.POSTING}
              disabled={jobApplicationStatus === POST_STATUS.POSTING}
              sx={{ width: "auto", padding: "8px 12px" }}
              onClick={sentJobApplication}
            >
              Apply directly
            </FormBtn>
          )}
        </BtnContainer>
      )}
      <EditJobPostDialog
        openEditPostJobDialog={openEditJobPostDialog}
        handleClose={handleCloseEditJobPostDialog}
        onOpenSnackbar={onOpenSnackbar}
        jobPostData={job}
      />
      <DeleteDialog
        id={job?.job_post_id}
        userId={userId}
        type="job"
        onOpenSnackbar={onOpenSnackbar}
        openDeleteDialog={openDeleteJobPostDialog}
        handleClose={handleCloseDeleteJobPostDialog}
      />
    </CardContainer>
  );
};

const CardContainer = styled(Box)`
  padding: 16px 12px;
  background: white;
  border-radius: 8px;
  margin-top: 16px;
  border: 1px solid ${({ theme }) => theme.palette.border.neutral[400]};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const TopSection = styled(Box)`
  width: 100%;
  padding-bottom: 4px;
  border-bottom: ${(props) => (props.isCompany ? "none" : "1px solid #e4e4e7")};
  margin-top: ${(props) => (props.isCompany ? "16px" : "0px")};

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
`;

const AvatarContainer = styled(Box)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

const UserContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const TimeStampContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TimeStamp = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[600]};
  font-size: 12px;
  font-weight: 400;
  word-wrap: break-word;
  margin-right: 16px;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin-right: 8px;
  }
`;

const UserDetails = styled(Box)`
  display: flex;
  flex-direction: column;
  padding-left: ${(props) => (props.isCompany ? "16px" : "0px")};
`;

const UserName = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[800]};
  font-size: 16px;
  font-family: Inter;
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
  text-transform: capitalize;
`;

const TagContainer = styled(Box)`
  margin-top: 16px;
  display: flex;
  flexdirection: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;

  ${(props) =>
    props.sx &&
    css`
      ${props.sx}
    `}
`;

const UserTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  font-size: 12px;
  font-family: Inter;
  font-weight: 400;
  line-height: 18px;
  text-transform: capitalize;
  word-wrap: break-word;
`;

const JobDetailsContainer = styled(Box)`
  margin-top: 16px;
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 16px;
  font-weight: 500;
  margin: 4px 0px;
  letter-spacing: 0.16px;
  word-wrap: break-word;
`;

const JobDescription = styled.p`
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  margin: 4px 0px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.24px;
  word-wrap: break-word;
`;
const Description = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[800]};
  font-size: 12px;
  font-weight: 500;
  word-wrap: break-word;
  margin-top: 8px;
`;

const BtnContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 16px;
`;

const LinkContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const LinkText = styled(Link)`
  color: #007aff;
  font-size: 12px;
  font-weight: 400;
  text-decoration: none;
  ${({ theme }) => theme.breakpoints.down("sm")} {
  }
`;

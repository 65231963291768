import styled from "@emotion/styled";
import {
  Alert,
  Container,
  Grid,
  Snackbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getAllBookmarks } from "../../api/MyPost/myPost";
import { EventCard } from "../../component/Events/EventCard";
import { EventCardSkeleton } from "../../component/Events/EventCardSkeleton";
import { JobsCard } from "../../component/Jobs/JobsCard";
import { JobCardSkeleton } from "../../component/Jobs/JobsCardSkeleton";
import { GET_STATUS } from "../../constants/constants";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ mt: 2 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const Bookmarks = () => {
  const [postFetchingError, setPostFetchingError] = useState("");
  const [postFetchingStatus, setPostFetchingStatus] = useState();
  const [jobPosts, setJobPosts] = useState([]);
  const [events, setEvents] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const userId = useSelector((state) => state.user.userId);
  const { postActionId, jobPostActionId, eventActionId } = useSelector(
    (state) => state.eventListener
  );

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const handleOpenSnackbar = (message, severity = "success") => {
    setSnackbar({
      open: true,
      message,
      severity,
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };
  const setData = (postType, data) => {
    switch (postType) {
      case "job":
        setJobPosts(data);
        break;
      case "event":
        setEvents(data);
        break;
      default:
        return;
    }
  };

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const fetchPosts = async (tabIndex) => {
    try {
      setPostFetchingError("");
      setPostFetchingStatus(GET_STATUS.FETCHING);
      let postType;
      switch (tabIndex) {
        case 0:
          postType = "job";
          break;
        case 1:
          postType = "event";
          break;
        default:
          postType = "job"; // Fallback if no tab is selected
      }

      const response = await getAllBookmarks(postType, userId);
      if (response.status === 200) {
        const data = response.data.data;
        setData(postType, data);
        setPostFetchingStatus(GET_STATUS.FETCHED);
      } else {
        setPostFetchingError("Error while fetching posts.");
        setPostFetchingStatus(GET_STATUS.FAILED);
      }
    } catch (error) {
      setPostFetchingError("Error while fetching posts.");
      setPostFetchingStatus(GET_STATUS.FAILED);
    }
  };

  useEffect(() => {
    fetchPosts(selectedTab);
  }, [selectedTab, postActionId, eventActionId, jobPostActionId]);

  return (
    <Container maxWidth="xl" sx={{ py: 3, pt: 1 }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          variant={isMobile ? "scrollable" : "standard"}
          scrollButtons={isMobile ? "auto" : false}
          allowScrollButtonsMobile
        >
          <StyledTab label="Job posts" {...a11yProps(0)} />
          <StyledTab label="Events" {...a11yProps(1)} />
        </Tabs>
      </Box>

      <CustomTabPanel value={selectedTab} index={0}>
        {postFetchingStatus === GET_STATUS.FETCHING ? (
          <JobCardSkeleton /> // Show loading skeleton while fetching data
        ) : postFetchingStatus === GET_STATUS.FAILED ? (
          <Box>
            <Alert severity="error">
              Error fetching posts. Please try again later.
            </Alert>
          </Box>
        ) : jobPosts.length === 0 ? (
          <Box>
            <Alert severity="info">No posts found.</Alert>
          </Box>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={8}>
              {jobPosts.map((job) => (
                <JobsCard
                  onOpenSnackbar={handleOpenSnackbar}
                  key={job?.job_post_id}
                  job={job}
                />
              ))}
            </Grid>
            <Grid item xs={12} sm={12} md={4}></Grid>
          </Grid>
        )}
      </CustomTabPanel>
      <CustomTabPanel value={selectedTab} index={1}>
        {postFetchingStatus === GET_STATUS.FETCHING ? (
          <EventCardSkeleton />
        ) : postFetchingStatus === GET_STATUS.FAILED ? (
          <Box>
            <Alert severity="error">
              Error fetching events. Please try again later.
            </Alert>
          </Box>
        ) : events.length === 0 ? (
          <Box>
            <Alert severity="info">No events found.</Alert>
          </Box>
        ) : (
          <Grid container spacing={2}>
            {events.map((event, index) => (
              <Grid item xs={12} sm={12} md={4} key={index}>
                <EventCard
                  onOpenSnackbar={handleOpenSnackbar}
                  image="/assets/images/events.png"
                  event={event}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </CustomTabPanel>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

const StyledTab = styled(Tab)`
  text-transform: none;
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  min-width: 0 !important;
  width: auto !important;
`;

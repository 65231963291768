import { createSlice } from "@reduxjs/toolkit";
import { EMPLOYMENT_STATUS } from "../../constants/constants";

const initialState = {
  first_name: "",
  last_name: "",
  country: "",
  state: "",
  city: "",
  country_id: "",
  state_id: "",
  city_id: null,
  phone_number: "",
  platform: "LinkedIn",
  platform_url: "",
  referred_by: "",
  employment_status: EMPLOYMENT_STATUS.CURRENTLY_EMPLOYED,
  current_job_title: "",
  current_company: "",
  salary_range: [10000, 50000],
  employment_type: "",
  preferred_position: "",
  is_active: false,
  is_user_verified: false,
  is_phone_verified: false,
  is_email_verified: false,
  is_user_onboarded: false,
  domains: [],
  past_companies: [],
  active_onboarding_step: 1,
};

const userProfileSlice = createSlice({
  name: "userProfile",
  initialState,
  reducers: {
    setUserProfileData: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetUserProfileState: () => initialState,
  },
});

export const { setUserProfileData, resetUserProfileState } = userProfileSlice.actions;
export default userProfileSlice.reducer;


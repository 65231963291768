import React from "react";
import styled from "@emotion/styled";
import { Button as MuiButton } from "@mui/material";
import { css } from "@emotion/react";
import { Box } from "@mui/material";

const StyledButton = styled(MuiButton)`
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.palette.border.neutral[200]};
  padding: 6px 16px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  word-wrap: break-word;
  cursor: pointer;
  text-transform: none;
  transition: all 0.3s ease;
  color: ${({ theme }) => theme.palette.common.purple};
  ${(props) =>
    props.sx &&
    css`
      ${props.sx}
    `}

  ${({ theme }) => theme.breakpoints.down("sm")} {
    font-size: 14px;
  }
`;

const InverseBtn = ({ children, sx, iconPath, ...props }) => {
  return (
    <StyledButton
      sx={sx}
      {...props}
      startIcon={iconPath && <Box component="img" src={iconPath} alt="icon" />}
    >
      {children}
    </StyledButton>
  );
};

export default InverseBtn;

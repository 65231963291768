import React from "react";
import Skeleton from "@mui/material/Skeleton";
import { Box, Grid } from "@mui/material";
import Card from "@mui/material/Card";

export const EventCardSkeleton = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={4}>
        <Card sx={{ p: 2 }}>
          {" "}
          <Skeleton
            variant="rectangular"
            width="100%"
            height={118}
            sx={{ borderRadius: "4px" }}
          />
          <Skeleton width="15%" height={30} />
          <Box mt={1}>
            <Skeleton width="80%" />
            <Skeleton width="100%" />
          </Box>
          <Box mt={2}>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <Card sx={{ p: 2 }}>
          {" "}
          <Skeleton
            variant="rectangular"
            width="100%"
            height={118}
            sx={{ borderRadius: "4px" }}
          />
          <Skeleton width="15%" height={30} />
          <Box mt={1}>
            <Skeleton width="80%" />
            <Skeleton width="100%" />
          </Box>
          <Box mt={2}>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <Card sx={{ p: 2 }}>
          {" "}
          <Skeleton
            variant="rectangular"
            width="100%"
            height={118}
            sx={{ borderRadius: "4px" }}
          />
          <Skeleton width="15%" height={30} />
          <Box mt={1}>
            <Skeleton width="80%" />
            <Skeleton width="100%" />
          </Box>
          <Box mt={2}>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};

import styled from "@emotion/styled";

const CustomSwiperPagination = styled.div`
  .swiper-pagination {
    position: relative;
    margin-top: 24px;
  }

  .swiper-pagination-bullet {
    background: #a1a1a1;
    padding: 1px;
  }
  .swiper-pagination-bullet-active {
    background: #400091;
  }
`;

export default CustomSwiperPagination;
import { createSlice } from "@reduxjs/toolkit";
import { EMPLOYMENT_STATUS } from "../../constants/constants";

const initialState = {
  first_name: "",
  last_name: "",
  country: "",
  state: "",
  city: "",
  country_id: "",
  state_id: "",
  city_id: null,
  phone_number: "",
  platform: "LinkedIn",
  platform_url: "",
  referred_by: "",
  employment_status: EMPLOYMENT_STATUS.CURRENTLY_EMPLOYED,
  current_job_title: "",
  current_company: "",
  salary_range: [10000, 50000],
  employment_type: "",
  preferred_position: "",
  is_active: false,
  is_user_verified: false,
  is_phone_verified: false,
  is_email_verified: false,
  is_user_onboarded: false,
  domains: [],
  past_companies: [],
  active_onboarding_step: 1,
};

const onBoardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    setOnBoardingData: (state, action) => {
      return { ...state, ...action.payload };
    },
    decrementOnboardingStep(state) {
      state.active_onboarding_step -= 1;
    },
    resetOnboardingState: () => initialState,
  },
});

export const { setOnBoardingData, decrementOnboardingStep, resetOnboardingState } = onBoardingSlice.actions;
export default onBoardingSlice.reducer;


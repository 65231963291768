import React from "react";
import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { css } from "@emotion/react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const StyledEditButton = styled(Button)`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.text.negative[600]};
  padding: 5px 12px;
  border-radius: 8px;
  text-transform: none;
  background: #fff;
  border: 1px ${({ theme }) => theme.palette.border.neutral[200]} solid;
  ${(props) =>
    props.sx &&
    css`
      ${props.sx}
    `}
`;

const DeleteButton = ({ onClick, style, isFullWidth = false }) => {
  return (
    <StyledEditButton
      onClick={onClick}
      startIcon={<DeleteOutlineIcon />}
      sx={style}
      fullWidth={isFullWidth}
    >
      delete
    </StyledEditButton>
  );
};

export default DeleteButton;

import React from "react";
import Box from "@mui/material/Box";
import { ReactSVG } from "react-svg";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

const IconContainerStyled = styled(Box)`
  background: ${({ theme }) => theme.palette.common.purple};
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
  ${(props) =>
    props.sx &&
    css`
      ${props.sx}
    `}
`;

const IconButtonV2 = ({ disabled = false, iconPath, sx, onClick }) => {
  return (
    <IconContainerStyled sx={sx} onClick={onClick}>
      <ReactSVG src={iconPath} />
    </IconContainerStyled>
  );
};

export default IconButtonV2;

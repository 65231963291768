import styled from "@emotion/styled";
import {
  Box,
  Container,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { useTheme } from "@mui/material/styles";
import Cookies from "js-cookie";
import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/pagination";
import FormBtn from "../../component/Common/FormBtn";
import { TermsAndPolicyFooter } from "../../component/Common/TermsAndPolicyFooter";
import CorosoleContent from "../../component/OnBoarding/CorosoulContent";
import MobileCorosoleContent from "../../component/OnBoarding/MobileCorosoulContent";
import { jwtDecode } from "jwt-decode";

const OrDivider = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        marginY: 2, // Vertical margin
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          height: "1px",
          backgroundColor: theme.palette.background.neutral[500],
        }}
      />
      <Typography
        sx={{
          paddingX: 2, // Horizontal padding
          color: theme.palette.text.neutral[700],
        }}
      >
        or
      </Typography>
      <Box
        sx={{
          flexGrow: 1,
          height: "1px",
          backgroundColor: theme.palette.background.neutral[500],
        }}
      />
    </Box>
  );
};

export const LandingPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const onSignUp = async () => {
    const token = Cookies.get("accessToken");
    if (token) {
      const decodedAccessToken = await jwtDecode(token);
      const isUserVerified = decodedAccessToken.isUserVerified;
      if (!isUserVerified) {
        navigate("/onboarding");
      } else {
        navigate("/sign-in");
      }
    } else {
      navigate("/welcome");
    }
  };

  const onLogin = () => {
    navigate("/signin");
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />

      <Grid item xs={12} sm={6} md={6} component={Paper} elevation={6} square>
        <Container maxWidth="sm">
          <Box
            sx={{
              mt: 1,
              mx: { xs: 1, sm: 6 },
              my: { xs: 4, sm: 6, md: 10 },
            }}
          >
            {isMobile && <MobileCorosoleContent />}
            {!isMobile && (
              <Box sx={{ alignSelf: "flex-start", mb: 2 }}>
                <StyledTitle>Join Community</StyledTitle>
                <StyledSubTitle>
                  or
                  <StyledSignInLink component={"span"} to={"/signin"}>
                    Login
                  </StyledSignInLink>
                </StyledSubTitle>
              </Box>
            )}

            <Box
              sx={{
                marginTop: { xs: 2, md: 3 },
              }}
            >
              <FormBtn onClick={onSignUp} fullWidth>
                Join community
              </FormBtn>
              <OrDivider />

              <FormBtn
                onClick={onLogin}
                fullWidth
                sx={{
                  background: "white",
                  color: theme.palette.text.neutral[700],
                  border: `1px solid  ${theme.palette.border.neutral[200]}`,
                  "&:hover": {
                    backgroundColor: "white",
                  },
                }}
              >
                Login
              </FormBtn>
            </Box>
            <TermsAndPolicyFooter />
          </Box>
        </Container>
      </Grid>
      <Grid
        item
        xs={false}
        sm={6}
        md={6}
        sx={{
          display: { xs: "none", sm: "block" },
          backgroundColor: theme.palette.primary[200],
        }}
      >
        <CorosoleContent />
      </Grid>
    </Grid>
  );
};

const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  font-size: 24px;
  font-style: normal;
  font-weight: 500;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    font-size: 16px;
  }
`;

const StyledSubTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
`;

const StyledSignInLink = styled(Link)`
  color: ${({ theme }) => theme.palette.common.purple};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  text-decoration-line: underline;
  cursor: pointer;
  margin-left: 6px;
`;

import { createSlice } from "@reduxjs/toolkit";
import { EMPLOYMENT_STATUS, JOB_POST_STATUS, JOB_TYPE } from "../../constants/constants";

const initialState = {
  company_name: "",
  position: "",
  job_type: "",
  region: "",
  job_link: "",
  description: "",
  salary_range: [50000, 500000],
  job_post_status: JOB_POST_STATUS.INIT,
};

const jobPostSlice = createSlice({
  name: "jobPost",
  initialState,
  reducers: {
    setJobPostData: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetJobPostState: () => initialState,
  },
});

export const { setJobPostData, resetJobPostState } = jobPostSlice.actions;
export default jobPostSlice.reducer;


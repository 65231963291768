import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { verifyUserToken } from "../../api/User/user"; // assuming this is your token verification API

const ProtectedRoute = () => {
  const [isVerified, setIsVerified] = useState(false); // null indicates verification is in progress
  const [loading, setLoading] = useState(true); // state to handle loading

  useEffect(() => {
    const checkUserToken = async () => {
      try {
        const response = await verifyUserToken();
        const user = response.data?.user;
        if (response.status === 200) {
          if (user) {
            setIsVerified(user?.isUserVerified);
          }
        } else {
          setIsVerified(false);
        }
      } catch (error) {
        setIsVerified(false);
      } finally {
        setLoading(false);
      }
    };
    checkUserToken();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Show a loading indicator while checking the token
  }

  if (!isVerified) {
    return <Navigate to="/landing" />; // Redirect to landing page if token is invalid
  }

  return <Outlet />; // Render child routes if token is valid
};

export default ProtectedRoute;

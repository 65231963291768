import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import GlobalStyles from "@mui/material/GlobalStyles";
import React, { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { MainNav } from "../component/Layout/MainNav";
import { SideNav } from "../component/Layout/SideNav";
import { Alert, Snackbar } from "@mui/material";

export default function MainLayout() {
  const location = useLocation();

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  // Define the routes where MainNav should be hidden
  const hideMainNavRoutes = ["/post/:postId"];

  // Check if the current path matches any of the routes where MainNav should be hidden
  const shouldHideMainNav = hideMainNavRoutes.some((route) =>
    new RegExp(route.replace(":postId", "\\d+")).test(location.pathname)
  );

  const handleOpenSnackbar = (message, severity = "success") => {
    setSnackbar({
      open: true,
      message,
      severity,
    });
  };
  // Function to close Snackbar
  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <>
      <GlobalStyles
        styles={{
          body: {
            "--MainNav-height": "56px",
            "--MainNav-zIndex": 1000,
            "--SideNav-width": "280px",
            "--SideNav-zIndex": 1100,
            "--MobileNav-width": "320px",
            "--MobileNav-zIndex": 1100,
          },
        }}
      />
      <Box
        sx={{
          bgcolor: "#fff",
          display: "flex",
          flexDirection: "column",
          position: "relative",
          minHeight: "100%",
        }}
      >
        <SideNav />
        <Box
          sx={{
            display: "flex",
            flex: "1 1 auto",
            flexDirection: "column",
            pl: { lg: "var(--SideNav-width)" },
          }}
        >
          {!shouldHideMainNav && (
            <MainNav onOpenSnackbar={handleOpenSnackbar} />
          )}
          <main>
            <Outlet />
          </main>
        </Box>
      </Box>
      {/* Snackbar Component */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}

import React from "react";
import { TextField, InputAdornment, Box, useMediaQuery } from "@mui/material";
import { Search } from "@mui/icons-material";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";

const SearchInput = ({ placeholder }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <StyledSearchBox>
      <StyledTextField
        variant="outlined"
        placeholder={placeholder}
        fullWidth={isMobile}
        isMobile={isMobile}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      />
    </StyledSearchBox>
  );
};

export default SearchInput;

const StyledSearchBox = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 12px 0;
`;

const StyledTextField = styled(TextField)`
  width: ${(props) =>
    props.isMobile ? "100%" : "420px"}; // Apply width based on isMobile

  .MuiOutlinedInput-root {
    padding-left: 8px;
    border-radius: 8px;
  }

  .MuiInputBase-input {
    font-size: 14px;
    padding: 12px;
  }
`;

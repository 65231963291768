import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React from "react";

import { Avatar } from "@mui/material";
import { NavItem } from "./NavItem";
import { navItems, bottomNavItems } from "./config";

export function MobileNav({ open, onClose }) {
  const pathname = window.location.pathname;

  return (
    <StyledDrawer onClose={onClose} open={open}>
      <Stack spacing={2} sx={{ p: 2 }}>
        <StyledUserBox>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Avatar src={"/assets/images/avatar-1.png"} />
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <StyledUserName>Mark</StyledUserName>
              <StyledUserEmail>mark@redefy.design</StyledUserEmail>
            </Box>
          </Box>
        </StyledUserBox>
      </Stack>
      <Box component="nav" sx={{ flex: "1 1 auto", p: "12px" }}>
        {renderNavItems({ pathname, items: navItems })}
      </Box>

      <Box component="nav" sx={{ flex: "0 1 auto", p: "12px" }}>
        {renderbottomItems({ pathname, items: bottomNavItems })}
      </Box>
    </StyledDrawer>
  );
}

function renderNavItems({ items = [], pathname }) {
  const children = items.reduce((acc, curr) => {
    const { key, ...item } = curr;

    acc.push(<NavItem key={key} pathname={pathname} {...item} />);

    return acc;
  }, []);

  return (
    <Stack component="ul" spacing={1} sx={{ listStyle: "none", m: 0, p: 0 }}>
      {children}
    </Stack>
  );
}

function renderbottomItems({ items = [], pathname }) {
  const children = items.reduce((acc, curr) => {
    const { key, ...item } = curr;

    acc.push(<NavItem key={key} pathname={pathname} {...item} />);

    return acc;
  }, []);

  return (
    <Stack component="ul" spacing={1} sx={{ listStyle: "none", m: 0, p: 0 }}>
      {children}
    </Stack>
  );
}

const StyledDrawer = styled(Drawer)`
  --MobileNav-background: "#fff";
  --MobileNav-color: var(--mui-palette-common-white);
  --NavItem-color: #000;
  --NavItem-hover-background: rgba(255, 255, 255, 0.04);
  --NavItem-active-background: #400091;
  --NavItem-active-color: #fff;
  --NavItem-disabled-color: gray;
  --NavItem-icon-color: #000;
  --NavItem-icon-active-color: #ffffff;
  --NavItem-icon-disabled-color: gray;
  background-color: var(--MobileNav-background);
  color: var(--MobileNav-color);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  scrollbar-width: none;
  width: 320px;
  z-index: 1100;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledNavItem = styled(Box)`
  align-items: center;
  border-radius: 1;
  color: var(--NavItem-color);
  cursor: pointer;
  display: flex;
  flex: 0 0 auto;
  gap: 1;
  padding: 6px 16px;
  position: relative;
  text-decoration: none;
  white-space: nowrap;
  ${({ disabled }) =>
    disabled &&
    `
    background-color: var(--NavItem-disabled-background);
    color: var(--NavItem-disabled-color);
    cursor: not-allowed;
  `}
  ${({ active }) =>
    active &&
    `
    background-color: var(--NavItem-active-background);
    color: var(--NavItem-active-color);
  `}
`;

const StyledIconBox = styled(Box)`
  align-items: center;
  display: flex;
  justify-content: center;
  flex: 0 0 auto;

  svg {
    fill: ${({ active }) =>
      active
        ? "var(--NavItem-icon-active-color)"
        : "var(--NavItem-icon-color)"};
    font-size: var(--icon-fontSize-md);
  }
`;

const StyledNavText = styled(Typography)`
  color: inherit;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 28px;
`;

const StyledUserBox = styled(Box)`
  align-items: center;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  padding: 4px 12px;
`;

const StyledUserName = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  font-size: 20px;
  font-weight: 600;
  word-wrap: break-word;
`;

const StyledUserEmail = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  font-size: 14px;
  font-weight: 400;
  word-wrap: break-word;
`;

import styled from "@emotion/styled";
import { Alert, Box, Skeleton, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getUserProfile } from "../../api/User/user";
import FormBtn from "../../component/Common/FormBtn";
import { GET_STATUS, USER_PROFILE_STATUS } from "../../constants/constants";
import { VerificationSkeleton } from "../../component/OnBoarding/VerificationSkeleton";

const OBScreen3 = ({}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [profileFetchingStatus, setProfileFetchingStatus] = useState();
  const { userId } = useSelector((state) => state.user);
  const [remark, setRemark] = useState("");
  const [userProfileStatus, setUserProfileStatus] = useState("");

  const fetchUserProfile = async () => {
    try {
      setProfileFetchingStatus(GET_STATUS.FETCHING);
      const response = await getUserProfile(userId);
      if (response.status === 200) {
        const user = response.data.data;
        setUserProfileStatus(user?.user_profile_status);
        setRemark(user?.remark);
        setProfileFetchingStatus(GET_STATUS.FETCHED);
      } else {
        setProfileFetchingStatus(GET_STATUS.FAILED);
      }
    } catch (error) {
      setProfileFetchingStatus(GET_STATUS.FAILED);
    }
  };

  const getTitle = (status) => {
    let title;
    switch (status) {
      case USER_PROFILE_STATUS.IN_REVIEW:
        title = "Verification in Progress";
        break;
      case USER_PROFILE_STATUS.ACTIVE:
        title = "Profile verified";
        break;
      case USER_PROFILE_STATUS.REJECTED:
        title = "Your Profile rejected";
        break;
      case USER_PROFILE_STATUS.ON_HOLD:
        title = "Your profile is on hold";
        break;
      default:
        title = "Verification in Progress";
    }
    return title;
  };

  const getRemark = (status) => {
    let description;
    switch (status) {
      case USER_PROFILE_STATUS.IN_REVIEW:
        description = `Thank you for submitting your details. Our team is reviewing them.
        Verification may take up to 48 hours. We'll notify you once your profile
        is verified. Thank you for your patience!`;
        break;
      case USER_PROFILE_STATUS.REJECTED:
      case USER_PROFILE_STATUS.ON_HOLD:
        description = remark;
        break;
      case USER_PROFILE_STATUS.ACTIVE:
        description = `Your profile has been verified! Please sign in to access your account.`;
        break;
      default:
        description = `Thank you for submitting your details. Our team is reviewing them.
        Verification may take up to 48 hours. We'll notify you once your profile
        is verified. Thank you for your patience!`;
        break;
    }
    return description;
  };

  useEffect(() => {
    fetchUserProfile();
  }, [userId]);
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      {profileFetchingStatus === GET_STATUS.FETCHING ? (
        <VerificationSkeleton />
      ) : profileFetchingStatus === GET_STATUS.FAILED ? (
        <Box mt={2}>
          <Alert severity="error">
            Error while fetching profile status.Please try later !
          </Alert>
        </Box>
      ) : (
        <Box sx={{ textAlign: "center" }}>
          <Box mt={12}>
            <img src="/assets/images/user-verification.png" />
          </Box>
          <TitleTypography>{getTitle(userProfileStatus)}</TitleTypography>

          <DescriptionTypography>
            {getRemark(userProfileStatus)}
          </DescriptionTypography>

          {userProfileStatus === USER_PROFILE_STATUS.ACTIVE && (
            <FormBtn
              sx={{ marginTop: "24px" }}
              onClick={() => navigate("/signin")}
            >
              {" "}
              Sign in
            </FormBtn>
          )}

          <Box mt={2}>
            <SupportText>
              Please feel free to contact us for any queries.
            </SupportText>
            <StyledLink>Contact us</StyledLink>
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default OBScreen3;

const StyledBtnContainer = styled(Box)`
  margin-top: 24px;
`;

const TitleTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[800]};
  font-size: 24px;
  font-weight: 500;
  margin-top: 16px;
`;

const DescriptionTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  font-size: 14px;
  font-weight: 400;
  word-wrap: break-word;
  margin-top: 16px;
`;

const SupportText = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[600]};
  font-size: 12px;
  font-weight: 400;
  word-wrap: break-word;
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  font-weight: 500;
  font-size: 12px;
`;

import styled from "@emotion/styled";
import {
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React from "react";
import { RequiredMark } from "./RequiredMark";

const CSCSelectInput = ({
  label,
  required = false,
  fullWidth = true,
  margin = "normal",
  options,
  value,
  onChange,
  error,
  helperText,
  disabled,
}) => {
  return (
    <StyledFormControl fullWidth={fullWidth} margin={margin} error={error}>
      <StyledFormLabel error={error}>
        {required && <RequiredMark />} {label}
      </StyledFormLabel>
      <StyledSelect
        value={value}
        onChange={onChange}
        disabled={disabled}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        error={error}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </StyledSelect>
      {helperText && (
        <StyledHelperText error={error}>{helperText}</StyledHelperText>
      )}
    </StyledFormControl>
  );
};

export default CSCSelectInput;

const StyledFormControl = styled(FormControl)`
  width: 100%;
  margin-top: 8px;

  .MuiOutlinedInput-root {
    border: none;

    &:hover fieldset {
      border: 1px solid ${({ theme }) => theme.palette.common.purple};
    }
    &.Mui-focused fieldset {
      border: 1px solid ${({ theme }) => theme.palette.common.purple};
    }
  }
`;

const StyledFormLabel = styled(FormLabel)`
  color: ${({ theme, error }) =>
    error
      ? theme.palette.text.negative[600] + " !important"
      : theme.palette.text.neutral[400]};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.14px;
  line-height: 20px;
`;

const StyledSelect = styled(Select)`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.palette.border.neutral[400]};
  font-size: 14px;
  margin-top: 4px;

  .MuiSelect-select {
    border: none;
    padding: 10px 12px;
  }

  &.Mui-error {
    border-color: ${({ theme }) => theme.palette.text.negative[600]};
  }
`;

const StyledHelperText = styled(Typography)`
  color: ${({ theme, error }) =>
    error ? theme.palette.text.negative[600] : theme.palette.text.neutral[500]};
  font-size: 12px;
  margin-top: 4px;
`;

import CssBaseline from "@mui/material/CssBaseline";
import { useTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import {
  Box,
  Grid,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Container,
  StepConnector,
  Typography,
} from "@mui/material";
import OBScreen1 from "./OBScreen1";
import OBScreen2 from "./OBScreen2";
import OBScreen3 from "./OBScreen3";
import {
  EMPLOYMENT_STATUS,
  ONBOARDING_STEPPER_STEPS,
} from "../../constants/constants";
import WelcomeScreen from "./WelcomeScreen";
import OtpVarification from "./OtpVarification";
import { useDispatch, useSelector } from "react-redux";
import { SignIn } from "@phosphor-icons/react";
import {
  decrementOnboardingStep,
  setOnBoardingData,
} from "../../redux/Slices/onBoardingSlice";

export const Onboarding = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.onboarding);

  const getStepContent = (step) => {
    switch (step) {
      case 1:
        return <OBScreen1 />;
      case 2:
        return <OBScreen2 />;
      case 3:
        return <OBScreen3 />;
      case 10:
        return <OtpVarification />;
      default:
        return <SignIn />;
    }
  };

  const handleBackClick = () => {
    if (formData.active_onboarding_step <= 1) {
      return;
    } else if (formData.active_onboarding_step === 10) {
      dispatch(
        setOnBoardingData({
          ...formData,
          active_onboarding_step: 1,
        })
      );
    } else {
      dispatch(decrementOnboardingStep());
    }
  };

  const shouldReturnBackButton = (step) => {
    return step === 2 || step === 10;
  };

  return (
    <Grid
      container
      component="main"
      sx={{ height: "100vh", overflow: "hidden" }}
    >
      <CssBaseline />
      <Grid
        item
        xs={12}
        sm={6}
        md={6}
        component={Paper}
        elevation={6}
        square
        sx={{ overflowY: "auto", height: "100%" }}
      >
        {shouldReturnBackButton(formData.active_onboarding_step) && (
          <Box
            sx={{
              mx: { xs: 3, sm: 4 },
              my: { xs: 3, sm: 4 },
            }}
          >
            <ArrowBackOutlinedIcon onClick={handleBackClick} />
          </Box>
        )}

        <Box
          sx={{
            mx: { xs: 1, sm: 6, md: 8, lg: 16 },
            my: { xs: 4, sm: formData.active_onboarding_step !== 1 ? 4 : 8 },
          }}
        >
          <Container maxWidth="sm">
            {formData.active_onboarding_step !== 10 && (
              <CustomStepper
                activeStep={formData.active_onboarding_step - 1} // Adjusting activeStep to 0-based index
                alternativeLabel
                connector={
                  <CustomConnector
                    activeStep={formData.active_onboarding_step}
                  />
                }
              >
                {ONBOARDING_STEPPER_STEPS.map((label, index) => (
                  <Step key={label}>
                    <CustomStepLabel StepIconComponent={CustomStepIcon}>
                      {label}
                    </CustomStepLabel>
                  </Step>
                ))}
              </CustomStepper>
            )}

            <Box sx={{ mt: 2, mb: 1 }}>
              {getStepContent(formData.active_onboarding_step)}
            </Box>
          </Container>
        </Box>
      </Grid>
      <Grid
        item
        xs={false}
        sm={6}
        md={6}
        sx={{
          display: { xs: "none", sm: "block" },
          backgroundColor: theme.palette.background.primary[200],
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            mx: { xs: 4, sm: 6, md: 12 },
            my: { xs: 4, sm: 6, md: 10 },
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            color: theme.palette.text.neutral[700],
          }}
        >
          <StyledTestimonialText>
            The Marathi Referral platform was instrumental in helping me secure
            a job at a Bosch . The user-friendly interface and referral feature
            made the process seamless.
          </StyledTestimonialText>

          <StyledTestimonialUser>-Aishwarya Tendulkar</StyledTestimonialUser>
          <StyledTestimonialUserRole>
            Software Engineer
          </StyledTestimonialUserRole>
        </Box>
      </Grid>
    </Grid>
  );
};

const CustomStepLabel = styled(StepLabel)`
  .MuiStepLabel-label {
    color: ${({ theme }) => theme.palette.text.neutral[700]};
    font-weight: 500;
    font-size: 12px;
  }
`;

const CustomConnector = styled(StepConnector)`
  .MuiStepConnector-line {
    width: 48px;
    margin: 0 auto; // Center the line
    border-width: 2px;
    border-color: ${({ theme }) => theme.palette.border.neutral[400]};
  }
`;

const CustomStepper = styled(Stepper)`
  .MuiStep-root {
    flex: 1; // Ensure each step takes equal space
  }
  .MuiStepConnector-root {
    flex: 0; // Do not allow the connector to take any extra space
  }
`;

const CustomStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor: ownerState.active
    ? theme.palette.common.purple
    : theme.palette.background.neutral[100],
  color: ownerState.active ? "#fff" : theme.palette.common.purple,
  zIndex: 1,
  width: 32,
  height: 32,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
}));

const StyledTestimonialText = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  font-size: 16px;
  font-weight: 500;
  word-wrap: break-word;
`;

const StyledTestimonialUser = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  font-size: 14px;
  font-weight: 500;
  margin-top: 16px;
`;

const StyledTestimonialUserRole = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  font-size: 14px;
  font-weight: 400;
  padding-left: 8px;
`;

function CustomStepIcon(props) {
  const { active, completed, icon } = props;

  return (
    <CustomStepIconRoot ownerState={{ active, completed }}>
      {completed ? <CheckOutlinedIcon style={{ fontSize: 20 }} /> : icon}
    </CustomStepIconRoot>
  );
}

import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React from "react";
import { useLocation } from "react-router-dom";

import { Avatar } from "@mui/material";
import { NavItem } from "./NavItem";
import { adminNavItems, bottomNavItems } from "./config";

export function AdminSideNav() {
  const location = useLocation();
  const pathname = location.pathname;
  return (
    <StyledSideNav>
      <Stack spacing={2} px={2} py={1}>
        <StyledUserBox>
          <Avatar
            src={"/assets/images/avatar-1.png"}
            style={{ width: "36px", height: "36px" }}
          />
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <StyledUserName>Marathi Referral</StyledUserName>
          </Box>
        </StyledUserBox>
      </Stack>
      <Box component="nav" sx={{ flex: "1 1 auto", p: "12px" }}>
        {renderNavItems({ pathname, items: adminNavItems })}
      </Box>
      <Box component="nav" sx={{ flex: "0 1 auto", p: "12px" }}>
        {renderbottomItems({ pathname, items: bottomNavItems })}
      </Box>
    </StyledSideNav>
  );
}

function renderNavItems({ items = [], pathname }) {
  const children = items.reduce((acc, curr) => {
    const { key, ...item } = curr;

    acc.push(<NavItem key={key} pathname={pathname} {...item} />);

    return acc;
  }, []);

  return (
    <Stack component="ul" spacing={1} sx={{ listStyle: "none", m: 0, p: 0 }}>
      {children}
    </Stack>
  );
}

function renderbottomItems({ items = [], pathname }) {
  const children = items.reduce((acc, curr) => {
    const { key, ...item } = curr;

    acc.push(<NavItem key={key} pathname={pathname} {...item} />);

    return acc;
  }, []);

  return (
    <Stack component="ul" spacing={1} sx={{ listStyle: "none", m: 0, p: 0 }}>
      {children}
    </Stack>
  );
}

const StyledSideNav = styled(Box)`
  --SideNav-background: #fff;
  --SideNav-color: #ff0000;
  --NavItem-color: #000;
  --NavItem-hover-background: rgba(255, 255, 255, 0.04);
  --NavItem-active-background: #400091;
  --NavItem-active-color: #fff;
  --NavItem-disabled-color: gray;
  --NavItem-icon-color: #000;
  --NavItem-icon-active-color: #ffffff;
  --NavItem-icon-disabled-color: gray;
  background-color: var(--SideNav-background);
  color: var(--SideNav-color);
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  max-width: 100%;
  position: fixed;
  scrollbar-width: none;
  top: 0;
  width: 280px;
  z-index: 1100;
  border-right: 1px solid #e4e4e7;
  &::-webkit-scrollbar {
    display: none;
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    display: none;
  }
`;

const StyledUserBox = styled(Box)`
  align-items: center;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  padding: 4px 12px;
  gap: 12px;
`;

const StyledUserName = styled(Typography)`
  color: #333333;
  font-size: 20px;
  font-weight: 600;
  word-wrap: break-word;
`;

import { Box, Skeleton } from "@mui/material";
import React from "react";

export const VerificationSkeleton = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box mt={3}>
        <Skeleton variant="circular" width={60} height={60} />
      </Box>
      <Box mt={2}>
        <Skeleton variant="rectangular" width={210} height={118} />
      </Box>
      <Box sx={{ pt: 0.5 }}>
        <Skeleton width={210} />
        <Skeleton width={210} />
        <Skeleton width={150} />
      </Box>
    </Box>
  );
};

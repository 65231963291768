import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { FormLabel } from "@mui/material";
import React from "react";

export const Label = ({ children, ...props }) => {
  return <StyledFormLabel {...props}>{children}</StyledFormLabel>;
};

const StyledFormLabel = styled(FormLabel)`
  color: ${({ theme }) => theme.palette.text.neutral[600]};
  font-size: 14px;
  font-weight: 400;

  ${(props) =>
    props.sx &&
    css`
      ${props.sx}
    `}
`;

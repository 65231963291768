import { UserClient } from "../config"

export const sendOtp = async (userData) => {
  const response = await UserClient.post(`onboarding/send-otp`, userData);
  return response;
}


export const verifyOtp = async (userData) => {
  const response = await UserClient.post(`onboarding/verify-otp`, userData);
  return response;
}

export const onboardUser = async (userData, userId) => {
  try
  {
    const response = await UserClient.post(`onbaording/${userId}`, userData);
    return response;
  } catch (error)
  {
    if (error.response && error.response.status === 404)
    {
      throw new Error("User not found"); // Throw a new error for 404 status
    }
    throw error; // Re-throw the original error for other cases
  }
};

export const getUserProfile = async (userId) => {
  try
  {
    const response = await UserClient.get(`profile/${userId}`);
    return response;
  } catch (error)
  {
    console.log(error)
    if (error.response && error.response.status === 500)
    {
      return error.response;
    }
    throw error;
  }

}


export const updateUserProfile = async (userId, data) => {
  try
  {
    const response = await UserClient.put(`profile/${userId}`, data);
    return response;
  } catch (error)
  {
    if (error.response && error.response.status === 500)
    {
      return error.response;
    }
    throw error;
  }
};


export const verifyUserToken = async () => {
  try
  {
    const response = await UserClient.get(`verify-token`);
    return response;
  } catch (error)
  {
    console.log(error)
    if (error.response && error.response.status === 500)
    {
      return error.response;
    }
    throw error;
  }
}
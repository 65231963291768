import axios from "axios";
import Cookies from 'js-cookie';
export const BASE_URL = "https://marathireferrals.com";
export const local_url = "http://localhost:5500";


export const Authclient = axios.create({
  baseURL: `${BASE_URL}/api/v1/auth/`,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export const UserClient = axios.create({
  baseURL: `${BASE_URL}/api/v1/user/`,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export const JobClient = axios.create({
  baseURL: `${BASE_URL}/api/v1/jobPost/`,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export const EventClient = axios.create({
  baseURL: `${BASE_URL}/api/v1/event/`,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});


export const PostClient = axios.create({
  baseURL: `${BASE_URL}/api/v1/post/`,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export const CommentClient = axios.create({
  baseURL: `${BASE_URL}/api/v1/comment/`,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export const AdminClient = axios.create({
  baseURL: `${BASE_URL}/api/v1/admin/`,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

// Add a request interceptor
Authclient.interceptors.request.use(
  (config) => {
    // Retrieve the token from cookies
    const token = Cookies.get('accessToken'); // Replace 'accessToken' with the name of your cookie

    // If token is found, add it to the Authorization header
    if (token)
    {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    // Handle the error
    return Promise.reject(error);
  }
);


UserClient.interceptors.request.use(
  (config) => {
    // Retrieve the token from cookies
    const token = Cookies.get('accessToken'); // Replace 'accessToken' with the name of your cookie

    // If token is found, add it to the Authorization header
    if (token)
    {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    // Handle the error
    return Promise.reject(error);
  }
);


JobClient.interceptors.request.use(
  (config) => {
    // Retrieve the token from cookies
    const token = Cookies.get('accessToken'); // Replace 'accessToken' with the name of your cookie

    // If token is found, add it to the Authorization header
    if (token)
    {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    // Handle the error
    return Promise.reject(error);
  }
);


EventClient.interceptors.request.use(
  (config) => {
    // Retrieve the token from cookies
    const token = Cookies.get('accessToken'); // Replace 'accessToken' with the name of your cookie

    // If token is found, add it to the Authorization header
    if (token)
    {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    // Handle the error
    return Promise.reject(error);
  }
);


PostClient.interceptors.request.use(
  (config) => {
    const token = Cookies.get('accessToken');

    if (token)
    {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

CommentClient.interceptors.request.use(
  (config) => {
    const token = Cookies.get('accessToken');

    if (token)
    {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);



AdminClient.interceptors.request.use(
  (config) => {
    const token = Cookies.get('accessToken');

    if (token)
    {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
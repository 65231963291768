import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import {
  Alert,
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  Snackbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getAllJobPosts } from "../../api/Job/job";
import AddButton from "../../component/Common/AddButton";
import FormBtn from "../../component/Common/FormBtn";
import { JobsCard } from "../../component/Jobs/JobsCard";
import { JobCardSkeleton } from "../../component/Jobs/JobsCardSkeleton";
import { GET_STATUS } from "../../constants/constants";
import SearchInput from "../../component/Layout/SearchInput";
import { JobPostDialog } from "../../component/Jobs/JobPostDialog";

const StyledFormControlLabel = ({ label, checked, onChange }) => {
  return (
    <FormControlLabel
      control={
        <StyledCheckbox
          checked={checked} // Pass the checked state
          onChange={onChange} // Pass the onChange handler
        />
      }
      label={<StyledLabel>{label}</StyledLabel>}
      sx={{ marginBottom: 0.5 }} // Adjust spacing between checkboxes
    />
  );
};

export const Jobs = () => {
  const [jobFetchingError, setJobFetchingError] = useState("");
  const [jobFetchingStatus, setJobFetchingStatus] = useState();
  const [openPostJobDialog, setOpenPostJobDialog] = useState(false);

  const [openPostDialog, setOpenPostDialog] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [limit, setLimit] = useState(3); // Number of posts per page
  const [offset, setOffset] = useState(0); // Starting point for fetching posts
  const [hasMore, setHasMore] = useState(true); // To determine if there are more posts to load
  const jobPostActionId = useSelector(
    (state) => state.eventListener.jobPostActionId
  );

  const [sortOrder, setSortOrder] = useState("");
  const [jobTypes, setJobTypes] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const fetchJobPosts = async (filters = {}) => {
    try {
      setJobs([]);
      setJobFetchingError("");
      setJobFetchingStatus(GET_STATUS.FETCHING);

      const response = await getAllJobPosts(limit, offset, filters);

      if (response.status === 200) {
        const newJobs = response.data.data;
        setJobs(newJobs);
        setJobFetchingStatus(GET_STATUS.FETCHED);
      } else {
        setJobFetchingError("Error while fetching jobs.");
        setJobFetchingStatus(GET_STATUS.FAILED);
      }
    } catch (error) {
      setJobFetchingError("Error while fetching jobs.");
      setJobFetchingStatus(GET_STATUS.FAILED);
    }
  };

  const handleApplyFilters = () => {
    const filters = {
      job_type: jobTypes.join(","), // Join the selected job types into a string
      sort_by: sortOrder, // Add sort order to the filter
    };
    setOffset(0); // Reset pagination
    setJobs([]); // Clear existing jobs
    fetchJobPosts(filters); // Fetch jobs with the applied filters
  };

  const handleJobTypeChange = (jobType) => {
    setJobTypes(
      (prev) =>
        prev.includes(jobType)
          ? prev.filter((type) => type !== jobType) // Remove the jobType if already selected
          : [...prev, jobType] // Add the jobType if not selected
    );
  };

  const handleSortChange = (sortOption) => {
    setSortOrder(sortOption);
  };

  const handleOpenPostJobDialog = () => {
    setOpenPostJobDialog(true);
  };

  const handleClosePostJobDialog = () => {
    setOpenPostJobDialog(false);
  };

  const handleOpenSnackbar = (message, severity = "success") => {
    setSnackbar({
      open: true,
      message,
      severity,
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  useEffect(() => {
    fetchJobPosts();
  }, [jobPostActionId]);

  return (
    <Box sx={{ overFlowX: "hidden" }}>
      {isMobile ? (
        <SearchBoxContainer maxWidth="xl" sx={{ pb: 1.5 }}>
          <SearchInput placeholder="Search people by job title or industry" />
          <AddButton onClick={handleOpenPostJobDialog} isFullWidth={true}>
            Post Job
          </AddButton>
        </SearchBoxContainer>
      ) : (
        <FlexContainer>
          <FlexItem>
            <SearchInput placeholder="Search people by job title or industry" />
          </FlexItem>

          <AddButton
            onClick={handleOpenPostJobDialog}
            sx={{
              width: "50%",
            }}
          >
            Post Job
          </AddButton>
        </FlexContainer>
      )}

      <Container maxWidth="xl" sx={{ py: 3 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={8} sx={{ order: { xs: 2, sm: 2, md: 1 } }}>
            {jobFetchingStatus === GET_STATUS.FETCHING ? (
              <JobCardSkeleton />
            ) : jobFetchingStatus === GET_STATUS.FAILED ? (
              <Box>
                <Alert severity="error">
                  Error fetching jobs. Please try again later.
                </Alert>
              </Box>
            ) : jobs.length === 0 ? (
              <Box>
                <Alert severity="info">No jobs found.</Alert>
              </Box>
            ) : (
              <Box>
                <SectionTitle>{jobs.length} jobs found</SectionTitle>

                {jobs.map((job) => (
                  <JobsCard
                    key={job.job_post_id}
                    job={job}
                    onOpenSnackbar={handleOpenSnackbar}
                  />
                ))}
              </Box>
            )}
          </Grid>

          <Grid item xs={12} sm={4} sx={{ order: { xs: 1, sm: 1, md: 2 } }}>
            <FilterTitleContainer>
              <SectionTitle>All filters</SectionTitle>
              {/* <SectionSubtitle isApplied={true}>Applied(2)</SectionSubtitle> */}
              <FormBtn sx={{ width: "auto" }} onClick={handleApplyFilters}>
                Apply filters
              </FormBtn>
            </FilterTitleContainer>
            <Box mt={2}>
              <FilterSectionTitle>Sort by</FilterSectionTitle>
              <StyledFormGroup>
                <StyledFormControlLabel
                  label="Oldest first"
                  checked={sortOrder === "ASC"}
                  onChange={() => handleSortChange("ASC")}
                />
                <StyledFormControlLabel
                  label="Newest first"
                  checked={sortOrder === "DESC"}
                  onChange={() => handleSortChange("DESC")}
                />
              </StyledFormGroup>
            </Box>
            <StyledHr />

            <Box mt={2}>
              <FilterSectionTitle>Employment Type</FilterSectionTitle>
              <StyledFormGroup>
                <StyledFormControlLabel
                  label="Full time"
                  checked={jobTypes.includes("FULL_TIME")}
                  onChange={() => handleJobTypeChange("FULL_TIME")}
                />
                <StyledFormControlLabel
                  label="Part time"
                  checked={jobTypes.includes("PART_TIME")}
                  onChange={() => handleJobTypeChange("PART_TIME")}
                />
                <StyledFormControlLabel
                  label="Thesis"
                  checked={jobTypes.includes("THESIS")}
                  onChange={() => handleJobTypeChange("THESIS")}
                />
              </StyledFormGroup>
            </Box>

            <StyledHr />
          </Grid>
        </Grid>
      </Container>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      <JobPostDialog
        openPostJobDialog={openPostJobDialog}
        handleClose={handleClosePostJobDialog}
        onOpenSnackbar={handleOpenSnackbar}
      />
    </Box>
  );
};

const SectionTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  font-size: 24px;
  font-weight: 500;
`;

const FilterTitleContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const FilterSectionTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  font-size: 16px;
  font-weight: 500;
  word-wrap: break-word;
  margin-bottom: 8px;
`;

const StyledLabel = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  font-size: 14px;
  font-family: "Inter";
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.14px;
  word-wrap: break-word;
  padding-left: 8px;
`;

const StyledCheckbox = styled(Checkbox)`
  color: ${({ theme }) => theme.palette.text.neutral[700]};
`;

const StyledFormGroup = styled(FormGroup)`
  .MuiCheckbox-root {
    padding: 0px 0px 0px 8px;
  }
`;

const StyledHr = styled.hr`
  border: none;
  height: 0.5px;
  background-color: ${({ theme }) => theme.palette.border.neutral[400]};
  margin: 16px 0; // Adjust the margin as needed
`;

const SearchBoxContainer = styled(Container)`
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.neutral[200]};
  border-top: 1px solid ${({ theme }) => theme.palette.border.neutral[200]};
`;

const FlexContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.neutral[200]};
  border-top: 1px solid ${({ theme }) => theme.palette.border.neutral[200]};

  ${({ theme }) => theme.breakpoints.down("sm")} {
    flex-direction: column; // Stack items vertically on small screens
    padding: 0 12px; // Adjust padding for small screens
    width: 100%; // Ensure full width
  }
`;

const FlexItem = styled(Box)`
  flex: 1;
  width: 100%;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin-bottom: 16px; // Add space between FlexItem and buttons on small screens
  }
`;

import { Box, Typography } from "@mui/material";
import React from "react";
import styled from "@emotion/styled";

export const TermsAndPolicyFooter = () => {
  return (
    <Box
      sx={{
        mt: { xs: 2, md: 2 },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <StyledFooterTitle component={"div"}>
        You acknowledge that you read, and agree to our
      </StyledFooterTitle>
      <Typography component={"div"}>
        <StyledFooterLink component={"span"}>Terms of Service</StyledFooterLink>
        <StyledFooterTitle component={"span"}> and </StyledFooterTitle>
        <StyledFooterLink component={"span"}>Privacy Policy.</StyledFooterLink>
      </Typography>
    </Box>
  );
};

const StyledFooterTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[600]};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;

  word-wrap: break-word;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    font-size: 12px;
  }
`;

const StyledFooterLink = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[600]};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  text-decoration-line: underline;
  word-wrap: break-word;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    font-size: 12px;
  }
`;

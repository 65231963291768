import styled from "@emotion/styled";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Slider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { createJobPost } from "../../api/Job/job";
import {
  JOB_POST_STATUS,
  JOB_TYPE_OPTIONS,
  POST_STATUS,
} from "../../constants/constants";
import {
  resetJobPostState,
  setJobPostData,
} from "../../redux/Slices/jobPostSlice";
import FormBtn from "../Common/FormBtn";
import FormInput from "../Common/FormInput";
import { Label } from "../Common/Label";
import SelectInput from "../Common/SelectInput";
import { setActionData } from "../../redux/Slices/eventListnerSlice";
import dayjs from "dayjs";
import { RequiredMark } from "../Common/RequiredMark";
import StyledTextArea from "../Common/StyledTextArea";
import StyledHelperText from "../Common/StyledHelperText";
import { useTheme } from "@emotion/react";

export const JobPostDialog = ({
  openPostJobDialog,
  handleClose,
  onOpenSnackbar,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const formData = useSelector((state) => state.jobPost);
  const [jobPostingError, setJobPostingError] = useState("");
  const [jobPostingStatus, setJobPostingStatus] = useState();
  const userId = useSelector((state) => state.user.userId);
  const salaryRange = formData.salary_range;
  const eventListenerData = useSelector((state) => state.eventListener);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    control,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: formData,
  });

  useEffect(() => {
    trigger("salary_range");
  }, [formData.salary_range, trigger]);

  const handleJobPostSuccess = () => {
    setJobPostingStatus(POST_STATUS.SAVED);
    dispatch(resetJobPostState());
    dispatch(
      setActionData({
        ...eventListenerData,
        jobPostActionId: `add_job_${dayjs()}`,
      })
    );
    onOpenSnackbar("Job created successfully!", "success");
    handleClose();
  };

  const handleJobPostFailure = () => {
    setJobPostingError("Error while creating job.");
    setJobPostingStatus(POST_STATUS.FAILED);
    onOpenSnackbar("Error while creating job.", "error");
    handleClose();
  };

  const onSubmit = async () => {
    try {
      setJobPostingError("");
      const jobPostData = { ...formData };
      jobPostData.job_post_status = JOB_POST_STATUS.IN_REVIEW;
      jobPostData.created_by = userId;
      jobPostData.min_salary = formData.salary_range[0];
      jobPostData.max_salary = formData.salary_range[1];
      delete jobPostData.salary_range;
      setJobPostingStatus(POST_STATUS.POSTING);
      const response = await createJobPost(jobPostData);
      if (response.status === 201) {
        handleJobPostSuccess();
      } else {
        handleJobPostFailure();
      }
    } catch (error) {
      handleJobPostFailure();
    }
  };

  const handleInputChange = async (name, value) => {
    dispatch(
      setJobPostData({
        ...formData,
        [name]: value,
      })
    );
    await trigger(name);
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={openPostJobDialog}
      onClose={handleClose}
      aria-labelledby="Post job"
      aria-describedby="Please fill in the details below to post a new job"
    >
      <CardContainer
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <StyledDialogTitle>Post job</StyledDialogTitle>
            <CloseOutlinedIcon
              sx={{ color: "#333333", padding: "8px", cursor: "pointer" }}
              onClick={handleClose}
            />
          </Box>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={isSmallScreen ? 0 : 2}>
            <Grid item xs={12} sm={12} md={6}>
              <Controller
                name="company_name"
                control={control}
                rules={{
                  required: "Company name is required.",
                  minLength: {
                    value: 3,
                    message: "Company name must be at least 3 characters long",
                  },
                }}
                render={({ field }) => (
                  <FormInput
                    label="Company name"
                    id="company_name"
                    type="text"
                    value={formData.company_name}
                    placeholder="Company name"
                    required
                    {...field}
                    error={!!errors.company_name}
                    helperText={
                      errors.company_name ? errors.company_name.message : ""
                    }
                    onChange={(e) => {
                      field.onChange(e);
                      handleInputChange("company_name", e.target.value);
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Controller
                name="position"
                control={control}
                rules={{
                  required: "Position is required.",
                  minLength: {
                    value: 3,
                    message: "Position must be at least 3 characters long",
                  },
                }}
                render={({ field }) => (
                  <FormInput
                    label="Position"
                    id="position"
                    type="text"
                    value={formData.position}
                    placeholder="developer"
                    required
                    {...field}
                    error={!!errors.position}
                    helperText={errors.position ? errors.position.message : ""}
                    onChange={(e) => {
                      field.onChange(e);
                      handleInputChange("position", e.target.value);
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={isSmallScreen ? 0 : 2}>
            <Grid item xs={12} sm={12} md={6}>
              <Controller
                name="job_type"
                control={control}
                rules={{
                  required: "Job type is required.",
                }}
                render={({ field }) => (
                  <SelectInput
                    label="Job type"
                    id="job_type"
                    name="job_type"
                    options={JOB_TYPE_OPTIONS}
                    fullWidth={false}
                    required
                    error={!!errors.job_type}
                    helperText={errors.job_type ? errors.job_type.message : ""}
                    value={formData.job_type}
                    onChange={(e) => {
                      field.onChange(e);
                      handleInputChange("job_type", e.target.value);
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Box mt={1}>
                <Label>Salary range</Label>
                <Controller
                  name="salary_range"
                  control={control}
                  render={({ field }) => (
                    <Slider
                      value={field.value || formData.salary_range}
                      onChange={(event, newValue) => {
                        field.onChange(newValue);
                        handleInputChange("salary_range", newValue);
                      }}
                      valueLabelDisplay="off"
                      min={50000}
                      max={500000}
                      step={1000}
                      getAriaValueText={(value) => `${value}$`}
                    />
                  )}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Label>€{formData.salary_range[0]}</Label>
                  <Label>€{formData.salary_range[1]}</Label>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={isSmallScreen ? 0 : 2}>
            <Grid item xs={12} sm={12} md={6}>
              <Controller
                name="region"
                control={control}
                rules={{
                  required: "Region is required.",
                  minLength: {
                    value: 2,
                    message: "Region must be at least 2 characters long",
                  },
                }}
                render={({ field }) => (
                  <FormInput
                    label="Region"
                    id="region"
                    name="region"
                    type="text"
                    placeholder="EU, USA"
                    required
                    fullWidth
                    {...field}
                    error={!!errors.region}
                    helperText={errors.region ? errors.region.message : ""}
                    onChange={(e) => {
                      field.onChange(e);
                      handleInputChange("region", e.target.value);
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Controller
                name="job_link"
                control={control}
                rules={{
                  required: "Job link is required.",
                  pattern: {
                    value: /^(ftp|http|https):\/\/[^ "]+$/,
                    message: "Please enter a valid URL.",
                  },
                }}
                render={({ field }) => (
                  <FormInput
                    label="Job link"
                    id="job-link"
                    name="job-link"
                    placeholder="Enter job link"
                    type="text"
                    required
                    fullWidth
                    {...field}
                    error={!!errors.job_link}
                    helperText={errors.job_link ? errors.job_link.message : ""}
                    onChange={(e) => {
                      field.onChange(e);
                      handleInputChange("job_link", e.target.value);
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              marginTop: 1,
            }}
          >
            <Controller
              name="description"
              control={control}
              rules={{
                required: "Description is required.",
                minLength: {
                  value: 50,
                  message: "Description must be between 50-500 characters long",
                },
                maxLength: {
                  value: 500,
                  message: "Description must be between 50-500 characters long",
                },
              }}
              render={({ field }) => (
                <>
                  <Label error={errors.description}>
                    <RequiredMark />
                    Job description
                  </Label>
                  <StyledTextArea
                    id="description"
                    value={formData.description}
                    name="description"
                    rows="5"
                    placeholder="Short job description"
                    required
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                      handleInputChange("description", e.target.value);
                    }}
                  />
                  <StyledHelperText error={errors.description}>
                    {errors?.description?.message}
                  </StyledHelperText>
                </>
              )}
            />
          </Box>
        </DialogContent>
        <DialogActionsBox>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}></Grid>
            <Grid item xs={12} sm={12} md={6}>
              <FormBtn
                type="submit"
                disabled={jobPostingStatus === POST_STATUS.POSTING}
                loading={jobPostingStatus === POST_STATUS.POSTING}
              >
                Post job
              </FormBtn>
            </Grid>
          </Grid>
        </DialogActionsBox>
      </CardContainer>
    </Dialog>
  );
};

const StyledDialogTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  font-size: 20px;
  font-weight: 500;
`;

const CardContainer = styled(Box)`
  padding: 16px;
  background: white;
  border-radius: 8px;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding: 0px;
  }
`;

const DialogActionsBox = styled(DialogActions)`
  margin: 0px 16px;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin: 0px 16px 16px 16px;
  }
`;

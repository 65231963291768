import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";
import { Link } from "react-router-dom";

const TextLink = ({ title, sx, to }) => {
  return (
    <StyledLink to={to} sx={sx}>
      {title}
    </StyledLink>
  );
};

export default TextLink;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.palette.primary[600]};
  font-size: 12px;
  font-weight: 500;
  text-decoration-line: underline;
  cursor: pointer;

  ${(props) =>
    props.sx &&
    css`
      ${props.sx}
    `}
`;

import styled from "@emotion/styled";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {
  Avatar,
  Box,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  DialogActions,
  Grid,
} from "@mui/material";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { createPost } from "../../api/Post/post";
import FormBtn from "../Common/FormBtn";
import FormInput from "../Common/FormInput";
import { HP_POST_STATUS, POST_STATUS } from "../../constants/constants";
import { resetPostState, setPostData } from "../../redux/Slices/postSlice";
import { Tag } from "../Common/Tag";
import { RequiredMark } from "../Common/RequiredMark";
import { Label } from "../Common/Label";
import StyledHelperText from "../Common/StyledHelperText";
import { setActionData } from "../../redux/Slices/eventListnerSlice";
import dayjs from "dayjs";
import StyledTextArea from "../Common/StyledTextArea";

export const PostDialog = ({ openPostDialog, handleClose, onOpenSnackbar }) => {
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.post);
  const [postingError, setPostingError] = useState("");
  const [postingStatus, setPostingStatus] = useState();
  const { userId, email, firstName, lastName } = useSelector(
    (state) => state.user
  );
  const eventListenerData = useSelector((state) => state.eventListener);
  const {
    control,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: formData,
  });

  const handlePostSuccess = () => {
    setPostingStatus(POST_STATUS.SAVED);
    dispatch(resetPostState());
    dispatch(
      setActionData({
        ...eventListenerData,
        postActionId: `post_added_${dayjs()}`,
      })
    );
    onOpenSnackbar("Post created successfully!", "success");
    handleClose();
  };

  const handlePostFailure = () => {
    setPostingError("Error while creating post.");
    setPostingStatus(POST_STATUS.FAILED);
    onOpenSnackbar("Error while creating post.", "error");
    handleClose();
  };

  const onSubmit = async () => {
    try {
      setPostingError("");
      const postData = { ...formData };
      postData.post_status = HP_POST_STATUS.IN_REVIEW;
      postData.created_by = userId;
      setPostingStatus(POST_STATUS.POSTING);
      const response = await createPost(postData);
      if (response.status === 201) {
        handlePostSuccess();
      } else {
        handlePostFailure();
      }
    } catch (error) {
      console.log(error.message);
      handlePostFailure();
    }
  };

  const handleInputChange = async (name, value) => {
    dispatch(
      setPostData({
        ...formData,
        [name]: value,
      })
    );
    await trigger(name);
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={openPostDialog}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <CardContainer
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <DialogTitle>
          <Header>
            <UserInfo>
              <Avatar src="/assets/images/avatar-1.png" />
              <UserDetails>
                <UserName>{`${firstName} ${lastName}`}</UserName>
                <UserEmail>{email}</UserEmail>
              </UserDetails>
            </UserInfo>
            <CloseOutlinedIconStyled onClick={handleClose} />
          </Header>
        </DialogTitle>
        <DialogContent>
          {/* <TagContainer>
            <Tag title="Job opening" />
            <Tag title="Help" />
            <AddTagContainer>
              <AddOutlinedIconStyled />
              <TagText>Tag</TagText>
            </AddTagContainer>
          </TagContainer> */}
          <Box mt={1}>
            <Controller
              name="title"
              control={control}
              rules={{
                required: "Post title is required.",
                minLength: {
                  value: 3,
                  message: "Post title must be at between 3-75 characters long",
                },
                maxLength: {
                  value: 70,
                  message: "Post title must be at between 3-75 characters long",
                },
              }}
              render={({ field }) => (
                <FormInput
                  label="Post title"
                  id="title"
                  type="text"
                  value={formData.title}
                  placeholder="Application of Gen-AI"
                  required
                  {...field}
                  error={!!errors.title}
                  helperText={errors.title ? errors.title.message : ""}
                  onChange={(e) => {
                    field.onChange(e);
                    handleInputChange("title", e.target.value);
                  }}
                />
              )}
            />
          </Box>

          <Box mt={1}>
            <Controller
              name="description"
              control={control}
              rules={{
                required: "Description is required.",
                minLength: {
                  value: 50,
                  message: "Description must be between 50-500 characters long",
                },
                maxLength: {
                  value: 500,
                  message: "Description must be between 50-500 characters long",
                },
              }}
              render={({ field }) => (
                <>
                  <Label error={errors.description}>
                    <RequiredMark />
                    Post description
                  </Label>
                  <StyledTextArea
                    id="description"
                    value={formData.description}
                    name="description"
                    rows="10"
                    placeholder="Short post description"
                    required
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                      handleInputChange("description", e.target.value);
                    }}
                  />
                  <StyledHelperText error={errors.description}>
                    {errors?.description?.message}
                  </StyledHelperText>
                </>
              )}
            />
          </Box>
        </DialogContent>
        <DialogActionsBox>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}></Grid>
            <Grid item xs={12} sm={12} md={6}>
              <FormBtn
                type="submit"
                disabled={postingStatus === POST_STATUS.POSTING}
                loading={postingStatus === POST_STATUS.POSTING}
                sx={{ mb: 2 }}
              >
                Post
              </FormBtn>
            </Grid>
          </Grid>
        </DialogActionsBox>
      </CardContainer>
    </Dialog>
  );
};

// Styled Components using Emotion
const CardContainer = styled(Box)`
  background: white;
  border-radius: 8px;
`;

const Header = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const UserInfo = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const UserDetails = styled(Box)`
  margin-left: 8px;
`;

const UserName = styled(Typography)`
  font-size: 20px;
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  font-weight: 600;
  word-wrap: break-word;
  text-transform: capitalize;
`;

const UserEmail = styled(Typography)`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  font-weight: 400;
  word-wrap: break-word;
  text-transform: capitalize;
`;

const CloseOutlinedIconStyled = styled(CloseOutlinedIcon)`
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  padding: 8px;
  cursor: pointer;
`;

const TextArea = styled.textarea`
  width: 100%;
  box-sizing: border-box;
  background: #fff;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.palette.text.secondary};
  padding: 8px;
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 14px;
  font-weight: 400;
  font-family: "Inter";
`;

const DialogActionsBox = styled(DialogActions)`
  margin: 0px 16px;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin: 0px 16px 16px 16px;
  }
`;

import styled from "@emotion/styled";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { Alert, Box, Button, Slider, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import FormBtn from "../../component/Common/FormBtn";
import FormInput from "../../component/Common/FormInput";
import { RequiredMark } from "../../component/Common/RequiredMark";
import SelectInput from "../../component/Common/SelectInput";
import SlideUp from "../../component/Common/SlideUp";
import { TermsAndPolicyFooter } from "../../component/Common/TermsAndPolicyFooter";
import {
  EMPLOYMENT_STATUS,
  EMPLOYMENT_STATUS_OPTIONS,
  EMPLOYMENT_TYPE_OPTIONS,
  POST_STATUS,
  USER_PROFILE_STATUS,
} from "../../constants/constants";
import { useDispatch, useSelector } from "react-redux";
import { setOnBoardingData } from "../../redux/Slices/onBoardingSlice";
import { onboardUser } from "../../api/User/user";
import { StyledFormApiErrorText } from "../../component/Common/StyledFormApiErrorText";
import { Label } from "../../component/Common/Label";

const OBScreen2 = ({}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.onboarding);
  const userId = useSelector((state) => state.user.userId);
  const [slideUpOpen, setSlideUpOpen] = useState(false);
  const [slideUpContent, setSlideUpContent] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [onboardingError, setOnnboardingError] = useState("");
  const [onboardingStatus, setOnboardingStatus] = useState();
  const employmentStatus = formData.employment_status;

  const {
    control,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: formData,
  });

  const handleSlideUpOpen = (content) => {
    setSlideUpContent(content);
    setSlideUpOpen(true);
  };

  const handleSlideUpClose = () => {
    setSlideUpOpen(false);
    setSlideUpContent("");
  };
  const handleEdit = (content) => {
    handleSlideUpOpen(content);
  };

  const onSubmit = async (data) => {
    try {
      setOnboardingStatus(POST_STATUS.INITIAL);
      setOnnboardingError("");
      const userData = { ...formData };
      userData.country = formData.country.toString();
      userData.city = formData.city.toString();
      userData.state = formData.state.toString();
      userData.min_salary = formData.salary_range[0];
      userData.max_salary = formData.salary_range[1];
      userData.user_profile_status = USER_PROFILE_STATUS.IN_REVIEW;
      delete userData.salary_range;
      setOnboardingStatus(POST_STATUS.POSTING);
      const response = await onboardUser(userData, userId);
      if (response.status === 200) {
        setOnboardingStatus(POST_STATUS.SAVED);
        dispatch(
          setOnBoardingData({
            ...formData,
            active_onboarding_step: 3,
          })
        );
      } else if (response.status === 404) {
        setOnnboardingError("User does not exists.");
        setOnboardingStatus(POST_STATUS.FAILED);
      } else {
        setOnnboardingError("Unexpected error occurred.");
        setOnboardingStatus(POST_STATUS.FAILED);
      }
    } catch (error) {
      console.log(error.message);
      setOnnboardingError("Error while onboarding user.");
      setOnboardingStatus(POST_STATUS.FAILED);
    }
  };

  const handleInputChange = async (name, value) => {
    dispatch(
      setOnBoardingData({
        ...formData,
        [name]: value,
      })
    );
    await trigger(name);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <StyledBox component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="employment_status"
        control={control}
        rules={{
          required: "Employment status is required.",
        }}
        render={({ field }) => (
          <SelectInput
            label="Employment Status"
            id="employment_status"
            name="employment_status"
            options={EMPLOYMENT_STATUS_OPTIONS}
            fullWidth={false}
            required
            error={!!errors.employment_status}
            helperText={
              errors.employment_status ? errors.employment_status.message : ""
            }
            value={formData.employment_status}
            onChange={(e) => {
              field.onChange(e);
              handleInputChange("employment_status", e.target.value);
            }}
          />
        )}
      />

      {(employmentStatus === EMPLOYMENT_STATUS.CURRENTLY_EMPLOYED ||
        employmentStatus === EMPLOYMENT_STATUS.EMPLOYED_AND_SEEKING_JOB) && (
        <Controller
          name="current_job_title"
          control={control}
          rules={{
            required: "Current job title is required.",
            minLength: {
              value: 3,
              message: "It must be at least 3 characters long",
            },
          }}
          render={({ field }) => (
            <FormInput
              label=" Current job title"
              id="current_job_title"
              value={formData.current_job_title}
              type="text"
              placeholder="Developer, Manager, Analyst, Consultant"
              required
              {...field}
              error={!!errors.current_job_title}
              helperText={
                errors.current_job_title ? errors.current_job_title.message : ""
              }
              onChange={(e) => {
                field.onChange(e);
                handleInputChange("current_job_title", e.target.value);
              }}
            />
          )}
        />
      )}

      {(employmentStatus === EMPLOYMENT_STATUS.CURRENTLY_EMPLOYED ||
        employmentStatus === EMPLOYMENT_STATUS.EMPLOYED_AND_SEEKING_JOB) && (
        <Controller
          name="current_company"
          control={control}
          rules={{
            required: "Current company is required.",
            minLength: {
              value: 3,
              message: "It must be at least 3 characters long",
            },
          }}
          render={({ field }) => (
            <FormInput
              label=" Current Company"
              id="current_company"
              value={formData.current_company}
              type="text"
              placeholder="SAP, Siemens, BMW, Self-employed"
              required
              {...field}
              error={!!errors.current_company}
              helperText={
                errors.current_company ? errors.current_company.message : ""
              }
              onChange={(e) => {
                field.onChange(e);
                handleInputChange("current_company", e.target.value);
              }}
            />
          )}
        />
      )}

      {(employmentStatus === EMPLOYMENT_STATUS.ACTIVELY_LOOKING_FOR_JOB ||
        employmentStatus === EMPLOYMENT_STATUS.EMPLOYED_AND_SEEKING_JOB) && (
        <Controller
          name="preferred_position"
          control={control}
          rules={{
            required: "Preferred position is required.",
            minLength: {
              value: 3,
              message: "It must be at least 3 characters long",
            },
          }}
          render={({ field }) => (
            <FormInput
              label="Preferred position"
              id="preferred_position"
              value={formData.preferred_position}
              type="text"
              placeholder="Developer, Manager, Analyst, Consultant"
              required
              {...field}
              error={!!errors.preferred_position}
              helperText={
                errors.preferred_position
                  ? errors.preferred_position.message
                  : ""
              }
              onChange={(e) => {
                field.onChange(e);
                handleInputChange("preferred_position", e.target.value);
              }}
            />
          )}
        />
      )}
      <Controller
        name="employment_type"
        control={control}
        rules={{
          required: "Employment type is required.",
        }}
        render={({ field }) => (
          <SelectInput
            label="Employment type"
            id="employment_type"
            name="employment_type"
            options={EMPLOYMENT_TYPE_OPTIONS}
            fullWidth={false}
            required
            error={!!errors.employment_type}
            helperText={
              errors.employment_type ? errors.employment_type.message : ""
            }
            value={formData.employment_type}
            onChange={(e) => {
              field.onChange(e);
              handleInputChange("employment_type", e.target.value);
            }}
          />
        )}
      />

      {(employmentStatus === EMPLOYMENT_STATUS.CURRENTLY_EMPLOYED ||
        employmentStatus === EMPLOYMENT_STATUS.EMPLOYED_AND_SEEKING_JOB) && (
        // <Controller
        //   name="salary_range"
        //   control={control}
        //   rules={{
        //     required: "Salary range is required.",
        //   }}
        //   render={({ field }) => (
        //     <SelectInput
        //       label="Salary range"
        //       id="salary_range"
        //       name="salary_range"
        //       options={options}
        //       fullWidth={false}
        //       required
        //       error={!!errors.salary_range}
        //       helperText={
        //         errors.salary_range ? errors.salary_range.message : ""
        //       }
        //       value={formData.salary_range}
        //       onChange={(e) => {
        //         field.onChange(e);
        //         handleInputChange("salary_range", e.target.value);
        //       }}
        //     />
        //   )}
        // />
        <Box mt={1}>
          <Label>Salary range</Label>
          <Controller
            name="salary_range"
            control={control}
            render={({ field }) => (
              <Slider
                value={field.value || formData.salary_range}
                onChange={(event, newValue) => {
                  field.onChange(newValue);
                  handleInputChange("salary_range", newValue);
                }}
                valueLabelDisplay="off"
                min={10000}
                max={200000}
                step={1000}
                getAriaValueText={(value) => `${value}$`}
              />
            )}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Label>€{formData.salary_range[0]}</Label>
            <Label>€{formData.salary_range[1]}</Label>
          </Box>
        </Box>
      )}

      <Box sx={{ mt: 0.7 }}>
        <StyledLabel>
          {/* <RequiredMark /> */}
          Domain
        </StyledLabel>
        <Box>
          {formData.domains.length > 0 ? (
            <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
              {formData.domains.map((domain) => (
                <SelectedButton
                  key={domain}
                  variant="contained"
                  onClick={() => handleEdit("domain")}
                >
                  {domain} <CloseIcon sx={{ ml: 1 }} />
                </SelectedButton>
              ))}
            </Box>
          ) : (
            <AddButton
              variant="contained"
              onClick={() => handleSlideUpOpen("domain")}
              startIcon={<AddOutlinedIcon />}
            >
              Add domain
            </AddButton>
          )}
        </Box>
      </Box>

      <Box sx={{ mt: 2 }}>
        <StyledLabel>Past companies</StyledLabel>
        <Box>
          {formData.past_companies.length > 0 ? (
            <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
              {formData.past_companies.map((company) => (
                <SelectedButton
                  key={company}
                  variant="contained"
                  onClick={() => handleEdit("companies")}
                >
                  {company} <CloseIcon sx={{ ml: 1 }} />
                </SelectedButton>
              ))}
            </Box>
          ) : (
            <AddButton
              variant="contained"
              onClick={() => handleSlideUpOpen("companies")}
              startIcon={<AddOutlinedIcon />}
            >
              Add company
            </AddButton>
          )}
        </Box>
      </Box>
      {onboardingError && (
        <Box sx={{ mt: 2 }}>
          <Alert severity="error">{onboardingError}</Alert>
        </Box>
      )}
      <StyledBtnContainer>
        <FormBtn
          type="submit"
          disabled={onboardingStatus === POST_STATUS.POSTING}
          loading={onboardingStatus === POST_STATUS.POSTING}
        >
          {" "}
          Continue
        </FormBtn>
      </StyledBtnContainer>
      <TermsAndPolicyFooter />
      <SlideUp
        open={slideUpOpen}
        onClose={handleSlideUpClose}
        content={slideUpContent}
      />
    </StyledBox>
  );
};

const StyledBtnContainer = styled(Box)`
  margin-top: 24px;
`;

const StyledBox = styled(Box)`
  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin-top: 24px;
  }
`;

const StyledLabel = styled("span")`
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  font-size: 14px;
  font-weight: 400;
  wordwrap: break-word;
  letter-spacing: 0.14px;
`;

const AddButton = styled(Button)`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.common.purple};
  border: 1px solid ${({ theme }) => theme.palette.common.purple};
  background: #fff;
  padding: 5px 16px;
  border-radius: 4px;
  cursor: pointer;
  text-transform: none;
  transition: all 0.3s ease;

  &:hover {
    background: white;
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    width: auto;
  }
`;

const SelectedButton = styled(Button)`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: white;
  padding: 4px 8px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  text-transform: none;
  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.dark};
  }
`;
export default OBScreen2;

import styled from "@emotion/styled";
import {
  Alert,
  Container,
  Grid,
  Snackbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getAllEvents } from "../../api/Event/event";
import { EventCard } from "../../component/Events/EventCard";
import { EventCardSkeleton } from "../../component/Events/EventCardSkeleton";
import { GET_STATUS } from "../../constants/constants";
import { CreateEventDialog } from "../../component/Events/CreateEventDialog";
import SearchInput from "../../component/Layout/SearchInput";
import AddButton from "../../component/Common/AddButton";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ mt: 2 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const Events = () => {
  const [eventFetchingError, setEventFetchingError] = useState("");
  const [eventFetchingStatus, setEventFetchingStatus] = useState();
  const [openCreateEventDialog, setOpenCreateEventDialog] = useState(false);
  const [events, setEvents] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const userId = useSelector((state) => state.user.userId);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const fetchEventsPosts = async (tabIndex) => {
    try {
      setEventFetchingError("");
      setEventFetchingStatus(GET_STATUS.FETCHING);

      let eventType;
      switch (tabIndex) {
        case 0:
          eventType = "active";
          break;
        case 1:
          eventType = "upcoming";
          break;
        case 2:
          eventType = "past";
          break;
        case 3:
          eventType = "saved";
          break;
        default:
          eventType = "all"; // Fallback if no tab is selected
      }

      const response = await getAllEvents(eventType, userId);
      if (response.status === 200) {
        const events = response.data.data;
        setEvents(events);
        setEventFetchingStatus(GET_STATUS.FETCHED);
      } else {
        setEventFetchingError("Error while fetching events.");
        setEventFetchingStatus(GET_STATUS.FAILED);
      }
    } catch (error) {
      setEventFetchingError("Error while fetching events.");
      setEventFetchingStatus(GET_STATUS.FAILED);
    }
  };

  const handleOpenCreateEventDialog = () => {
    setOpenCreateEventDialog(true);
  };

  const handleCloseCreateEventDialog = () => {
    setOpenCreateEventDialog(false);
  };

  const handleOpenSnackbar = (message, severity = "success") => {
    setSnackbar({
      open: true,
      message,
      severity,
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  useEffect(() => {
    fetchEventsPosts(selectedTab);
  }, [selectedTab]);

  return (
    <Box sx={{ overFlowX: "hidden" }}>
      {isMobile ? (
        <SearchBoxContainer maxWidth="xl" sx={{ pb: 1.5 }}>
          <SearchInput placeholder="Search people by job title or industry" />
          <AddButton isFullWidth={true} onClick={handleOpenCreateEventDialog}>
            Create Event
          </AddButton>
        </SearchBoxContainer>
      ) : (
        <FlexContainer>
          <FlexItem>
            <SearchInput placeholder="Search people by job title or industry" />
          </FlexItem>

          <AddButton onClick={handleOpenCreateEventDialog}>
            Create Event
          </AddButton>
        </FlexContainer>
      )}

      <Container maxWidth="xl" sx={{ py: 3, pt: 2 }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            variant={isMobile ? "scrollable" : "standard"}
            scrollButtons={isMobile ? "auto" : false}
            allowScrollButtonsMobile
          >
            <StyledTab label="Active events" {...a11yProps(0)} />
            <StyledTab label="Upcoming events" {...a11yProps(1)} />
            <StyledTab label="Past events" {...a11yProps(2)} />
            <StyledTab label="Saved events" {...a11yProps(3)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={selectedTab} index={0}>
          {eventFetchingStatus === GET_STATUS.FETCHING ? (
            <EventCardSkeleton /> // Show loading skeleton while fetching data
          ) : eventFetchingStatus === GET_STATUS.FAILED ? (
            <Box>
              <Alert severity="error">
                Error fetching events. Please try again later.
              </Alert>
            </Box>
          ) : events.length === 0 ? (
            <Box>
              <Alert severity="info">No active events found.</Alert>
            </Box>
          ) : (
            <Grid container spacing={2}>
              {events.map((event, index) => (
                <Grid item xs={12} sm={12} md={4} key={index}>
                  <EventCard image="/assets/images/events.png" event={event} />
                </Grid>
              ))}
            </Grid>
          )}
        </CustomTabPanel>
        <CustomTabPanel value={selectedTab} index={1}>
          {eventFetchingStatus === GET_STATUS.FETCHING ? (
            <EventCardSkeleton /> // Show loading skeleton while fetching data
          ) : eventFetchingStatus === GET_STATUS.FAILED ? (
            <Box>
              <Alert severity="error">
                Error fetching events. Please try again later.
              </Alert>
            </Box>
          ) : events.length === 0 ? (
            <Box>
              <Alert severity="info">No upcoming events found.</Alert>
            </Box>
          ) : (
            <Grid container spacing={2}>
              {events.map((event, index) => (
                <Grid item xs={12} sm={12} md={4} key={index}>
                  <EventCard image="/assets/images/events.png" event={event} />
                </Grid>
              ))}
            </Grid>
          )}
        </CustomTabPanel>
        <CustomTabPanel value={selectedTab} index={2}>
          {eventFetchingStatus === GET_STATUS.FETCHING ? (
            <EventCardSkeleton /> // Show loading skeleton while fetching data
          ) : eventFetchingStatus === GET_STATUS.FAILED ? (
            <Box>
              <Alert severity="error">
                Error fetching events. Please try again later.
              </Alert>
            </Box>
          ) : events.length === 0 ? (
            <Box>
              <Alert severity="info">No past events found.</Alert>
            </Box>
          ) : (
            <Grid container spacing={2}>
              {events.map((event, index) => (
                <Grid item xs={12} sm={12} md={4} key={index}>
                  <EventCard image="/assets/images/events.png" event={event} />
                </Grid>
              ))}
            </Grid>
          )}
        </CustomTabPanel>
        <CustomTabPanel value={selectedTab} index={3}>
          {eventFetchingStatus === GET_STATUS.FETCHING ? (
            <EventCardSkeleton /> // Show loading skeleton while fetching data
          ) : eventFetchingStatus === GET_STATUS.FAILED ? (
            <Box>
              <Alert severity="error">
                Error fetching events. Please try again later.
              </Alert>
            </Box>
          ) : events.length === 0 ? (
            <Box>
              <Alert severity="info">No saved events found.</Alert>
            </Box>
          ) : (
            <Grid container spacing={2}>
              {events.map((event, index) => (
                <Grid item xs={12} sm={12} md={4} key={index}>
                  <EventCard image="/assets/images/events.png" event={event} />
                </Grid>
              ))}
            </Grid>
          )}
        </CustomTabPanel>
        {/* <CreateEventDialog openCreateEventDialog={true} /> */}
      </Container>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      <CreateEventDialog
        openCreateEventDialog={openCreateEventDialog}
        handleClose={handleCloseCreateEventDialog}
        onOpenSnackbar={handleOpenSnackbar}
      />
    </Box>
  );
};

const StyledTab = styled(Tab)`
  text-transform: none;
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  min-width: 0 !important;
  width: auto !important;
`;

const SearchBoxContainer = styled(Container)`
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.neutral[200]};
  border-top: 1px solid ${({ theme }) => theme.palette.border.neutral[200]};
`;

const FlexContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.neutral[200]};
  border-top: 1px solid ${({ theme }) => theme.palette.border.neutral[200]};

  ${({ theme }) => theme.breakpoints.down("sm")} {
    flex-direction: column; // Stack items vertically on small screens
    padding: 0 12px; // Adjust padding for small screens
    width: 100%; // Ensure full width
  }
`;

const FlexItem = styled(Box)`
  flex: 1;
  width: 100%;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin-bottom: 16px;
  }
`;

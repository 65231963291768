import { CommentClient } from "../config"

export const postComment = async (data) => {
  try
  {
    const response = await CommentClient.post(`create`, data);
    return response;
  } catch (error)
  {
    if (error.response && error.response.status === 500)
    {
      return error.response;
    }
    throw error;
  }
};

export const getAllComments = async (limit, offset, postId) => {
  try
  {
    const response = await CommentClient.get(`all/${postId}`, {
      params: { limit, offset }
    });
    return response;
  } catch (error)
  {
    console.log(error)
    if (error.response && error.response.status === 500)
    {
      return error.response;
    }
    throw error;
  }
}

export const getAllCommentReplies = async (commentId) => {
  try
  {
    const response = await CommentClient.get(`replies/${commentId}`);
    return response;
  } catch (error)
  {
    console.log(error)
    if (error.response && error.response.status === 500)
    {
      return error.response;
    }
    throw error;
  }
}

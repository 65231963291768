import React from "react";
import { Typography } from "@mui/material";
import styled from "@emotion/styled";

const StyledRequiredMark = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.crimson};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`;

export const RequiredMark = () => {
  return <StyledRequiredMark component={"span"}> *</StyledRequiredMark>;
};

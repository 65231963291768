import React, { useState, useEffect } from "react";
import {
  Dialog,
  Slide,
  Box,
  IconButton,
  useMediaQuery,
  useTheme,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import { setOnBoardingData } from "../../redux/Slices/onBoardingSlice";
import AddButton from "./AddButton";
import FormInput from "./FormInput";
import InverseBtn from "./InverseBtn";
import { setUserProfileData } from "../../redux/Slices/userProfileSlice";
const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const SlideUp = ({ open, onClose, content, page = "onboarding" }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedValues, setSelectedValues] = useState([]);
  const [newInput, setNewInput] = useState(""); // State for text input
  const dispatch = useDispatch();

  // Always call useSelector to avoid conditional hooks
  const userProfile = useSelector((state) => state.userProfile);
  const onboarding = useSelector((state) => state.onboarding);

  // Conditional logic comes after the hook calls
  const formData = page === "profile" ? userProfile : onboarding;

  const domainsCheckboxes = [
    { value: "domain 1", label: "domain 1" },
    { value: "domain 2", label: "domain 2" },
    { value: "domain 3", label: "domain 3" },
  ];

  const pastCompaniesCheckboxes = [
    { value: "Company A", label: "Company A" },
    { value: "Company B", label: "Company B" },
    { value: "Company C", label: "Company C" },
  ];

  useEffect(() => {
    setSelectedValues(
      formData[content === "domain" ? "domains" : "past_companies"] || []
    );
  }, [open, content, formData]);

  const handleCheckboxChange = (event) => {
    const { checked, value } = event.target;
    setSelectedValues((prev) => {
      if (checked && prev.length < 2) {
        return [...prev, value];
      } else if (!checked) {
        return prev.filter((val) => val !== value);
      }
      return prev;
    });
  };

  const handleAddInputValue = () => {
    if (newInput && selectedValues.length < 2) {
      setSelectedValues((prev) => [...prev, newInput]);
      setNewInput(""); // Clear input after adding
    }
  };

  const handleDone = () => {
    const updatedFormData = {
      ...formData,
      [content === "domain" ? "domains" : "past_companies"]: selectedValues,
    };
    if (page === "profile") {
      dispatch(setUserProfileData(updatedFormData));
    } else {
      dispatch(setOnBoardingData(updatedFormData));
    }
    onClose();
  };

  const handleClearAll = () => {
    setSelectedValues([]);
  };

  return (
    <Dialog
      fullScreen={isMobile}
      open={open}
      TransitionComponent={Transition}
      onClose={onClose}
      PaperProps={{
        style: {
          height: isMobile ? "100%" : "50%",
          width: isMobile ? "100%" : "50%",
          margin: "auto",
          borderRadius: isMobile ? "8px 8px 0 0" : "8px",
        },
      }}
    >
      <Box sx={{ p: 2, position: "relative", height: "100%" }}>
        <HeaderContainer>
          <IconButton aria-label="close" onClick={onClose} sx={{ p: 1 }}>
            <CloseIcon />
          </IconButton>
          <HeaderTitle>
            {content === "domain" ? "Domain" : "Company"}
          </HeaderTitle>
          <DoneButton onClick={handleDone}>Done</DoneButton>
        </HeaderContainer>
        <SeparatorLine />

        <Box sx={{ height: "calc(100% - 64px)", overflowY: "auto" }}>
          <SelectedItemsContainer>
            <Box sx={{ display: "flex", gap: "8px" }}>
              {selectedValues.map((value) => (
                <SelectedButton
                  key={value}
                  variant="contained"
                  onClick={() =>
                    setSelectedValues((prev) =>
                      prev.filter((val) => val !== value)
                    )
                  }
                >
                  {value} <CloseIcon sx={{ ml: 1 }} />
                </SelectedButton>
              ))}
            </Box>

            {selectedValues.length > 0 && (
              <ClearAllButton onClick={handleClearAll}>
                <CloseIcon />
              </ClearAllButton>
            )}
          </SelectedItemsContainer>

          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <FormInput
              value={newInput}
              onChange={(e) => setNewInput(e.target.value)}
              fullWidth={true}
            />
            <InverseBtn
              onClick={handleAddInputValue}
              sx={{
                fontSize: "12px",
                fontWeight: 500,
                padding: "8px",
                marginTop: "2px",
              }}
            >
              Add
            </InverseBtn>
          </Box>

          <FormGroup>
            {(content === "domain"
              ? domainsCheckboxes
              : pastCompaniesCheckboxes
            ).map((checkbox) => (
              <StyledFormControlLabel
                key={checkbox.value}
                control={
                  <Checkbox
                    value={checkbox.value}
                    onChange={handleCheckboxChange}
                    checked={selectedValues.includes(checkbox.value)}
                    disabled={
                      !selectedValues.includes(checkbox.value) &&
                      selectedValues.length >= 2
                    }
                  />
                }
                label={
                  <StyledCheckboxLabel>{checkbox.label}</StyledCheckboxLabel>
                }
              />
            ))}
          </FormGroup>
        </Box>
      </Box>
    </Dialog>
  );
};

const HeaderContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const HeaderTitle = styled.h2`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 20px;
  font-weight: 500;
  margin: 0;
`;

const SeparatorLine = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  margin-bottom: 8px;
`;

const SelectedItemsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 16px;
`;

const SelectedButton = styled(Button)`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: white;
  padding: 4px 8px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  text-transform: none;
  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.dark};
  }
`;

const ClearAllButton = styled(Button)`
  display: flex;
  align-items: center;
  background-color: transparent;
  color: ${({ theme }) => theme.palette.text.secondary};
  padding: 4px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: ${({ theme }) => theme.palette.action.hover};
  }
`;

const DoneButton = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[500]};
  font-size: 14px;
  padding: 8px 16px;
  font-weight: 500;
  cursor: pointer;
`;

const StyledCheckboxLabel = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 14px;
  font-weight: 500;
  word-wrap: break-word;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  }
`;

export default SlideUp;

import { css } from "@emotion/react";
import styled from "@emotion/styled";
import {
  Box,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";

import { GetCity, GetCountries, GetState } from "react-country-state-city";
import { Controller, useForm, useWatch } from "react-hook-form";
import "react-phone-input-2/lib/material.css";
import { useDispatch, useSelector } from "react-redux";
import CSCSelectInput from "../../component/Common/CSCSelectInput";
import FormBtn from "../../component/Common/FormBtn";
import FormInput from "../../component/Common/FormInput";
import { RequiredMark } from "../../component/Common/RequiredMark";
import { TermsAndPolicyFooter } from "../../component/Common/TermsAndPolicyFooter";
import { setOnBoardingData } from "../../redux/Slices/onBoardingSlice";
import "../../styles.css";
// import { OtpVerificationDialog } from "../../component/OnBoarding/OtpVerificationDialog";

const OBScreen1 = ({}) => {
  const theme = useTheme();
  const [isChecked, setIsChecked] = useState(true);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  // const [openOtpVerificationDialog, setOpenOtpVerificationDialog] =
  //   useState(false);

  const dispatch = useDispatch();
  const formData = useSelector((state) => state.onboarding);

  const handleSwitchChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const {
    control,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: formData,
  });

  const platform = useWatch({
    control,
    name: "platform",
  });

  const onSubmit = (data) => {
    dispatch(setOnBoardingData(data));
    console.log(formData);
    if (PhoneInput) {
      dispatch(
        setOnBoardingData({
          ...formData,
          active_onboarding_step: 2,
        })
      );
    }
  };

  const fetchCountries = () => {
    GetCountries()
      .then((result) => {
        const filteredCountries = result.filter(
          (country) => country.region === "Europe"
        );
        setCountries(filteredCountries);
      })
      .catch((error) => {
        console.error("Error fetching countries:", error);
      });
  };

  const fetchStates = (countryId) => {
    GetState(countryId)
      .then((result) => {
        setStates(result);
      })
      .catch((error) => {
        console.error("Error fetching states:", error);
      });
  };

  const fetchCities = (countryId, stateId) => {
    GetCity(countryId, stateId)
      .then((result) => {
        setCities(result);
      })
      .catch((error) => {
        console.error("Error fetching cities:", error);
      });
  };

  const handleCountryChange = async (e) => {
    const countryId = e.target.value;

    // Use the `find` method instead of `forEach`
    const selectedCountry = countries.find(
      (country) => country.id == countryId
    );

    // If a country is found, proceed with the update
    const countryName = selectedCountry ? selectedCountry.name : "";
    console.log(countryName, countryId);
    // Dispatch to Redux with the updated country details
    dispatch(
      setOnBoardingData({
        ...formData,
        country: countryName,
        country_id: countryId,
        state: "",
        city: "",
      })
    );

    // Fetch states and reset states and cities
    fetchStates(countryId);
    setStates([]);
    setCities([]);
  };

  const handleStateChange = (e) => {
    const stateId = e.target.value;

    // Use the `find` method to get the selected state
    const selectedState = states.find((state) => state.id == stateId);

    // Get the state name or default to an empty string if not found
    const stateName = selectedState ? selectedState.name : "";

    // Dispatch to Redux with updated state details
    dispatch(
      setOnBoardingData({
        ...formData,
        state: stateName,
        state_id: stateId,
        city: "", // Reset city when state changes
      })
    );

    // Fetch cities based on the selected state
    fetchCities(formData.country_id, stateId);
  };

  const handleCityChange = (e) => {
    const cityId = e.target.value;

    const selectedCity = cities.find((city) => city.id == cityId);

    const cityName = selectedCity ? selectedCity.name : "";

    dispatch(
      setOnBoardingData({
        ...formData,
        city: cityName,
        city_id: cityId,
      })
    );
  };

  const handleInputChange = async (name, value, e) => {
    dispatch(
      setOnBoardingData({
        ...formData,
        [name]: value,
      })
    );
    await trigger(name);
  };

  const onClickVerify = () => {
    dispatch(
      setOnBoardingData({
        ...formData,
        active_onboarding_step: 10,
      })
    );
    // handleOpenOtpVerificationDialog();
  };

  // const handleOpenOtpVerificationDialog = () => {
  //   setOpenOtpVerificationDialog(true);
  // };

  // const handleClose = () => {
  //   setOpenOtpVerificationDialog(false);
  // };

  useEffect(() => {
    if (!countries.length) {
      fetchCountries();
    }
  }, []);
  useEffect(() => {
    if (formData.country_id) {
      fetchStates(formData.country_id);
    }
  }, [formData.country_id]);

  useEffect(() => {
    if (formData.country_id && formData.state_id) {
      fetchCities(formData.country_id, formData.state_id);
    }
  }, [formData.state_id]);

  useEffect(() => {
    if (formData.state_id && formData.city_id) {
      fetchCities(formData.country_id, formData.state_id);
    }
  }, []);

  useEffect(() => {
    setValue("platform_url", formData.platform_url || "");
    trigger("platform_url");
  }, [platform]);

  return (
    <Box>
      <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="first_name"
          control={control}
          rules={{
            required: "First name is required.",
            minLength: {
              value: 3,
              message: "First name must be at least 3 characters long",
            },
          }}
          render={({ field }) => (
            <FormInput
              label="First name"
              id="first_name"
              type="text"
              value={formData.first_name}
              placeholder="Enter your first name"
              required
              {...field}
              error={!!errors.first_name}
              helperText={errors.first_name ? errors.first_name.message : ""}
              onChange={(e) => {
                field.onChange(e);
                handleInputChange("first_name", e.target.value);
              }}
            />
          )}
        />

        <Controller
          name="last_name"
          control={control}
          rules={{
            required: "Last name is required.",
            minLength: {
              value: 3,
              message: "Last name must be at least 3 characters long",
            },
          }}
          render={({ field }) => (
            <FormInput
              label="Last name"
              id="last_name"
              value={formData.last_name}
              type="text"
              placeholder="Enter your last name"
              required
              {...field}
              error={!!errors.last_name}
              helperText={errors.last_name ? errors.last_name.message : ""}
              onChange={(e) => {
                field.onChange(e);
                handleInputChange("last_name", e.target.value);
              }}
            />
          )}
        />

        <Controller
          name="country"
          control={control}
          rules={{
            required: "Country is required.",
          }}
          render={({ field }) => (
            <CSCSelectInput
              label="Country"
              id="country"
              name="country"
              options={countries.map((country) => ({
                value: country.id,
                label: country.name,
              }))}
              fullWidth={false}
              required
              error={!!errors.country}
              helperText={errors.country ? errors.country.message : ""}
              value={formData.country_id}
              onChange={(e) => {
                field.onChange(e);
                handleCountryChange(e);
              }}
            />
          )}
        />

        <Controller
          name="state"
          control={control}
          rules={{
            required: "State is required.",
          }}
          render={({ field }) => (
            <CSCSelectInput
              label="State"
              id="state"
              name="state"
              disabled={!formData.country_id} // Disable until country is selected
              options={states.map((state) => ({
                value: state.id,
                label: state.name,
              }))}
              fullWidth={false}
              required
              error={!!errors.state}
              helperText={errors.state ? errors.state.message : ""}
              value={formData.state_id}
              onChange={(e) => {
                field.onChange(e);
                handleStateChange(e);
              }}
            />
          )}
        />

        <Controller
          name="city"
          control={control}
          render={({ field }) => (
            <CSCSelectInput
              label="City"
              id="city"
              name="city"
              disabled={!formData.state_id} // Disable until state is selected
              options={cities.map((city) => ({
                value: city.id,
                label: city.name,
              }))}
              fullWidth={false}
              error={!!errors.city}
              helperText={errors.city ? errors.city.message : ""}
              value={formData.city_id}
              onChange={(e) => {
                field.onChange(e);
                handleCityChange(e);
              }}
            />
          )}
        />
        <Controller
          name="platform"
          control={control}
          render={({ field }) => (
            <StyledRadioGroup
              {...field}
              value={formData.platform}
              onChange={(e) => {
                field.onChange(e);
                handleInputChange("platform", e.target.value);
              }}
            >
              <Box sx={{ flex: 1, padding: 0 }}>
                <RequiredMark />
                <FormControlLabel
                  value="LinkedIn"
                  control={<Radio sx={{ py: 0, marginLeft: "4px" }} />}
                  label={<StyledRadioLabel>LinkedIn</StyledRadioLabel>}
                />
              </Box>
              <StyledRadioBox>
                <FormControlLabel
                  value="Xing"
                  control={<Radio sx={{ py: 0 }} />}
                  label={<StyledRadioLabel>Xing</StyledRadioLabel>}
                />
              </StyledRadioBox>
            </StyledRadioGroup>
          )}
        />
        <Controller
          name="platform_url"
          control={control}
          rules={{
            required: `${formData.platform} url is required`,
            pattern: {
              value: new RegExp(
                `^https:\/\/www\\.${platform?.toLowerCase()}.com\\/.*$`
              ),
              message: `Invalid ${platform} url `,
            },
          }}
          render={({ field }) => (
            <FormInput
              id="platform_url"
              type="text"
              value={formData.platform_url}
              placeholder={`Enter ${platform} link`}
              containerStyle={{ marginTop: "0px" }}
              inputStyle={{
                "&:hover fieldset": {
                  border: "1px solid #6a1b9a",
                },
                "&.Mui-focused fieldset": {
                  border: "1px solid #6a1b9a",
                },
              }}
              required
              {...field}
              error={!!errors.platform_url}
              helperText={
                errors.platform_url ? errors.platform_url.message : ""
              }
              onChange={(e) => {
                field.onChange(e);
                handleInputChange("platform_url", e.target.value);
              }}
            />
          )}
        />

        <Controller
          name="referred_by"
          control={control}
          render={({ field }) => (
            <FormInput
              label="Referred by"
              id="referred_by"
              value={formData.referred_by}
              type="text"
              required={false}
              fullWidth={false}
              {...field}
              onChange={(e) => {
                field.onChange(e);
                handleInputChange("referred_by", e.target.value);
              }}
            />
          )}
        />
        <Box sx={{ mt: 1 }}>
          <StyledFormLabel>
            <RequiredMark />
            Phone
          </StyledFormLabel>
          <Controller
            name="phone_number"
            control={control}
            rules={{ required: "Phone number is required." }}
            render={({ field }) => (
              <PhoneInput
                id="phone_number"
                country={"in"}
                value={formData.phone_number}
                inputStyle={{
                  width: "100%",
                  fontSize: "14px",
                  borderRadius: "4px",
                  paddingTop: "12px",
                  paddingBottom: "12px",
                  border: "2px solid #A1A1AA",
                  outline: "none",
                }}
                buttonStyle={{ width: "0px" }}
                inputProps={{
                  id: "phone_number",
                }}
                onChange={(value) => {
                  field.onChange(value);
                  handleInputChange("phone_number", value);
                }}
              />
            )}
          />
          {errors.phone_number && (
            <Typography variant="caption" color="error">
              {errors.phone_number.message}
            </Typography>
          )}
          {!errors.phone_number && (
            <StyledPhoneInfoBox>
              {!formData.is_phone_verified && (
                <StyledInputInfo>
                  Verify your number to continue
                </StyledInputInfo>
              )}

              {!formData.is_phone_verified ? (
                <StyledVerifyLink onClick={onClickVerify}>
                  Verify now
                </StyledVerifyLink>
              ) : (
                <StyledVerifyLink isVerified={formData.is_phone_verified}>
                  Verified
                </StyledVerifyLink>
              )}
            </StyledPhoneInfoBox>
          )}
        </Box>

        <StyledBtnContainer>
          <FormBtn type="submit" disabled={!formData.is_phone_verified}>
            {" "}
            Continue
          </FormBtn>
          {/* <FormBtn type="submit" disabled={false}>
          {" "}
          Continue
        </FormBtn> */}
        </StyledBtnContainer>
        <TermsAndPolicyFooter />
      </Box>
      {/* <OtpVerificationDialog
        openOtpVerificationDialog={openOtpVerificationDialog}
        handleClose={handleClose}
      /> */}
    </Box>
  );
};

const StyledPhoneInfoBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledBtnContainer = styled(Box)`
  margin-top: 24px;
`;

const StyledInputInfo = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[500]};
  font-size: 12px;
  font-weight: 400;
`;

const StyledVerifyLink = styled(Typography)`
  ${({ theme, isVerified }) => css`
    color: ${isVerified
      ? theme.palette.text.success
      : theme.palette.common.purple};
    font-size: 12px;
    font-weight: 500;
    text-decoration: ${isVerified ? "none" : "underline"};
    cursor: pointer;
  `}
`;

const StyledRadioLabel = styled("span")`
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  font-size: 14px;
  font-weight: 400;
  wordwrap: break-word;
`;

const StyledFormLabel = styled(FormLabel)`
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.14px;
  line-height: 20px;

  ${(props) =>
    props.sx &&
    css`
      ${props.sx}
    `}
`;

const StyledRadioGroup = styled(RadioGroup)`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
`;

const StyledRadioBox = styled(Box)`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  padding: 0;
`;

export default OBScreen1;

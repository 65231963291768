import React from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import styled from "@emotion/styled";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules"; // Import Autoplay
import "swiper/css";
import "swiper/css/pagination";

const CorosoleContent = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        mx: { xs: 4, sm: 6, md: 8 },
        my: { xs: 4, sm: 6, md: 10 },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        color: theme.palette.text.neutral[700],
      }}
    >
      <StyledCorosoleSectionTitle>
        Jobs in E.U. for Marathis{" "}
      </StyledCorosoleSectionTitle>
      <StyledCorosoleSectionSubTitle>
        Join Marathi professionals community in Europe.{" "}
      </StyledCorosoleSectionSubTitle>
      <StyledCorosoleSectionSubTitle>
        Network, grow, and succeed together.
      </StyledCorosoleSectionSubTitle>

      <Box sx={{ mt: 2, width: "100%", textAlign: "center" }}>
        <CustomSwiperPagination>
          <Swiper
            spaceBetween={30}
            pagination={{ clickable: true }}
            modules={[Pagination, Autoplay]} // Include Autoplay module
            centeredSlides={true}
            autoplay={{ delay: 3000 }} // Autoplay settings
          >
            <SwiperSlide>
              <Box>
                <img src="/assets/images/corosoul-img-1.png" alt="USP 1" />
                <StyledCorosoleImageTitle>USPs</StyledCorosoleImageTitle>
                <StyledUspContainer>
                  <Box>
                    <StyledUspItemTitle>50+</StyledUspItemTitle>
                    <StyledUspItemSubTitle>Companies</StyledUspItemSubTitle>
                  </Box>
                  <Box>
                    <StyledUspItemTitle>24</StyledUspItemTitle>
                    <StyledUspItemSubTitle>Cities</StyledUspItemSubTitle>
                  </Box>
                  <Box>
                    <StyledUspItemTitle>500+</StyledUspItemTitle>
                    <StyledUspItemSubTitle>
                      Successful referrals
                    </StyledUspItemSubTitle>
                  </Box>
                </StyledUspContainer>
              </Box>
            </SwiperSlide>
            <SwiperSlide>
              <Box>
                <img src="/assets/images/corosoul-img-2.png" alt="USP 2" />
                <StyledCorosoleImageTitle>
                  Popular Categories
                </StyledCorosoleImageTitle>
                <StyledCategoryContainer>
                  <ul style={{ textAlign: "left", paddingLeft: "8px" }}>
                    <li>
                      <StyledUspItemSubTitle>IT</StyledUspItemSubTitle>
                    </li>
                    <li>
                      <StyledUspItemSubTitle>Finance</StyledUspItemSubTitle>
                    </li>
                  </ul>
                  <ul style={{ textAlign: "left" }}>
                    <li>
                      <StyledUspItemSubTitle>Healthcare</StyledUspItemSubTitle>
                    </li>
                    <li>
                      <StyledUspItemSubTitle>Education</StyledUspItemSubTitle>
                    </li>
                  </ul>
                </StyledCategoryContainer>
              </Box>
            </SwiperSlide>
          </Swiper>
        </CustomSwiperPagination>
      </Box>
    </Box>
  );
};

const StyledCorosoleSectionTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  font-size: 20px;
  font-weight: 600;
  word-wrap: break-word;
  margin-bottom: 8px;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    font-size: 12px;
  }
`;

const StyledCorosoleSectionSubTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  font-size: 14px;
  font-weight: 400;
  lineheight: 20;
  word-wrap: break-word;
  letterspacing: 0.14;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    font-size: 12px;
  }
`;

const CustomSwiperPagination = styled.div`
  .swiper-pagination {
    position: relative;
    margin-top: 24px;
  }

  .swiper-pagination-bullet {
    background: #a1a1a1;
    padding: 6px;
  }
  .swiper-pagination-bullet-active {
    background: #400091;
  }
`;

const StyledCorosoleImageTitle = styled(Typography)`
  color: black;
  font-size: 24px;
  font-weight: 500;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    font-size: 12px;
  }
`;

const StyledUspItemTitle = styled(Typography)`
  color: black;
  font-size: 24px;
  font-weight: 600;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    font-size: 20px;
  }
`;

const StyledUspItemSubTitle = styled(Typography)`
  color: black;
  font-size: 14px;
  font-weight: 500;
`;
const StyledUspContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 32px;
  margin-top: 16px;
`;

const StyledCategoryContainer = styled(Box)`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;

export default CorosoleContent;

import { PostAddOutlined } from "@mui/icons-material";

export const ONBOARDING_STEPPER_STEPS = [
  "Personal details",
  "Professional details",
  "Verification"
];

export const EMPLOYMENT_STATUS = {
  CURRENTLY_EMPLOYED: 'CURRENTLY_EMPLOYED',
  ACTIVELY_LOOKING_FOR_JOB: 'ACTIVELY_LOOKING_FOR_JOB',
  EMPLOYED_AND_SEEKING_JOB: 'EMPLOYED_AND_SEEKING_JOB'

}

export const EMPLOYMENT_STATUS_OPTIONS = [
  { value: "CURRENTLY_EMPLOYED", label: "Currently employed & referral advocate" },
  { value: "ACTIVELY_LOOKING_FOR_JOB", label: "Actively looking for jobs" },
  { value: "EMPLOYED_AND_SEEKING_JOB", label: "Employed & seeking job" },
];


export const EMPLOYMENT_TYPE = {
  FULL_TIME: 'FULL_TIME',
  PART_TIME: 'PART_TIME',
  INTERNSHIP: 'INTERNSHIP',
  THESIS: 'THESIS'
}

export const EMPLOYMENT_TYPE_OPTIONS = [
  { value: "FULL_TIME", label: "Full time" },
  { value: "PART_TIME", label: "Part time" },
  { value: "INTERNSHIP", label: "Internship" },
  { value: "THESIS", label: "Thesis" },
];

export const LOADING_STATUS = {
  INITIAL: "initial",
  LOADING: "loading",
  LOADED: "loaded",
  FAILED: "failed",
};

export const SAVING_STATUS = {
  INITIAL: "initial",
  SENDING: "sending",
  SAVED: "saved",
  FAILED: "failed",
};

export const POST_STATUS = {
  INITIAL: "INITIAL",
  POSTING: "POSTING",
  SAVED: "SAVED",
  FAILED: "FAILED",
};

export const DELETE_STATUS = {
  INITIAL: "INITIAL",
  DELETING: "DELETING",
  DELETED: "DELETED",
  FAILED: "FAILED",
};

export const GET_STATUS = {
  FECHING: "FECHING",
  FETCHED: "FETCHED",
  FAILED: "FAILED",
};



export const JOB_TYPE_OPTIONS = [
  { value: "FULL_TIME", label: "Full time" },
  { value: "PART_TIME", label: "Part time" },
  { value: "THESIS", label: "Thesis" },
];

export const JOB_TYPE = {
  FULL_TIME: 'FULL_TIME',
  PART_TIME: 'PART_TIME',
  THESIS: 'THESIS'
}

export const JOB_POST_STATUS = {
  INIT: "INIT",
  IN_REVIEW: 'IN_REVIEW',
  ACTIVE: 'ACTIVE',
  REJECTED: 'REJECTED',
  CLOSED: 'CLOSED'
}

export const EVENT_TYPE = {
  PAID: 'PAID',
  FREE: 'FREE',
}

export const EVENT_MODE = {
  ONLINE: 'ONLINE',
  OFFLINE: 'OFFLINE',
}

export const EVENT_STATUS = {
  INIT: "INIT",
  IN_REVIEW: 'IN_REVIEW',
  ACTIVE: 'ACTIVE',
  REJECTED: 'REJECTED',
  CLOSED: 'CLOSED'
}

export const HP_POST_STATUS = {
  INIT: "INIT",
  IN_REVIEW: 'IN_REVIEW',
  ACTIVE: 'ACTIVE',
  REJECTED: 'REJECTED',
  CLOSED: 'CLOSED'
}


export const USER_PROFILE_STATUS = {
  IN_REVIEW: 'IN_REVIEW',
  ACTIVE: 'ACTIVE',
  REJECTED: 'REJECTED',
  ON_HOLD: 'ON_HOLD',
  DELETED: 'DELETED'
}

import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import userSlice from './Slices/userSlice';
import onBoardingSlice from './Slices/onBoardingSlice';
import jobPostSlice from './Slices/jobPostSlice';
import eventSlice from './Slices/eventSlice';
import postSlice from './Slices/postSlice';
import eventListnerSlice from './Slices/eventListnerSlice';
import userProfileSlice from './Slices/userProfileSlice';




// Define persist configuration
const persistConfig = {
  key: 'root',
  storage,
};

// Combine the reducers into a root reducer
const rootReducer = combineReducers({
  user: userSlice,
  onboarding: onBoardingSlice,
  jobPost: jobPostSlice,
  event: eventSlice,
  post: postSlice,
  eventListener: eventListnerSlice,
  userProfile: userProfileSlice
});


// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure the store with the persisted reducer
const store = configureStore({
  reducer: persistedReducer,
});

// Create the persistor
const persistor = persistStore(store);

// Export both the store and the persistor
export { store, persistor };

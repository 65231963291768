import { css } from "@emotion/react";
import styled from "@emotion/styled";
import {
  Box,
  Container,
  CssBaseline,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import FormBtn from "../../component/Common/FormBtn";
import { TermsAndPolicyFooter } from "../../component/Common/TermsAndPolicyFooter";
import { useNavigate } from "react-router-dom";

const WelcomeScreen = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/signup");
  };

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: {
          xs: "flex-start", // default for mobile screens
          md: "center", // apply center alignment for medium screens and above (laptops and larger)
        },
        backgroundColor: theme.palette.common.white,
        padding: "16px 8px",
      }}
    >
      <CssBaseline />
      <Container maxWidth="sm">
        <StyledMainBox>
          <Box>
            <StyledTitle sx={{ marginBottom: 2 }}>
              How Community Works:
            </StyledTitle>
          </Box>

          <StyledBox>
            <Box sx={{ mr: 1 }}>
              <StyledStep>1.</StyledStep>
            </Box>
            <StyledInnerBox>
              <StyledStep> Network and Connect:</StyledStep>
              <StyledDescription>
                Connect with Marathi-speaking professionals, mentors, and job
                seekers.
              </StyledDescription>
            </StyledInnerBox>
          </StyledBox>

          <StyledBox sx={{ mt: 2 }}>
            <Box sx={{ mr: 1 }}>
              <StyledStep>2.</StyledStep>
            </Box>
            <StyledInnerBox>
              <StyledStep> Create Your Profile:</StyledStep>
              <StyledDescription>
                Sign up and create a professional profile to showcase your
                skills and experiences.
              </StyledDescription>
            </StyledInnerBox>
          </StyledBox>

          <StyledBox sx={{ mt: 2 }}>
            <Box sx={{ mr: 1 }}>
              <StyledStep>3.</StyledStep>
            </Box>
            <StyledInnerBox>
              <StyledStep>Share and Discover Opportunities: </StyledStep>
              <StyledDescription>
                Post job openings, find referrals, and explore career
                opportunities tailored to your interests.
              </StyledDescription>
            </StyledInnerBox>
          </StyledBox>

          <StyledBox sx={{ mt: 2 }}>
            <Box sx={{ mr: 1 }}>
              <StyledStep>4.</StyledStep>
            </Box>
            <StyledInnerBox>
              <StyledStep>Participate in Events: </StyledStep>
              <StyledDescription>
                Join workshops, networking events, and entrepreneurship sessions
                online and in-person.
              </StyledDescription>
            </StyledInnerBox>
          </StyledBox>

          <StyledBox sx={{ mt: 2 }}>
            <Box sx={{ mr: 1 }}>
              <StyledStep>5.</StyledStep>
            </Box>
            <StyledInnerBox>
              <StyledStep>Stay Engaged: </StyledStep>
              <StyledDescription>
                Engage with the community through discussions, forums, and
                events, all conducted in Marathi.
              </StyledDescription>
            </StyledInnerBox>
          </StyledBox>

          <Box
            sx={{
              mt: 3, // Responsive margin-top
            }}
          >
            <FormBtn onClick={handleClick}>Join community</FormBtn>
          </Box>
          <TermsAndPolicyFooter />
        </StyledMainBox>
      </Container>
    </Box>
  );
};

const StyledMainBox = styled(Box)`
  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin-top: 24px;
    margin-bottom: 36px;
  }
`;

const StyledTitle = styled(Typography)`
  color: black;
  font-size: 24px;
  font-weight: 500;
  word-wrap: break-word;
`;

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: row;

  ${(props) =>
    props.sx &&
    css`
      ${props.sx}
    `}
`;
const StyledInnerBox = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const StyledStep = styled(Typography)`
  color: #000;
  font-weight: 600;
  word-wrap: break-word;
  font-size: 16px;
`;

const StyledDescription = styled(Typography)`
  color: #000;
  font-weight: 400;
  word-wrap: break-word;
  font-size: 14px;
`;

export default WelcomeScreen;

import React from 'react';

import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Home } from "./pages/Home";
import { ThemeProvider, createTheme } from '@mui/material';
import { SignIn } from './pages/Auth/SignIn';
import { SignUp } from './pages/Auth/SignUp';
import OBScreen1 from './pages/OnBoarding/OBScreen1';
import OBScreen2 from './pages/OnBoarding/OBScreen2';
import OBScreen3 from './pages/OnBoarding/OBScreen3';
import WelcomeScreen from './pages/OnBoarding/WelcomeScreen';
import MainLayout from './layout/MainLayout'
import { Jobs } from './pages/Jobs';
import { Events } from './pages/Events/index'
import { Mentoring } from './pages/Mentoring';
import { Profile } from './pages/Profile';
import { PostDescription } from './pages/Posts/PostDescription';
import { EditProfile } from './pages/Profile/EditProfile';
import { Onboarding } from './pages/OnBoarding/Onboarding';
import { LandingPage } from './pages/Auth/LandingPage';
import { ForgotPassword } from './pages/Auth/ForgotPassword';
import theme from './styles/theme';
import ProtectedRoute from './component/Auth/ProtectedRoute';
import NotFoundPage from './pages/NotFoundPage';
import AdminLayout from './layout/AdminLayout';
import { ManageRequests } from './pages/Admin/ManageRequests';
import { PeopleManagent } from './pages/Admin/PeopleManagent';
import { MonitoringJobPost } from './pages/Admin/MonitoringJobPost';
import { AdminEvents } from './pages/Admin/AdminEvents';
import { MyPosts } from './pages/MyPosts';
import { EventDescription } from './pages/Events/EventDescription';
import { Bookmarks } from './pages/Bookmarks';
import AdminProtectedRoutes from './component/Auth/AdminProtectedRoutes';
import { AdminSignIn } from './pages/Auth/AdminSignIn';



function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/landing" element={<LandingPage />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/marathi-referrals-admin/signin" element={<AdminSignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/forgot-passward" element={<ForgotPassword />} />
          <Route path="/welcome" element={<WelcomeScreen />} />
          <Route path="/onboarding" element={<Onboarding />} />

          <Route element={<ProtectedRoute />}>
            <Route path="/" element={<MainLayout />}>
              <Route index element={<Home />} />
              <Route path="jobs" element={<Jobs />} />
              <Route path="events" element={<Events />} />
              <Route path="/events/:eventId" element={<EventDescription />} />
              <Route path="mentoring" element={<Mentoring />} />
              <Route path="profile" element={<Profile />} />
              <Route path="/post/:postId" element={<PostDescription />} />
              <Route path="/profile/edit/:id" element={<EditProfile />} />
              <Route path="/my-posts" element={<MyPosts />} />
              <Route path="/bookmarks" element={<Bookmarks />} />
            </Route>
          </Route>
          <Route element={<AdminProtectedRoutes />}>
            <Route path="/admin/*" element={<AdminLayout />}>
              <Route index element={<Navigate to="/admin/manage-requests" />} />
              <Route path="manage-requests" element={<ManageRequests />} />
              <Route path="people-management" element={<PeopleManagent />} />
              <Route path="monitoring-job-posts" element={<MonitoringJobPost />} />
              <Route path="events" element={<AdminEvents />} />
            </Route>
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router >
    </ThemeProvider >
  );
}

export default App;

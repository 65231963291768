import React from "react";
import Skeleton from "@mui/material/Skeleton";
import { Box as MuiBox, Card as MuiCard } from "@mui/material";
import styled from "@emotion/styled";

export const JobCardSkeleton = () => {
  return (
    <>
      <Card>
        <FlexBox>
          <Skeleton
            animation="wave"
            variant="circular"
            width={40}
            height={40}
          />
          <Box flexGrow={1}>
            <Skeleton animation="wave" height={15} width="30%" />
            <Skeleton animation="wave" height={15} width="50%" />
          </Box>
        </FlexBox>
        <SkeletonRectangle variant="rectangular" />
        <Box mt={2}>
          <Skeleton animation="wave" height={25} width="30%" />
          <Skeleton animation="wave" height={15} width="30%" />
        </Box>
        <Box mt={2}>
          <Skeleton animation="wave" height={15} width="100%" />
          <Skeleton animation="wave" height={15} width="100%" />
          <Skeleton animation="wave" height={15} width="100%" />
        </Box>
      </Card>

      <Card mt={4}>
        <FlexBox>
          <Skeleton
            animation="wave"
            variant="circular"
            width={40}
            height={40}
          />
          <Box flexGrow={1}>
            <Skeleton animation="wave" height={15} width="30%" />
            <Skeleton animation="wave" height={15} width="50%" />
          </Box>
        </FlexBox>
        <SkeletonRectangle variant="rectangular" />
        <Box mt={2}>
          <Skeleton animation="wave" height={25} width="30%" />
          <Skeleton animation="wave" height={15} width="30%" />
        </Box>
        <Box mt={2}>
          <Skeleton animation="wave" height={15} width="100%" />
          <Skeleton animation="wave" height={15} width="100%" />
          <Skeleton animation="wave" height={15} width="100%" />
        </Box>
      </Card>

      <Card mt={4}>
        <FlexBox>
          <Skeleton
            animation="wave"
            variant="circular"
            width={40}
            height={40}
          />
          <Box flexGrow={1}>
            <Skeleton animation="wave" height={15} width="30%" />
            <Skeleton animation="wave" height={15} width="50%" />
          </Box>
        </FlexBox>
        <SkeletonRectangle variant="rectangular" />
        <Box mt={2}>
          <Skeleton animation="wave" height={25} width="30%" />
          <Skeleton animation="wave" height={15} width="30%" />
        </Box>
        <Box mt={2}>
          <Skeleton animation="wave" height={15} width="100%" />
          <Skeleton animation="wave" height={15} width="100%" />
          <Skeleton animation="wave" height={15} width="100%" />
        </Box>
      </Card>
    </>
  );
};

// Styled components using Emotion
const Card = styled(MuiCard)`
  padding: 16px;
  margin-top: ${({ mt }) =>
    mt ? `${mt * 8}px` : "0"}; // Convert 'mt' prop to pixel values
`;

const FlexBox = styled(MuiBox)`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const SkeletonRectangle = styled(Skeleton)`
  border-radius: 4px;
  width: 100%;
  height: 118px;
  margin-top: 16px;
`;

const Box = styled(MuiBox)`
  margin-top: ${({ mt }) => mt * 8}
  flex-grow: ${({ flexGrow }) => flexGrow || "initial"};
`;

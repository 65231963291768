import { css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import IosShareIcon from "@mui/icons-material/IosShare";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import {
  Alert,
  Avatar,
  Box,
  Container,
  Grid,
  Link,
  Snackbar,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toggleBookmarkEvent } from "../../api/Event/event";
import AddButton from "../../component/Common/AddButton";
import DeleteButton from "../../component/Common/DeleteButton";
import { DeleteDialog } from "../../component/Common/DeleteDialog";
import EditButton from "../../component/Common/EditButton";
import FormBtn from "../../component/Common/FormBtn";
import SectionTitle from "../../component/Common/SectionTitle";
import { Tag } from "../../component/Common/Tag";
import { EditEventDialog } from "../../component/Events/EditEventDialog";
import { POST_STATUS } from "../../constants/constants";
import { resetEventState, setEventData } from "../../redux/Slices/eventSlice";
import {
  addEventBookmark,
  removeEventBookmark,
} from "../../redux/Slices/userSlice";
import { formatEventDateTime } from "../../utils/helpers";

export const EventDescription = () => {
  const location = useLocation();

  const eventData = location.state?.event;
  const eventId = eventData?.event_id;
  const theme = useTheme();
  const dispatch = useDispatch();
  const bookmarks = useSelector((state) => state.user.eventBookmarks);
  const [bookmarkStatus, setBookmarkStaus] = useState("");
  const isEventBookMarked = bookmarks?.includes(eventId);
  const [openEditEventDialog, setOpenEditEventDialog] = useState(false);
  const [openDeleteEventDialog, setOpenDeleteEventDialog] = useState(false);
  const eventState = useSelector((state) => state.event);
  const userId = useSelector((state) => state.user.userId);

  const handleOpenEditEventDialog = () => {
    const formData = {
      event_name: eventData?.event_name,
      event_type: eventData?.event_type,
      event_mode: eventData?.event_mode,
      location: eventData?.location,
      event_link: eventData?.event_link,
      description: eventData?.description,
      event_status: eventData?.event_status,
      event_start_datetime: eventData?.event_start_datetime,
      event_end_datetime: eventData?.event_end_datetime,
      amount: eventData?.amount,
    };
    dispatch(
      setEventData({
        ...eventState,
        ...formData,
      })
    );
    setOpenEditEventDialog(true);
  };

  const handleCloseEditEventDialog = (event) => {
    dispatch(resetEventState());
    setOpenEditEventDialog(false);
  };

  const handleOpenDeleteEventDialog = (event) => {
    setOpenDeleteEventDialog(true);
  };
  const handleCloseDeleteEventDialog = (event) => {
    setOpenDeleteEventDialog(false);
  };
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  // Function to open Snackbar with custom message and severity
  const handleOpenSnackbar = (message, severity = "success") => {
    setSnackbar({
      open: true,
      message,
      severity,
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const toggleBookmark = () => {
    if (isEventBookMarked) {
      dispatch(removeEventBookmark(eventId));
      postBookmarkEvent("delete");
    } else {
      dispatch(addEventBookmark(eventId));
      postBookmarkEvent("create");
    }
  };

  const postBookmarkEvent = async (action) => {
    try {
      const data = {
        event_id: eventId,
        user_id: eventData?.created_by,
      };
      setBookmarkStaus(POST_STATUS.POSTING);
      const response = await toggleBookmarkEvent(data, action);
      if (response.status === 200) {
        setBookmarkStaus(POST_STATUS.SAVED);
      } else {
        console.log("Error while bookmarking event.");
        setBookmarkStaus(POST_STATUS.FAILED);
      }
    } catch (error) {
      console.log("Error while bookmarking event.", error.message);
      setBookmarkStaus(POST_STATUS.FAILED);
    }
  };

  return (
    <>
      <Container maxWidth="xl" sx={{ py: 3, px: 2 }}>
        <TopBox>
          <SectionTitle>Tech Innovators Meetup 2024</SectionTitle>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <UserContainer>
              <AvatarContainer>
                <Avatar src="/assets/images/avatar-1.png" />
              </AvatarContainer>
              <UserDetails>
                <UserName>{`${eventData?.user?.first_name} ${eventData?.user?.last_name}`}</UserName>
                <UserTitle>{eventData?.user?.current_job_title}</UserTitle>
              </UserDetails>
            </UserContainer>
            <Box sx={{ display: "flex", flexDirection: "row", gap: "16px" }}>
              <AddButton
                icon={IosShareIcon}
                style={{ padding: "8px 24px" }}
                isFullWidth={true}
              >
                Share
              </AddButton>
              <FormBtn>Attend</FormBtn>
            </Box>
          </Box>
        </TopBox>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8} mt={2}>
            <StyledImage src={"/assets/images/events2.png"} />
            <Details>Details</Details>
            <Description>{eventData?.description}</Description>
          </Grid>
          <Grid item xs={12} sm={4}>
            <CardContainer>
              <Row>
                <EventTime>
                  {formatEventDateTime(
                    eventData?.event_start_datetime,
                    eventData?.event_end_datetime
                  )}
                </EventTime>
                {isEventBookMarked ? (
                  <BookmarkIcon
                    onClick={toggleBookmark}
                    sx={{ color: theme.palette.common.purple }}
                  />
                ) : (
                  <BookmarkBorderOutlinedIcon
                    onClick={toggleBookmark}
                    sx={{ color: "#71717A" }}
                  />
                )}
              </Row>
              <DetailRow>
                <FmdGoodOutlinedIcon sx={{ fontSize: 18, color: "#333333" }} />
                <DetailText>
                  {eventData?.location ? eventData?.location : "--"}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <PersonOutlinedIcon sx={{ fontSize: 18, color: "#333333" }} />
                <DetailText>25 attending</DetailText>
              </DetailRow>
              {/* <DetailRow>
              <Link to={eventData?.event_link}>
                <img src="/assets/icons/link-external.svg" />
              </Link>{" "}
              <DetailText>Meeting link</DetailText>
            </DetailRow> */}
              <DetailRow>
                <Link to={eventData?.event_link}>
                  <img src="/assets/icons/web-cam.svg" />
                </Link>{" "}
                <DetailText>Online event </DetailText>
              </DetailRow>
              <LinkText>Link visible for attendees</LinkText>

              <TagContainer sx={{ marginTop: "12px" }}>
                <Tag title={eventData?.event_type} />
                <Tag title={eventData?.event_mode} />
              </TagContainer>
              {userId === eventData?.created_by && (
                <Box mt={2}>
                  <EditButton onClick={handleOpenEditEventDialog} />
                  <DeleteButton
                    onClick={handleOpenDeleteEventDialog}
                    style={{ marginLeft: "8px" }}
                  />
                </Box>
              )}
            </CardContainer>
          </Grid>
        </Grid>
        <Snackbar
          open={snackbar.open}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={snackbar.severity}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
        <EditEventDialog
          openEditEventDialog={openEditEventDialog}
          handleClose={handleCloseEditEventDialog}
          onOpenSnackbar={handleOpenSnackbar}
          eventData={eventData}
        />
        <DeleteDialog
          id={eventData?.event_id}
          userId={userId}
          type="event"
          onOpenSnackbar={handleOpenSnackbar}
          openDeleteDialog={openDeleteEventDialog}
          handleClose={handleCloseDeleteEventDialog}
        />
      </Container>
    </>
  );
};

const TopBox = styled(Box)`
  padding-bottom: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.neutral[200]};
`;

const AvatarContainer = styled(Box)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

const UserContainer = styled(Box)`
  margin: 8px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const UserDetails = styled(Box)`
  display: flex;
  flex-direction: column;
  padding-left: ${(props) => (props.isCompany ? "16px" : "0px")};
`;

const UserName = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[800]};
  font-size: 16px;
  font-family: Inter;
  font-weight: 600;
  line-height: 24px;
  word-wrap: break-word;
  text-transform: capitalize;
`;

const UserTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  font-size: 14px;
  font-family: Inter;
  font-weight: 400;
  line-height: 18px;
  text-transform: capitalize;
  word-wrap: break-word;
`;

const Details = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[800]};
  font-size: 20px;
  font-weight: 500;
  margin-top: 8px;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  max-height: 280px;
  border-radius: 8px;
  z-index: 0;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    max-height: 180px;
  }
`;

const CardContainer = styled(Box)`
  padding: 16px 12px;
  background: white;
  border-radius: 8px;
  margin-top: 16px;
  border: 1px solid ${({ theme }) => theme.palette.border.neutral[400]};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const Row = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const EventTime = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.purple};
  font-size: 16px;
  font-weight: 500;
  word-wrap: break-word;
  text-transform: uppercase;
`;

const LinkText = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[600]};
  font-size: 12px;
  font-weight: 400;
  margin-left: 24px;
`;

const DetailRow = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 8px;
`;

const DetailText = styled(Typography)`
  padding-left: 8px;
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  font-size: 14px;
  font-weight: 500;
  word-wrap: break-word;
  white-space: nowrap;
`;

const Description = styled.p`
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  font-size: 14px;
  font-weight: 400;
  word-break: break-all;
  box-sizing: border-box;
  white-space: normal;
  margin-top: 8px;
`;

const TagContainer = styled(Box)`
  margin-top: 16px;
  display: flex;
  flexdirection: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;

  ${(props) =>
    props.sx &&
    css`
      ${props.sx}
    `}
`;

import styled from "@emotion/styled";
import { Typography } from "@mui/material";

const StyledHelperText = styled(Typography)`
  color: ${({ theme, error }) =>
    error ? theme.palette.text.negative[600] : theme.palette.text.neutral[500]};
  font-size: 12px;
  margin-top: 4px;
`;

export default StyledHelperText;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  postActionId: "",
  jobPostActionId: "",
  eventActionId: "",
  profileRequestActionId: ""
};
const eventListenerSlice = createSlice({
  name: "eventListener",
  initialState,
  reducers: {
    setActionData: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setActionData } = eventListenerSlice.actions;
export default eventListenerSlice.reducer;


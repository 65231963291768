import { Authclient } from "../config"

export const signUp = async (userData) => {
  try
  {
    const response = await Authclient.post(`signup`, userData);
    return response;
  } catch (error)
  {
    if (error.response && error.response.status === 400)
    {
      return error.response;
    }
    throw error;
  }
};

export const signIn = async (userData) => {
  try
  {
    const response = await Authclient.post('signin', userData);
    return response;
  } catch (error)
  {
    if (error.response && error.response.status === 401)
    {
      return error.response;
    }
    throw error;
  }
};

export const adminSignIn = async (userData) => {
  try
  {
    const response = await Authclient.post('/marathi-referrals-admin/signin', userData);
    return response;
  } catch (error)
  {
    if (error.response && error.response.status === 401)
    {
      return error.response;
    }
    throw error;
  }
};




import styled from "@emotion/styled";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import dayjs from "dayjs";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { createEvent } from "../../api/Event/event";
import {
  EVENT_MODE,
  EVENT_STATUS,
  EVENT_TYPE,
  POST_STATUS,
} from "../../constants/constants";
import { setActionData } from "../../redux/Slices/eventListnerSlice";
import { resetEventState, setEventData } from "../../redux/Slices/eventSlice";
import AddButton from "../Common/AddButton";
import FormBtn from "../Common/FormBtn";
import FormInput from "../Common/FormInput";
import { Label } from "../Common/Label";
import { RequiredMark } from "../Common/RequiredMark";
import StyledHelperText from "../Common/StyledHelperText";
import StyledTextArea from "../Common/StyledTextArea";
import { useTheme } from "@emotion/react";
export const CreateEventDialog = ({
  openCreateEventDialog,
  handleClose,
  onOpenSnackbar,
}) => {
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.event);
  const [eventPostingError, setEventPostingError] = useState("");
  const [eventPostingStatus, setEventPostingStatus] = useState();
  const userId = useSelector((state) => state.user.userId);
  const eventListenerData = useSelector((state) => state.eventListener);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    control,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: formData,
  });

  const handleEventPostSuccess = () => {
    setEventPostingStatus(POST_STATUS.SAVED);
    dispatch(resetEventState());
    dispatch(
      setActionData({
        ...eventListenerData,
        eventActionId: `event_add_${dayjs()}`,
      })
    );
    onOpenSnackbar("Event created successfully!", "success");
    handleClose();
  };

  const handleEventPostFailure = () => {
    setEventPostingError("Error while creating event.");
    setEventPostingStatus(POST_STATUS.FAILED);
    onOpenSnackbar("Error while creating event.", "error");
    handleClose();
  };

  const onSubmit = async () => {
    try {
      setEventPostingError("");
      const eventPostData = {
        ...formData,
        event_status: EVENT_STATUS.IN_REVIEW,
        created_by: userId,
        amount: formData.event_type === EVENT_TYPE.PAID ? formData.amount : 0,
      };
      setEventPostingStatus(POST_STATUS.POSTING);
      const response = await createEvent(eventPostData);
      if (response.status === 201) {
        handleEventPostSuccess();
      } else {
        handleEventPostFailure();
      }
    } catch (error) {
      console.log(error.message);
      handleEventPostFailure();
    }
  };

  const handleInputChange = async (name, value) => {
    dispatch(setEventData({ ...formData, [name]: value }));
    await trigger(name);
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={openCreateEventDialog}
      onClose={handleClose}
      aria-labelledby="Create event"
      aria-describedby="Please fill in the details below to post a new event"
    >
      <CardContainer
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <StyledDialogTitle>Create event</StyledDialogTitle>
            <CloseOutlinedIcon
              sx={{ color: "#333333", padding: "8px", cursor: "pointer" }}
              onClick={handleClose}
            />
          </Box>
        </DialogTitle>
        <DialogContent>
          {/* <Box
            sx={{
              padding: "24px",
              border: "1px solid #E4E4E7",
              borderRadius: "4px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "16px",
              }}
            >
              <img src="/assets/images/upload-cloud.png" alt="upload" />
              <Box>
                <Typography
                  sx={{ fontSize: 14, fontWeight: 500, color: "#333333" }}
                >
                  Upload your files
                </Typography>
                <Typography
                  sx={{ fontSize: 12, fontWeight: 400, color: "#52525B" }}
                >
                  Max. 5MB | JPG, PNG, PDF
                </Typography>
              </Box>
              <AddButton isNormalBtn={true} style={{ padding: "4px 8px" }}>
                Browse
              </AddButton>
            </Box>
          </Box> */}
          <Controller
            name="event_name"
            control={control}
            rules={{
              required: "Event name is required.",
              minLength: {
                value: 5,
                message: "Event name must be at least 5 characters long.",
              },
            }}
            render={({ field }) => (
              <FormInput
                label="Event Name"
                {...field}
                value={formData.event_name}
                required
                onChange={(e) => {
                  field.onChange(e);
                  handleInputChange("event_name", e.target.value);
                }}
                error={!!errors.event_name}
                helperText={errors.event_name ? errors.event_name.message : ""}
              />
            )}
          />
          <Grid container spacing={isSmallScreen ? 0 : 2}>
            <Grid item xs={12} sm={12} md={6}>
              <Controller
                name="event_start_datetime"
                control={control}
                rules={{
                  required: "Event start date and time are required.",
                }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <PickerContainer>
                    <Label error={errors.event_start_datetime}>
                      <RequiredMark /> Event start date & time
                    </Label>
                    <br />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <StyledDateTimePicker
                        slotProps={{ textField: { fullWidth: true } }}
                        value={
                          formData.event_start_datetime
                            ? dayjs(formData.event_start_datetime)
                            : null
                        }
                        onChange={(newValue) => {
                          onChange(newValue);
                          handleInputChange("event_start_datetime", newValue);
                        }}
                        minDate={dayjs()}
                        renderInput={(props) => (
                          <TextField
                            {...props}
                            fullWidth
                            error={!!errors.event_start_datetime}
                            helperText={
                              errors.event_start_datetime
                                ? errors.event_start_datetime.message
                                : ""
                            }
                          />
                        )}
                        inputRef={ref}
                      />
                    </LocalizationProvider>
                    <StyledHelperText error={errors.event_start_datetime}>
                      {errors?.event_start_datetime?.message}
                    </StyledHelperText>
                  </PickerContainer>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Controller
                name="event_end_datetime"
                control={control}
                rules={{
                  required: "Event end date and time are required.",
                  validate: (value) => {
                    if (!value) return "Event end date and time are required.";
                    if (
                      dayjs(value).isBefore(
                        dayjs(formData.event_start_datetime)
                      )
                    ) {
                      return "Event end date and time must be greater than start date and time.";
                    }
                    return true;
                  },
                }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <PickerContainer>
                    <Label error={errors.event_end_datetime}>
                      <RequiredMark /> Event end date & time
                    </Label>
                    <br />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <StyledDateTimePicker
                        slotProps={{ textField: { fullWidth: true } }}
                        value={
                          formData.event_end_datetime
                            ? dayjs(formData.event_end_datetime)
                            : null
                        }
                        onChange={(newValue) => {
                          onChange(newValue);
                          handleInputChange("event_end_datetime", newValue);
                        }}
                        minDate={dayjs()}
                        renderInput={(props) => (
                          <TextField
                            {...props}
                            fullWidth
                            error={!!errors.event_end_datetime}
                            helperText={
                              errors.event_end_datetime
                                ? errors.event_end_datetime.message
                                : ""
                            }
                          />
                        )}
                        inputRef={ref}
                      />
                    </LocalizationProvider>
                    <StyledHelperText error={errors.event_end_datetime}>
                      {errors?.event_end_datetime?.message}
                    </StyledHelperText>
                  </PickerContainer>
                )}
              />
            </Grid>
          </Grid>
          <Box mt={1}>
            <Grid container spacing={isSmallScreen ? 0 : 2}>
              <Grid item xs={12} sm={6} mt={1}>
                <Controller
                  name="event_type"
                  control={control}
                  rules={{ required: "Event type is required." }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Box>
                      <Label>
                        <RequiredMark />
                        Event Type
                      </Label>
                      <StyledRadioGroup
                        value={formData.event_type}
                        onChange={(e) => {
                          const selectedValue = e.target.value;
                          onChange(e); // Update the field value in react-hook-form
                          handleInputChange("event_type", selectedValue); // Update formData state
                        }}
                        onBlur={onBlur}
                        ref={ref}
                      >
                        <Box sx={{ padding: 0 }}>
                          <FormControlLabel
                            value={EVENT_TYPE.FREE}
                            control={
                              <Radio sx={{ py: 0, marginLeft: "4px" }} />
                            }
                            label={<StyledRadioLabel>Free</StyledRadioLabel>}
                          />
                        </Box>
                        <StyledRadioBox>
                          <FormControlLabel
                            value={EVENT_TYPE.PAID}
                            control={<Radio sx={{ py: 0 }} />}
                            label={<StyledRadioLabel>Paid</StyledRadioLabel>}
                          />
                        </StyledRadioBox>
                      </StyledRadioGroup>
                      {errors.event_type && (
                        <Box color="red" mt={1}>
                          {errors.event_type.message}
                        </Box>
                      )}
                    </Box>
                  )}
                />
              </Grid>
              {formData.event_type === EVENT_TYPE.PAID && (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{ marginTop: isSmallScreen ? 1 : 0 }}
                >
                  <Controller
                    name="amount"
                    control={control}
                    rules={{
                      required: "Amount is required.",
                      validate: (value) => {
                        return (
                          Number(value) >= 5 ||
                          "Amount must be greater than or equal €5."
                        );
                      },
                    }}
                    render={({ field }) => (
                      <FormInput
                        label="Amount in €"
                        {...field}
                        value={formData.amount}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (/^\d*\.?\d*$/.test(inputValue)) {
                            field.onChange(inputValue);
                            handleInputChange("amount", Number(inputValue));
                          }
                        }}
                        error={!!errors.amount}
                        helperText={errors.amount ? errors.amount.message : ""}
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
          </Box>

          <Box mt={1}>
            <Controller
              name="event_mode"
              control={control}
              rules={{ required: "Event mode is required." }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Box>
                  <Label>
                    <RequiredMark />
                    Event Mode
                  </Label>
                  <StyledRadioGroup
                    value={formData.event_mode}
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      onChange(e); // Update the field value in react-hook-form
                      handleInputChange("event_mode", selectedValue); // Update formData state
                    }}
                    onBlur={onBlur}
                    ref={ref}
                  >
                    <Box sx={{ padding: 0 }}>
                      <FormControlLabel
                        value={EVENT_MODE.ONLINE}
                        control={<Radio sx={{ py: 0, marginLeft: "4px" }} />}
                        label={<StyledRadioLabel>Online</StyledRadioLabel>}
                      />
                    </Box>
                    <StyledRadioBox>
                      <FormControlLabel
                        value={EVENT_MODE.OFFLINE}
                        control={<Radio sx={{ py: 0 }} />}
                        label={<StyledRadioLabel>Offline</StyledRadioLabel>}
                      />
                    </StyledRadioBox>
                  </StyledRadioGroup>
                  {errors.event_mode && (
                    <Box color="red" mt={1}>
                      {errors.event_mode.message}
                    </Box>
                  )}
                </Box>
              )}
            />
          </Box>
          <Box mt={1}>
            {formData.event_mode === EVENT_MODE.ONLINE ? (
              <Controller
                name="event_link"
                control={control}
                rules={{
                  required: "Meeting link is required.",
                  pattern: {
                    value: /^(ftp|http|https):\/\/[^ "]+$/,
                    message: "Please enter a valid URL.",
                  },
                }}
                render={({ field }) => (
                  <FormInput
                    label="Meeting link"
                    {...field}
                    value={formData.event_link}
                    required
                    onChange={(e) => {
                      field.onChange(e);
                      handleInputChange("event_link", e.target.value);
                    }}
                    error={!!errors.event_link}
                    helperText={
                      errors.event_link ? errors.event_link.message : ""
                    }
                  />
                )}
              />
            ) : (
              <Controller
                name="location"
                control={control}
                rules={{
                  required: "Meeting location is required.",
                  minLength: {
                    value: 5,
                    message: "Location must be at least 5 characters long.",
                  },
                }}
                render={({ field }) => (
                  <FormInput
                    label="Location"
                    {...field}
                    value={formData.location}
                    onChange={(e) => {
                      field.onChange(e);
                      handleInputChange("location", e.target.value);
                    }}
                    required
                    error={!!errors.location}
                    helperText={errors.location ? errors.location.message : ""}
                  />
                )}
              />
            )}
          </Box>

          <Box mt={1}>
            <Controller
              name="description"
              control={control}
              rules={{
                required: "Description is required.",
                minLength: {
                  value: 50,
                  message: "Description must be between 50-500 characters long",
                },
                maxLength: {
                  value: 500,
                  message: "Description must be between 50-500 characters long",
                },
              }}
              render={({ field }) => (
                <>
                  <Label error={errors.description}>
                    <RequiredMark />
                    Event description
                  </Label>
                  <StyledTextArea
                    id="description"
                    value={formData.description}
                    name="description"
                    rows="5"
                    placeholder="Short event description"
                    required
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                      handleInputChange("description", e.target.value);
                    }}
                  />
                  <StyledHelperText error={errors.description}>
                    {errors?.description?.message}
                  </StyledHelperText>
                </>
              )}
            />
          </Box>
        </DialogContent>
        <DialogActionsBox>
          {isMobile ? (
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6}>
                <AddButton
                  isNormalBtn={true}
                  isFullWidth={true}
                  style={{ padding: "12px" }}
                  onClick={handleClose}
                >
                  Cancel
                </AddButton>
              </Grid>
              <Grid item xs={6} sm={6}>
                <FormBtn
                  type="submit"
                  disabled={eventPostingStatus === POST_STATUS.POSTING}
                  loading={eventPostingStatus === POST_STATUS.POSTING}
                >
                  Create event
                </FormBtn>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6}></Grid>
              <Grid item xs={12} sm={12} md={3}>
                <AddButton
                  isNormalBtn={true}
                  isFullWidth={true}
                  style={{ padding: "12px" }}
                  onClick={handleClose}
                >
                  Cancel
                </AddButton>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <FormBtn
                  type="submit"
                  disabled={eventPostingStatus === POST_STATUS.POSTING}
                  loading={eventPostingStatus === POST_STATUS.POSTING}
                >
                  Create event
                </FormBtn>
              </Grid>
            </Grid>
          )}
        </DialogActionsBox>
      </CardContainer>
    </Dialog>
  );
};

const StyledDialogTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  font-size: 20px;
  font-weight: 500;
`;

const CardContainer = styled(Box)`
  padding: 16px;
  background: white;
  border-radius: 8px;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding: 0px;
  }
`;
const StyledRadioGroup = styled(RadioGroup)`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
`;

const StyledRadioBox = styled(Box)`
  display: flex;
  padding: 0;
`;

const StyledRadioLabel = styled("span")`
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  font-size: 14px;
  font-weight: 400;
  wordwrap: break-word;
`;

const StyledDateTimePicker = styled(DateTimePicker)({
  "& .MuiOutlinedInput-root": {
    fontSize: "14px", // Reduce font size
    width: "100%",
  },
  "& .MuiOutlinedInput-input": {
    fontSize: "14px", // Reduce font size
    padding: "12px 10px", // Adjust padding
  },
  "& .MuiInputAdornment-root": {
    margin: "8px", // Adjust icon margin if needed
  },
});

const PickerContainer = styled(Box)({
  marginTop: "8px",
});

const DialogActionsBox = styled(DialogActions)`
  margin: 0px 16px;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin: 0px 16px 16px 16px;
  }
`;

import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import {
  Alert,
  Box,
  Grid,
  Pagination,
  Snackbar,
  Tabs,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { getProfileRequests } from "../../api/Admin/admin";
import { ProfileActivityItem } from "../../component/Admin/ProfileActivityItem";
import { ProfileDetailsDialog } from "../../component/Admin/ProfileDetailsDialog";
import ProfileRequestTable from "../../component/Admin/ProfileRequestTable";
import ProfileRequestTableSkeleton from "../../component/Admin/ProfileRequestTableSkeleton";
import CustomTab from "../../component/Common/CustomTab";
import SectionTitle from "../../component/Common/SectionTitle";
import { GET_STATUS, USER_PROFILE_STATUS } from "../../constants/constants";
import { resetUserProfileState } from "../../redux/Slices/userProfileSlice";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const ManageRequests = () => {
  const [profileReqFetchingError, setProfileReqFetchingError] = useState("");
  const [profileReqFetchingStatus, setProfileReqFetchingStatus] = useState();
  const [selectedTab, setSelectedTab] = useState(0);
  const [profileRequestStatus, setProfileRequestStatus] = useState(
    USER_PROFILE_STATUS.IN_REVIEW
  );
  const [profileRequests, setProfileRequests] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Add page state
  const [totalPages, setTotalPages] = useState(1); // Track total pages
  const [openUserProfileDialog, setOpenUserProfileDialog] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const profileRequestActionId = useSelector(
    (state) => state.eventListener.profileRequestActionId
  );

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    switch (newValue) {
      case 0:
        setProfileRequestStatus(USER_PROFILE_STATUS.IN_REVIEW);
        break;
      case 1:
        setProfileRequestStatus(USER_PROFILE_STATUS.ACTIVE);
        break;
      case 2:
        setProfileRequestStatus(USER_PROFILE_STATUS.REJECTED);
        break;
      case 3:
        setProfileRequestStatus(USER_PROFILE_STATUS.ON_HOLD);
        break;
      default:
        setProfileRequestStatus(USER_PROFILE_STATUS.IN_REVIEW);
    }
  };

  const handleProfileFetchFailure = () => {
    setProfileReqFetchingError("Error while fetching posts.");
    setProfileReqFetchingStatus(GET_STATUS.FAILED);
  };

  const fetchProfileRequests = async (tabIndex, page = 1) => {
    try {
      setProfileReqFetchingError("");
      setProfileReqFetchingStatus(GET_STATUS.FETCHING);
      let status;
      switch (tabIndex) {
        case 0:
          status = "IN_REVIEW";
          break;
        case 1:
          status = "ACTIVE";
          break;
        case 2:
          status = "REJECTED";
          break;
        case 3:
          status = "ON_HOLD";
          break;
        default:
          status = "IN_REVIEW";
      }

      const response = await getProfileRequests(status, page);
      if (response.status === 200) {
        const { users, totalPages: apiTotalPages } = response.data.data;
        setProfileRequests(users);
        setTotalPages(apiTotalPages);
        setProfileReqFetchingStatus(GET_STATUS.FETCHED);
      } else {
        handleProfileFetchFailure();
      }
    } catch (error) {
      handleProfileFetchFailure();
    }
  };

  const handleOpenUserProfileDialog = () => {
    setOpenUserProfileDialog(true);
  };

  const handleCloseUserProfileDialog = () => {
    dispatch(resetUserProfileState());
    setOpenUserProfileDialog(false);
  };

  const handleOpenSnackbar = (message, severity = "success") => {
    setSnackbar({
      open: true,
      message,
      severity,
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  useEffect(() => {
    fetchProfileRequests(selectedTab, currentPage); // Pass current page
  }, [selectedTab, currentPage, profileRequestActionId]);

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <SectionTitle>New profile requests</SectionTitle>

          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              aria-label="basic tabs example"
              variant={isMobile ? "scrollable" : "standard"}
              scrollButtons={isMobile ? "auto" : false}
              allowScrollButtonsMobile
            >
              <CustomTab label="General" {...a11yProps(0)} />
              <CustomTab label="Accepted" {...a11yProps(1)} />
              <CustomTab label="Declined" {...a11yProps(2)} />
              <CustomTab label="On hold" {...a11yProps(3)} />
            </Tabs>
          </Box>

          {profileReqFetchingStatus === GET_STATUS.FETCHING ? (
            <ProfileRequestTableSkeleton />
          ) : profileReqFetchingStatus === GET_STATUS.FAILED ? (
            <Box mt={2}>
              <Alert severity="error">
                Error fetching profile requests. Please try again later.
              </Alert>
            </Box>
          ) : profileRequests.length === 0 ? (
            <Box mt={2}>
              <Alert severity="info">No profile requests found.</Alert>
            </Box>
          ) : (
            <>
              <ProfileRequestTable
                handleOpenUserProfileDialog={handleOpenUserProfileDialog}
                handleCloseUserProfileDialog={handleCloseUserProfileDialog}
                status={profileRequestStatus}
                users={profileRequests}
                onOpenSnackbar={handleOpenSnackbar}
              />

              <Box sx={{ mt: 2 }}>
                <StyledPagination
                  count={totalPages} // Total pages from API
                  page={currentPage} // Current page
                  onChange={(e, page) => setCurrentPage(page)} // Handle page change
                  variant="outlined"
                  shape="rounded"
                />
              </Box>
            </>
          )}

          <Box sx={{ mt: 2 }}>
            <SectionTitle>Report requests</SectionTitle>
          </Box>
        </Grid>

        <Grid item xs={12} md={4}>
          <StyledActivityContainer>
            <ProfileActivityItem type="user" />
            <StyledHr />
            <ProfileActivityItem type="post" />
            <StyledHr />

            <ProfileActivityItem type="job" />
          </StyledActivityContainer>
        </Grid>
      </Grid>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      <ProfileDetailsDialog
        onOpenSnackbar={handleOpenSnackbar}
        openProfileDetailsDialog={openUserProfileDialog}
        handleClose={handleCloseUserProfileDialog}
        handleCloseUserProfileDialog={handleCloseUserProfileDialog}
      />
    </Box>
  );
};

const StyledActivityContainer = styled(Box)`
  border: 1px solid ${({ theme }) => theme.palette.border.neutral[200]};
  border-radius: 4px;
  margin-top: 16px;
`;

const StyledHr = styled.hr`
  margin: 4px 12px;
  border: none;
  border-top: 1px solid ${({ theme }) => theme.palette.border.neutral[200]};
  height: 1px;
  color: ${({ theme }) => theme.palette.border.neutral[200]};
`;

const StyledPagination = styled(Pagination)`
  margin-top: 8px; // Adjusts the margin at the top

  .MuiPaginationItem-root {
    color: #400091; // Text color for pagination items
    border-color: #e4e4e7; // Border color for pagination items
  }

  .Mui-selected {
    border-color: #400091 !important;
    color: #400091;
    background-color: white !important;
  }

  .MuiPaginationItem-ellipsis {
    border: none; // No border for ellipsis items
  }
`;

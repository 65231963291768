import styled from "@emotion/styled";
import React from "react";

import {
  Box,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";

const ProfileRequestTableSkeleton = () => {
  return (
    <Box mt={2}>
      <StyledTableContainer>
        <Table>
          <TableBody>
            <StyledTableRow>
              <TableCell sx={{ flexGrow: 1, width: "100%" }}>
                <Skeleton variant="rounded" width={150} height={10} />
              </TableCell>

              <TableCell sx={{ whiteSpace: "nowrap", paddingX: 2 }}>
                {" "}
                <Skeleton variant="rounded" width={70} height={10} />
              </TableCell>
              <TableCell sx={{ whiteSpace: "nowrap", paddingX: 2 }}>
                {" "}
                <Skeleton variant="rounded" width={70} height={10} />
              </TableCell>
              <TableCell sx={{ whiteSpace: "nowrap", paddingX: 2 }}>
                <Skeleton variant="rounded" width={70} height={10} />
              </TableCell>
              <TableCell sx={{ whiteSpace: "nowrap", paddingX: 2 }}>
                <Skeleton variant="rounded" width={70} height={10} />
              </TableCell>
            </StyledTableRow>
            <StyledTableRow>
              <TableCell sx={{ flexGrow: 1, width: "100%" }}>
                <Skeleton variant="rounded" width={150} height={10} />
              </TableCell>

              <TableCell sx={{ whiteSpace: "nowrap", paddingX: 2 }}>
                {" "}
                <Skeleton variant="rounded" width={70} height={10} />
              </TableCell>
              <TableCell sx={{ whiteSpace: "nowrap", paddingX: 2 }}>
                {" "}
                <Skeleton variant="rounded" width={70} height={10} />
              </TableCell>
              <TableCell sx={{ whiteSpace: "nowrap", paddingX: 2 }}>
                <Skeleton variant="rounded" width={70} height={10} />
              </TableCell>
              <TableCell sx={{ whiteSpace: "nowrap", paddingX: 2 }}>
                <Skeleton variant="rounded" width={70} height={10} />
              </TableCell>
            </StyledTableRow>
            <StyledTableRow>
              <TableCell sx={{ flexGrow: 1, width: "100%" }}>
                <Skeleton variant="rounded" width={150} height={10} />
              </TableCell>

              <TableCell sx={{ whiteSpace: "nowrap", paddingX: 2 }}>
                {" "}
                <Skeleton variant="rounded" width={70} height={10} />
              </TableCell>
              <TableCell sx={{ whiteSpace: "nowrap", paddingX: 2 }}>
                {" "}
                <Skeleton variant="rounded" width={70} height={10} />
              </TableCell>
              <TableCell sx={{ whiteSpace: "nowrap", paddingX: 2 }}>
                <Skeleton variant="rounded" width={70} height={10} />
              </TableCell>
              <TableCell sx={{ whiteSpace: "nowrap", paddingX: 2 }}>
                <Skeleton variant="rounded" width={70} height={10} />
              </TableCell>
            </StyledTableRow>
            <StyledTableRow>
              <TableCell sx={{ flexGrow: 1, width: "100%" }}>
                <Skeleton variant="rounded" width={150} height={10} />
              </TableCell>

              <TableCell sx={{ whiteSpace: "nowrap", paddingX: 2 }}>
                {" "}
                <Skeleton variant="rounded" width={70} height={10} />
              </TableCell>
              <TableCell sx={{ whiteSpace: "nowrap", paddingX: 2 }}>
                {" "}
                <Skeleton variant="rounded" width={70} height={10} />
              </TableCell>
              <TableCell sx={{ whiteSpace: "nowrap", paddingX: 2 }}>
                <Skeleton variant="rounded" width={70} height={10} />
              </TableCell>
              <TableCell sx={{ whiteSpace: "nowrap", paddingX: 2 }}>
                <Skeleton variant="rounded" width={70} height={10} />
              </TableCell>
            </StyledTableRow>
            <StyledTableRow>
              <TableCell sx={{ flexGrow: 1, width: "100%" }}>
                <Skeleton variant="rounded" width={150} height={10} />
              </TableCell>

              <TableCell sx={{ whiteSpace: "nowrap", paddingX: 2 }}>
                {" "}
                <Skeleton variant="rounded" width={70} height={10} />
              </TableCell>
              <TableCell sx={{ whiteSpace: "nowrap", paddingX: 2 }}>
                {" "}
                <Skeleton variant="rounded" width={70} height={10} />
              </TableCell>
              <TableCell sx={{ whiteSpace: "nowrap", paddingX: 2 }}>
                <Skeleton variant="rounded" width={70} height={10} />
              </TableCell>
              <TableCell sx={{ whiteSpace: "nowrap", paddingX: 2 }}>
                <Skeleton variant="rounded" width={70} height={10} />
              </TableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </StyledTableContainer>
    </Box>
  );
};

export default ProfileRequestTableSkeleton;

const StyledTableContainer = styled(TableContainer)`
  border: 1px solid ${({ theme }) => theme.palette.border.neutral[200]};
  padding: 0; // Remove any padding from the TableContainer
  margin: 0; // Remove any margin
  border-radius: 4px;
`;

const StyledTableRow = styled(TableRow)`
  &:not(:last-child) {
    border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
  }
`;

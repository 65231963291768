// src/theme/theme.js

import { createTheme } from '@mui/material';

const theme = createTheme({
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "*": {
          boxSizing: "border-box",
        },
      },
    },
  },
  typography: {
    fontFamily: 'Inter, Arial, sans-serif',
  },
  palette: {
    primary: {
      main: '#5F00D9',
      600: '#5F00D9',
      200: '#DDC2FF',
    },
    secondary: {
      main: '#dc004e',
    },
    common: {
      white: '#ffffff',
      crimson: '#DC2626',
      purple: '#400091',
    },
    text: {
      primary: '#27272A',
      secondary: '#A1A1AA',
      success: '#4BB543',
      red: '#B46E1A',
      warning: {
        800: '#92400E',
      },
      positive: {
        800: '#065F46'
      },
      negative: {
        600: '#DC2626',
        800: '#991B1B'
      },
      neutral: {
        400: '#52525B',
        500: '#71717A',
        600: '#52525B',
        700: '#333333',
        800: '#27272A',
      },
      prim: {
        800: '#320073',
      },
    },
    border: {
      neutral: {
        200: "#E4E4E7",
        400: "#A1A1AA",
        700: "#333333",
      },
    },
    background: {
      primary: {
        100: "#F4F4F5",
        200: "#DDC2FF",
      },
      neutral: {
        100: "#F4F4F5",
        500: "#71717A",
        700: "#333333",
      },
      positive: {
        100: "#D1FAE5"
      },
      warning: {
        100: '#FEF3C7'
      },
      negative: {
        100: '#FEE2E2',
        600: '#DC2626'
      },
      info: {
        100: '#F7EECA'
      }

    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        backgroundColor: "#ffffff",
        borderRadius: 8,
        "& .MuiInputLabel-root": {
          color: "#6D6D6D", // Change this to the desired label color
        },
        "& .MuiOutlinedInput-root": {
          borderColor: "#A1A1AA", // Default border color
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#A1A1AA", // Default border color
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#A1A1AA", // Border color on hover
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#A1A1AA", // Border color on focus
          },
          "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
            borderColor: "#A1A1AA", // Border color on focus
          },
          "&.Mui-disabled .MuiInputLabel-root": {
            color: "red", // Change this to the desired label color
          },
        },
      },
    },
  },
});

export default theme;

import styled from "@emotion/styled";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import AddButton from "../Common/AddButton";
import FormBtn from "../Common/FormBtn";
import { Label } from "../Common/Label";
import { RequiredMark } from "../Common/RequiredMark";
import { useTheme } from "@emotion/react";
import StyledTextArea from "../Common/StyledTextArea";
import { POST_STATUS, USER_PROFILE_STATUS } from "../../constants/constants";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { setActionData } from "../../redux/Slices/eventListnerSlice";
import { updateProfileStatus } from "../../api/Admin/admin";
import { Controller, useForm } from "react-hook-form";
import StyledHelperText from "../Common/StyledHelperText";

export const DeleteProfileDialog = ({
  openDeleteProfileDialog,
  handleCloseUserProfileDialog,
  handleClose,
  onOpenSnackbar,
  type = "remove",
  userId,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [profileUpdateStatus, setProfileUpdateStatus] = useState();
  const eventListenerData = useSelector((state) => state.eventListener);
  const status =
    type === "remove"
      ? USER_PROFILE_STATUS.REJECTED
      : USER_PROFILE_STATUS.ON_HOLD;

  const {
    control,
    handleSubmit,
    trigger,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: { reason: "" },
  });

  const handleProfileUpdateFailure = () => {
    onOpenSnackbar("Error while updating profile status.", "error");
    handleClose();
    setProfileUpdateStatus(POST_STATUS.FAILED);
    handleCloseUserProfileDialog();
  };

  const handleProfileUpdateSuccess = (user_id) => {
    setProfileUpdateStatus(POST_STATUS.SAVED);
    dispatch(
      setActionData({
        ...eventListenerData,
        profileRequestActionId: `profile_status_${user_id}_${dayjs()}`,
      })
    );
    handleCloseUserProfileDialog();
    handleClose();
    onOpenSnackbar("Profile status updated successfully!", "success");
  };

  const onSubmit = async (data) => {
    const { reason } = data; // Only reason comes from the form
    try {
      setProfileUpdateStatus(POST_STATUS.POSTING);
      const response = await updateProfileStatus({
        user_id: userId,
        status,
        reason,
      });
      if (response.status === 200) {
        handleProfileUpdateSuccess();
      } else {
        handleProfileUpdateFailure();
      }
    } catch (error) {
      handleProfileUpdateFailure();
    }
  };

  const handleInputChange = async (value) => {
    setValue("reason", value);
    await trigger("reason");
  };

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={openDeleteProfileDialog}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <CardContainer
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <StyledDialogTitle>
              {type === "remove"
                ? "Remove profile: Are your sure ?"
                : "Profile on hold: Are your sure ?"}
            </StyledDialogTitle>
            <CloseOutlinedIcon
              sx={{ color: "#333333", padding: "8px", cursor: "pointer" }}
              onClick={handleClose}
            />
          </Box>
        </DialogTitle>
        <DialogContent sx={{ justifyContent: "center" }}>
          <StyledDialogSubTitle>
            {type === "remove"
              ? "This profile will not be added to the platform."
              : "This profile will not be added until you approve it"}
          </StyledDialogSubTitle>

          <Box mt={2}>
            <Label>
              <RequiredMark />

              {type === "remove" ? `Reason for rejection` : `Reason for hold`}
            </Label>
            <Controller
              name="reason"
              control={control}
              rules={{
                required: "Reason is required.",
                minLength: {
                  value: 50,
                  message: "Reason must be between 25-500 characters long",
                },
                maxLength: {
                  value: 500,
                  message: "Reason must be between 25-500 characters long",
                },
              }}
              render={({ field }) => (
                <>
                  <StyledTextArea
                    id="reason"
                    name="reason"
                    value={field.value}
                    rows="5"
                    {...field}
                    onChange={(e) => {
                      field.onChange(e); // React Hook Form handler
                      handleInputChange(e.target.value); // Local state update for manual triggers
                    }}
                  />
                  <StyledHelperText error={errors.reason}>
                    {errors?.reason?.message}
                  </StyledHelperText>
                </>
              )}
            />
          </Box>
          {/* Centering the action buttons */}
          <Box
            sx={{ display: "flex", gap: 2, mt: 2, justifyContent: "center" }}
          >
            <AddButton
              isNormalBtn={true}
              isFullWidth={true}
              style={{
                padding: "8px 16px",
                width: "auto",
                color: theme.palette.text.negative[600],
              }}
            >
              Cancel
            </AddButton>
            <FormBtn
              disabled={profileUpdateStatus === POST_STATUS.POSTING}
              sx={{
                background: theme.palette.background.negative[600],
                width: "auto",
                padding: "8px 16px",
                "&:hover": {
                  background: theme.palette.background.negative[600], // Ensures background stays the same
                },
              }}
              type="submit"
            >
              {type === "remove" ? `Reject profile` : `On hold`}
            </FormBtn>
          </Box>
        </DialogContent>
      </CardContainer>
    </Dialog>
  );
};

const StyledDialogTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  font-size: 20px;
  font-weight: 500;
`;

const StyledDialogSubTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[600]};
  font-size: 14px;
  font-weight: 500;
`;

const CardContainer = styled(Box)`
  padding: 16px;
  background: white;
  border-radius: 8px;
`;

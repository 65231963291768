import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { EVENT_MODE, POST_STATUS } from "../../constants/constants";
import {
  addEventBookmark,
  removeEventBookmark,
} from "../../redux/Slices/userSlice";
import { formatEventDateTime, truncateText } from "../../utils/helpers";
import { Tag } from "../Common/Tag";
import { toggleBookmarkEvent } from "../../api/Event/event";
import InverseBtn from "../Common/InverseBtn";

export const EventCard = ({ image, event }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const eventId = event?.event_id;
  const isFree = event?.event_type === "FREE";
  const bookmarks = useSelector((state) => state.user.eventBookmarks);
  const [bookmarkStatus, setBookmarkStaus] = useState("");
  const isEventBookMarked = bookmarks?.includes(eventId);
  const navigate = useNavigate();
  const userId = useSelector((state) => state.user.userId);

  const toggleBookmark = () => {
    if (isEventBookMarked) {
      dispatch(removeEventBookmark(eventId));
      postBookmarkEvent("delete");
    } else {
      dispatch(addEventBookmark(eventId));
      postBookmarkEvent("create");
    }
  };

  const handleBookmarkFailure = () => {
    setBookmarkStaus(POST_STATUS.FAILED);
  };

  const postBookmarkEvent = async (action) => {
    try {
      const data = {
        event_id: eventId,
        user_id: userId,
      };
      setBookmarkStaus(POST_STATUS.POSTING);
      const response = await toggleBookmarkEvent(data, action);
      if (response.status === 200) {
        setBookmarkStaus(POST_STATUS.SAVED);
      } else {
        handleBookmarkFailure();
      }
    } catch (error) {
      handleBookmarkFailure();
    }
  };

  const handleViewEvent = () => {
    navigate(`/events/${eventId}`, { state: { event } });
  };

  return (
    <CardContainer>
      <StyledImage src={image} />
      <Row>
        <Tag
          title={isFree ? "Free" : "Paid"}
          sx={{
            color: isFree
              ? theme.palette.text.positive[800]
              : theme.palette.text.warning[800],
            background: isFree
              ? theme.palette.background.positive[100]
              : theme.palette.background.warning[100],
          }}
        />
      </Row>

      <Row>
        <EventTime>
          {formatEventDateTime(
            event?.event_start_datetime,
            event?.event_end_datetime
          )}
        </EventTime>
        {isEventBookMarked ? (
          <BookmarkIcon
            onClick={toggleBookmark}
            sx={{ color: theme.palette.common.purple }}
          />
        ) : (
          <BookmarkBorderOutlinedIcon
            onClick={toggleBookmark}
            sx={{ color: "#71717A" }}
          />
        )}
      </Row>
      <EventTitle>{truncateText(event?.event_name, 50)}</EventTitle>
      <EventDescription>
        {truncateText(event?.description, 100)}
      </EventDescription>
      <Row>
        <EventDetails>
          {event?.event_mode === EVENT_MODE.OFFLINE ? (
            <DetailRow>
              <FmdGoodOutlinedIcon sx={{ fontSize: 18, color: "#333333" }} />
              <DetailText>{event?.location}</DetailText>
            </DetailRow>
          ) : (
            <DetailRow>
              <Link to={event?.event_link}>
                <img src="/assets/icons/link-external.svg" />
              </Link>{" "}
              <DetailText>Meeting link</DetailText>
            </DetailRow>
          )}

          <DetailRow mt={1}>
            <PersonOutlinedIcon sx={{ fontSize: 18, color: "#333333" }} />
            <DetailText>25 attending</DetailText>
          </DetailRow>
        </EventDetails>
        <InverseBtn
          onClick={handleViewEvent}
          sx={{ fontSize: "12px", fontWeight: 500 }}
        >
          View event
        </InverseBtn>
      </Row>
    </CardContainer>
  );
};

const CardContainer = styled(Box)`
  padding: 16px 12px;
  background: white;
  border-radius: 8px;
  margin-top: 16px;
  border: 1px solid ${({ theme }) => theme.palette.border.neutral[400]};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  max-height: 280px;
  border-radius: 8px;
  z-index: 0;
`;

const Row = styled(Box)`
  width: 100%;
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const EventTime = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.purple};
  font-size: 12px;
  font-weight: 500;
  word-wrap: break-word;
  text-transform: uppercase;
`;

const EventTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[800]};
  font-size: 16px;
  font-weight: 500;
  word-wrap: break-word;
  margin-top: 4px;
`;

const EventDescription = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[800]};
  font-size: 14px;
  font-weight: 400;
  word-wrap: break-word;
  margin-top: 8px;
`;

const EventDetails = styled(Box)`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
`;

const DetailRow = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DetailText = styled(Typography)`
  padding-left: 4px;
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  font-size: 12px;
  font-weight: 400;
  word-wrap: break-word;
`;

import { css } from "@emotion/react";
import styled from "@emotion/styled";
import CloseIcon from "@mui/icons-material/Close";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import {
  Alert,
  Avatar,
  Box,
  Button,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Slider,
  Snackbar,
  Typography,
  Container,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { GetCity, GetCountries, GetState } from "react-country-state-city";
import { Controller, useForm, useWatch } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CSCSelectInput from "../../component/Common/CSCSelectInput";
import FormBtn from "../../component/Common/FormBtn";
import FormInput from "../../component/Common/FormInput";
import { Label } from "../../component/Common/Label";
import { RequiredMark } from "../../component/Common/RequiredMark";
import SelectInput from "../../component/Common/SelectInput";
import {
  EMPLOYMENT_STATUS,
  EMPLOYMENT_STATUS_OPTIONS,
  EMPLOYMENT_TYPE_OPTIONS,
  POST_STATUS,
} from "../../constants/constants";
import {
  resetUserProfileState,
  setUserProfileData,
} from "../../redux/Slices/userProfileSlice";
import SlideUp from "../../component/Common/SlideUp";
import { setActionData } from "../../redux/Slices/eventListnerSlice";
import dayjs from "dayjs";
import { updateUserProfile } from "../../api/User/user";
import { waitForSeconds } from "../../utils/helpers";

export const EditProfile = () => {
  const [isChecked, setIsChecked] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userProfile = location?.state?.userProfile;
  const formData = useSelector((state) => state.userProfile);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [slideUpOpen, setSlideUpOpen] = useState(false);
  const [slideUpContent, setSlideUpContent] = useState("");
  const [profileEditError, setProfileEditError] = useState("");
  const [profileEditStatus, setProfileEditStatus] = useState();
  const eventListenerData = useSelector((state) => state.eventListener);

  const {
    control,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: formData,
  });

  const platform = useWatch({
    control,
    name: "platform",
  });

  const handleInputChange = async (name, value) => {
    dispatch(
      setUserProfileData({
        ...formData,
        [name]: value,
      })
    );
    await trigger(name);
  };

  const fetchCountries = () => {
    GetCountries()
      .then((result) => {
        const filteredCountries = result.filter(
          (country) => country.region === "Europe"
        );
        setCountries(filteredCountries);
        console.log(filteredCountries);
      })
      .catch((error) => {
        console.error("Error fetching countries:", error);
      });
  };

  const fetchStates = (countryId) => {
    GetState(countryId)
      .then((result) => {
        setStates(result);
      })
      .catch((error) => {
        console.error("Error fetching states:", error);
      });
  };

  const fetchCities = (countryId, stateId) => {
    GetCity(countryId, stateId)
      .then((result) => {
        setCities(result);
      })
      .catch((error) => {
        console.error("Error fetching cities:", error);
      });
  };

  const handleCountryChange = async (e) => {
    const countryId = e.target.value;

    // Use the `find` method instead of `forEach`
    const selectedCountry = countries.find(
      (country) => country.id == countryId
    );

    // If a country is found, proceed with the update
    const countryName = selectedCountry ? selectedCountry.name : "";
    console.log(countryName, countryId);
    // Dispatch to Redux with the updated country details
    dispatch(
      setUserProfileData({
        ...formData,
        country: countryName,
        country_id: countryId,
        state: "",
        city: "",
      })
    );

    // Fetch states and reset states and cities
    fetchStates(countryId);
    setStates([]);
    setCities([]);
  };

  const handleStateChange = (e) => {
    const stateId = e.target.value;

    // Use the `find` method to get the selected state
    const selectedState = states.find((state) => state.id == stateId);

    // Get the state name or default to an empty string if not found
    const stateName = selectedState ? selectedState.name : "";

    // Dispatch to Redux with updated state details
    dispatch(
      setUserProfileData({
        ...formData,
        state: stateName,
        state_id: stateId,
        city: "", // Reset city when state changes
      })
    );

    // Fetch cities based on the selected state
    fetchCities(formData.country_id, stateId);
  };

  const handleCityChange = (e) => {
    const cityId = e.target.value;

    const selectedCity = cities.find((city) => city.id == cityId);

    const cityName = selectedCity ? selectedCity.name : "";

    dispatch(
      setUserProfileData({
        ...formData,
        city: cityName,
        city_id: cityId,
      })
    );
  };

  const handleSlideUpOpen = (content) => {
    setSlideUpContent(content);
    setSlideUpOpen(true);
  };

  const handleSlideUpClose = () => {
    setSlideUpOpen(false);
    setSlideUpContent("");
  };
  const handleEdit = (content) => {
    handleSlideUpOpen(content);
  };

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  // Function to open Snackbar with custom message and severity
  const handleOpenSnackbar = (message, severity = "success") => {
    setSnackbar({
      open: true,
      message,
      severity,
    });
  };
  // Function to close Snackbar
  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleProfileEditSuccess = async () => {
    setProfileEditStatus(POST_STATUS.SAVED);
    dispatch(resetUserProfileState());
    dispatch(
      setActionData({
        ...eventListenerData,
        eventActionId: `profile_edit_${userProfile?.user_id}_${dayjs()}`,
      })
    );
    handleOpenSnackbar("Profile updated successfully!", "success");
    await waitForSeconds(2);
    navigate("/profile");
  };

  const handleProfileEditFailure = () => {
    setProfileEditError("Error while updating profile.");
    setProfileEditStatus(POST_STATUS.FAILED);
    handleOpenSnackbar("Error while updating profile.", "error");
  };

  const equalsCheck = (a, b) => {
    return JSON.stringify(a) === JSON.stringify(b);
  };

  const onSubmit = async (data) => {
    if (
      data.first_name === userProfile?.first_name &&
      data.last_name === userProfile?.last_name &&
      data.country === userProfile?.country &&
      data.state === userProfile?.state &&
      data.city === userProfile?.city &&
      data.country_id === userProfile?.country_id &&
      data.state_id === userProfile?.state_id &&
      data.city_id === userProfile?.city_id &&
      data.phone_number === userProfile?.phone_number &&
      data.platform === userProfile?.platform &&
      data.platform_url === userProfile?.platform_url &&
      data.referred_by === userProfile?.referred_by &&
      data.employment_status === userProfile?.employment_status &&
      data.current_job_title === userProfile?.current_job_title &&
      data.current_company === userProfile?.current_company &&
      data.min_salary === userProfile?.min_salary &&
      data.max_salary === userProfile?.max_salary &&
      data.employment_type === userProfile?.employment_type &&
      data.preferred_position === userProfile?.preferred_position &&
      equalsCheck(data.domains, userProfile.domains) &&
      equalsCheck(data.past_companies, userProfile.past_companies)
    ) {
      setProfileEditError("Please modify the job post before updating.");
      return;
    }
    try {
      setProfileEditError("");
      const profileStateData = { ...formData };
      profileStateData.min_salary = profileStateData.salary_range[0];
      profileStateData.max_salary = profileStateData.salary_range[1];
      delete profileStateData.salary_range;

      setProfileEditStatus(POST_STATUS.POSTING);
      const response = await updateUserProfile(
        userProfile?.user_id,
        profileStateData
      );

      if (response.status === 200) {
        handleProfileEditSuccess();
      } else {
        handleProfileEditFailure();
      }
    } catch (error) {
      handleProfileEditFailure();
    }
  };

  useEffect(() => {
    if (!countries.length) {
      fetchCountries();
    }
  }, []);
  useEffect(() => {
    if (formData.country_id) {
      fetchStates(formData.country_id);
    }
  }, [formData.country_id]);

  useEffect(() => {
    if (formData.country_id && formData.state_id) {
      fetchCities(formData.country_id, formData.state_id);
    }
  }, [formData.state_id]);

  useEffect(() => {
    if (formData.state_id && formData.city_id) {
      fetchCities(formData.country_id, formData.state_id);
    }
  }, []);

  return (
    <Container maxWidth="xl" sx={{ py: 3 }}>
      <Box sx={{ mt: 2 }}>
        <Typography
          sx={{ fontSize: "16px", fontWeight: 600, color: "#27272A" }}
        >
          Welcome Username!
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: 400,
            color: "#52525B",
            marginTop: "4px",
          }}
        >
          Manage your personal details with ease in this section
        </Typography>
      </Box>
      <UserContainer>
        <AvatarContainer>
          <Avatar
            sx={{ height: 64, width: 64 }}
            src="/assets/images/avatar-1.png"
          />
        </AvatarContainer>
        <UserDetails>
          <FormBtn sx={{ padding: "8px 0px" }}>Upload new photo</FormBtn>
          <UserRole>At least 800*800 px recommended</UserRole>
        </UserDetails>
      </UserContainer>
      <Box
        sx={{
          p: 2,
          border: "1px solid #A1A1AA",
          borderRadius: "8px",
          marginTop: "24px",
        }}
      >
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={{ color: "#52525B", fontSize: 20, fontWeight: 500 }}>
            Profile
          </Typography>
          <FormBtn type="submit" sx={{ width: "auto", padding: "8px 24px" }}>
            Edit
          </FormBtn>
        </Box>
        {profileEditError && (
          <Box mt={2}>
            <Alert severity="error">{profileEditError}</Alert>
          </Box>
        )}

        <Box p={2}>
          <StyledLable>
            {" "}
            <StyledRequiredMark component={"span"}>* </StyledRequiredMark>
            About me
          </StyledLable>
          <StyledDescription>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam
          </StyledDescription>
          <Grid container mt={1.5} spacing={1}>
            <Grid item xs={12} sm={12} md={6}>
              <Controller
                name="first_name"
                control={control}
                rules={{
                  required: "First name is required.",
                  minLength: {
                    value: 3,
                    message: "First name must be at least 3 characters long",
                  },
                }}
                render={({ field }) => (
                  <FormInput
                    label="First name"
                    id="first_name"
                    type="text"
                    value={formData.first_name}
                    placeholder="Enter your first name"
                    required
                    {...field}
                    error={!!errors.first_name}
                    helperText={
                      errors.first_name ? errors.first_name.message : ""
                    }
                    onChange={(e) => {
                      field.onChange(e);
                      handleInputChange("first_name", e.target.value);
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Controller
                name="last_name"
                control={control}
                rules={{
                  required: "Last name is required.",
                  minLength: {
                    value: 3,
                    message: "Last name must be at least 3 characters long",
                  },
                }}
                render={({ field }) => (
                  <FormInput
                    label="Last name"
                    id="last_name"
                    value={formData.last_name}
                    type="text"
                    placeholder="Enter your last name"
                    required
                    {...field}
                    error={!!errors.last_name}
                    helperText={
                      errors.last_name ? errors.last_name.message : ""
                    }
                    onChange={(e) => {
                      field.onChange(e);
                      handleInputChange("last_name", e.target.value);
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={6}>
              <Box sx={{ mt: 1 }}>
                <StyledFormLabel>
                  <RequiredMark />
                  Phone
                </StyledFormLabel>
                <Controller
                  name="phone_number"
                  control={control}
                  rules={{ required: "Phone number is required." }}
                  render={({ field }) => (
                    <PhoneInput
                      id="phone_number"
                      country={"in"}
                      value={formData.phone_number}
                      inputStyle={{
                        width: "100%",
                        fontSize: "14px",
                        borderRadius: "4px",
                        paddingTop: "12px",
                        paddingBottom: "12px",
                        border: "2px solid #A1A1AA",
                        outline: "none",
                      }}
                      buttonStyle={{ width: "0px" }}
                      inputProps={{
                        id: "phone_number",
                      }}
                      onChange={(value) => {
                        field.onChange(value);
                        handleInputChange("phone_number", value);
                      }}
                    />
                  )}
                />
                {errors.phone_number && (
                  <Typography variant="caption" color="error">
                    {errors.phone_number.message}
                  </Typography>
                )}
                {!errors.phone_number && (
                  <StyledPhoneInfoBox>
                    {!formData.is_phone_verified && (
                      <StyledInputInfo>
                        Verify your number to continue
                      </StyledInputInfo>
                    )}

                    {!formData.is_phone_verified ? (
                      <StyledVerifyLink
                      // onClick={onClickVerify}
                      >
                        Verify now
                      </StyledVerifyLink>
                    ) : (
                      <StyledVerifyLink isVerified={formData.is_phone_verified}>
                        Verified
                      </StyledVerifyLink>
                    )}
                  </StyledPhoneInfoBox>
                )}
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Controller
                name="country"
                control={control}
                rules={{
                  required: "Country is required.",
                }}
                render={({ field }) => (
                  <CSCSelectInput
                    label="Country"
                    id="country"
                    name="country"
                    options={countries.map((country) => ({
                      value: country.id,
                      label: country.name,
                    }))}
                    fullWidth={false}
                    required
                    error={!!errors.country}
                    helperText={errors.country ? errors.country.message : ""}
                    value={formData.country_id}
                    onChange={(e) => {
                      field.onChange(e);
                      handleCountryChange(e);
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={6}>
              <Controller
                name="state"
                control={control}
                rules={{
                  required: "State is required.",
                }}
                render={({ field }) => (
                  <CSCSelectInput
                    label="State"
                    id="state"
                    name="state"
                    disabled={!formData.country_id} // Disable until country is selected
                    options={states.map((state) => ({
                      value: state.id,
                      label: state.name,
                    }))}
                    fullWidth={false}
                    required
                    error={!!errors.state}
                    helperText={errors.state ? errors.state.message : ""}
                    value={formData.state_id}
                    onChange={(e) => {
                      field.onChange(e);
                      handleStateChange(e);
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Controller
                name="city"
                control={control}
                render={({ field }) => (
                  <CSCSelectInput
                    label="City"
                    id="city"
                    name="city"
                    disabled={!formData.state_id} // Disable until state is selected
                    options={cities.map((city) => ({
                      value: city.id,
                      label: city.name,
                    }))}
                    fullWidth={false}
                    error={!!errors.city}
                    helperText={errors.city ? errors.city.message : ""}
                    value={formData.city_id}
                    onChange={(e) => {
                      field.onChange(e);
                      handleCityChange(e);
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={6}>
              <Controller
                name="platform"
                control={control}
                render={({ field }) => (
                  <StyledRadioGroup
                    {...field}
                    value={formData.platform}
                    onChange={(e) => {
                      field.onChange(e);
                      handleInputChange("platform", e.target.value);
                    }}
                  >
                    <Box sx={{ flex: 1, padding: 0 }}>
                      <RequiredMark />
                      <FormControlLabel
                        value="LinkedIn"
                        control={<Radio sx={{ py: 0, marginLeft: "4px" }} />}
                        label={<StyledRadioLabel>LinkedIn</StyledRadioLabel>}
                      />
                    </Box>
                    <StyledRadioBox>
                      <FormControlLabel
                        value="Xing"
                        control={<Radio sx={{ py: 0 }} />}
                        label={<StyledRadioLabel>Xing</StyledRadioLabel>}
                      />
                    </StyledRadioBox>
                  </StyledRadioGroup>
                )}
              />
              <Controller
                name="platform_url"
                control={control}
                rules={{
                  required: `${formData.platform} url is required`,
                  pattern: {
                    value: new RegExp(
                      `^https:\/\/www\\.${platform?.toLowerCase()}.com\\/.*$`
                    ),
                    message: `Invalid ${platform} url `,
                  },
                }}
                render={({ field }) => (
                  <FormInput
                    id="platform_url"
                    type="text"
                    value={formData.platform_url}
                    placeholder={`Enter ${platform} link`}
                    containerStyle={{ marginTop: "0px" }}
                    inputStyle={{
                      "&:hover fieldset": {
                        border: "1px solid #6a1b9a",
                      },
                      "&.Mui-focused fieldset": {
                        border: "1px solid #6a1b9a",
                      },
                    }}
                    required
                    {...field}
                    error={!!errors.platform_url}
                    helperText={
                      errors.platform_url ? errors.platform_url.message : ""
                    }
                    onChange={(e) => {
                      field.onChange(e);
                      handleInputChange("platform_url", e.target.value);
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Controller
                name="referred_by"
                control={control}
                render={({ field }) => (
                  <FormInput
                    label="Referred by"
                    id="referred_by"
                    value={formData.referred_by}
                    type="text"
                    required={false}
                    fullWidth={false}
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                      handleInputChange("referred_by", e.target.value);
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={6}>
              <Controller
                name="employment_type"
                control={control}
                rules={{
                  required: "Employment type is required.",
                }}
                render={({ field }) => (
                  <SelectInput
                    label="Employment type"
                    id="employment_type"
                    name="employment_type"
                    options={EMPLOYMENT_TYPE_OPTIONS}
                    fullWidth={false}
                    required
                    error={!!errors.employment_type}
                    helperText={
                      errors.employment_type
                        ? errors.employment_type.message
                        : ""
                    }
                    value={formData.employment_type}
                    onChange={(e) => {
                      field.onChange(e);
                      handleInputChange("employment_type", e.target.value);
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Controller
                name="employment_status"
                control={control}
                rules={{
                  required: "Employment status is required.",
                }}
                render={({ field }) => (
                  <SelectInput
                    label="Employment Status"
                    id="employment_status"
                    name="employment_status"
                    options={EMPLOYMENT_STATUS_OPTIONS}
                    fullWidth={false}
                    required
                    error={!!errors.employment_status}
                    helperText={
                      errors.employment_status
                        ? errors.employment_status.message
                        : ""
                    }
                    value={formData.employment_status}
                    onChange={(e) => {
                      field.onChange(e);
                      handleInputChange("employment_status", e.target.value);
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          {(formData?.employment_status ===
            EMPLOYMENT_STATUS.CURRENTLY_EMPLOYED ||
            formData?.employment_status ===
              EMPLOYMENT_STATUS.EMPLOYED_AND_SEEKING_JOB) && (
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6}>
                {" "}
                <Controller
                  name="current_job_title"
                  control={control}
                  rules={{
                    required: "Current job title is required.",
                    minLength: {
                      value: 3,
                      message: "It must be at least 3 characters long",
                    },
                  }}
                  render={({ field }) => (
                    <FormInput
                      label=" Current job title"
                      id="current_job_title"
                      value={formData.current_job_title}
                      type="text"
                      placeholder="Developer, Manager, Analyst, Consultant"
                      required
                      {...field}
                      error={!!errors.current_job_title}
                      helperText={
                        errors.current_job_title
                          ? errors.current_job_title.message
                          : ""
                      }
                      onChange={(e) => {
                        field.onChange(e);
                        handleInputChange("current_job_title", e.target.value);
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Controller
                  name="current_company"
                  control={control}
                  rules={{
                    required: "Current company is required.",
                    minLength: {
                      value: 3,
                      message: "It must be at least 3 characters long",
                    },
                  }}
                  render={({ field }) => (
                    <FormInput
                      label=" Current Company"
                      id="current_company"
                      value={formData.current_company}
                      type="text"
                      placeholder="SAP, Siemens, BMW, Self-employed"
                      required
                      {...field}
                      error={!!errors.current_company}
                      helperText={
                        errors.current_company
                          ? errors.current_company.message
                          : ""
                      }
                      onChange={(e) => {
                        field.onChange(e);
                        handleInputChange("current_company", e.target.value);
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          )}

          {(formData?.employment_status ===
            EMPLOYMENT_STATUS.ACTIVELY_LOOKING_FOR_JOB ||
            formData?.employment_status ===
              EMPLOYMENT_STATUS.EMPLOYED_AND_SEEKING_JOB) && (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <Controller
                  name="preferred_position"
                  control={control}
                  rules={{
                    required: "Preferred position is required.",
                    minLength: {
                      value: 3,
                      message: "It must be at least 3 characters long",
                    },
                  }}
                  render={({ field }) => (
                    <FormInput
                      label="Preferred position"
                      id="preferred_position"
                      value={formData.preferred_position}
                      type="text"
                      placeholder="Developer, Manager, Analyst, Consultant"
                      required
                      {...field}
                      error={!!errors.preferred_position}
                      helperText={
                        errors.preferred_position
                          ? errors.preferred_position.message
                          : ""
                      }
                      onChange={(e) => {
                        field.onChange(e);
                        handleInputChange("preferred_position", e.target.value);
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Box mt={1}>
                  <Label>Salary range</Label>
                  <Controller
                    name="salary_range"
                    control={control}
                    render={({ field }) => (
                      <Slider
                        value={field.value || formData.salary_range}
                        onChange={(event, newValue) => {
                          field.onChange(newValue);
                          handleInputChange("salary_range", newValue);
                        }}
                        valueLabelDisplay="off"
                        min={10000}
                        max={200000}
                        step={1000}
                        getAriaValueText={(value) => `${value}$`}
                      />
                    )}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Label>€{formData.salary_range[0]}</Label>
                    <Label>€{formData.salary_range[1]}</Label>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          )}

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <Box sx={{ mt: 0.7 }}>
                <Label>Domain</Label>
                <Box>
                  {formData.domains.length > 0 ? (
                    <Box
                      sx={{ display: "flex", gap: "8px", alignItems: "center" }}
                    >
                      {formData.domains.map((domain) => (
                        <SelectedButton
                          key={domain}
                          variant="contained"
                          onClick={() => handleEdit("domain")}
                        >
                          {domain} <CloseIcon sx={{ ml: 1 }} />
                        </SelectedButton>
                      ))}
                    </Box>
                  ) : (
                    <AddButton
                      variant="contained"
                      onClick={() => handleSlideUpOpen("domain")}
                      startIcon={<AddOutlinedIcon />}
                    >
                      Add domain
                    </AddButton>
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Box sx={{ mt: 2 }}>
                <Label>Past companies</Label>
                <Box>
                  {formData.past_companies.length > 0 ? (
                    <Box
                      sx={{ display: "flex", gap: "8px", alignItems: "center" }}
                    >
                      {formData.past_companies.map((company) => (
                        <SelectedButton
                          key={company}
                          variant="contained"
                          onClick={() => handleEdit("companies")}
                        >
                          {company} <CloseIcon sx={{ ml: 1 }} />
                        </SelectedButton>
                      ))}
                    </Box>
                  ) : (
                    <AddButton
                      variant="contained"
                      onClick={() => handleSlideUpOpen("companies")}
                      startIcon={<AddOutlinedIcon />}
                    >
                      Add company
                    </AddButton>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <SlideUp
        open={slideUpOpen}
        onClose={handleSlideUpClose}
        content={slideUpContent}
        page="profile"
      />
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

const AvatarContainer = styled(Box)`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  z-index: 0;
`;

const UserContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  margin-top: 24px;
`;

const UserRole = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  font-size: 12px;
  font-family: Inter;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.24px;
  word-wrap: break-word;
`;

const UserDetails = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledRequiredMark = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.crimson};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`;

const StyledLable = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  font-size: 14px;
  font-weight: 400;
  word-wrap: break-word;
`;

const StyledDescription = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  font-size: 14px;
  font-weight: 600;
  word-wrap: break-word;
`;

const AddButton = styled(Button)`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.text.secondary};
  padding: 10px 16px;
  border-radius: 8px;
  text-transform:none;
  background:#fff;
  border:1px #71717A solid;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    width: auto;
`;

const StyledRadioLabel = styled("span")`
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  font-size: 14px;
  font-weight: 400;
  wordwrap: break-word;
`;

const StyledFormLabel = styled(FormLabel)`
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.14px;
  line-height: 20px;

  ${(props) =>
    props.sx &&
    css`
      ${props.sx}
    `}
`;

const StyledRadioGroup = styled(RadioGroup)`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
`;

const StyledRadioBox = styled(Box)`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  padding: 0;
`;

const StyledPhoneInfoBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledInputInfo = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[500]};
  font-size: 12px;
  font-weight: 400;
`;

const StyledVerifyLink = styled(Typography)`
  ${({ theme, isVerified }) => css`
    color: ${isVerified
      ? theme.palette.text.success
      : theme.palette.common.purple};
    font-size: 12px;
    font-weight: 500;
    text-decoration: ${isVerified ? "none" : "underline"};
    cursor: pointer;
  `}
`;

const SelectedButton = styled(Button)`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: white;
  padding: 4px 8px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  text-transform: none;
  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.dark};
  }
`;

import styled from "@emotion/styled";

import {
  Alert,
  Box,
  Container,
  Grid,
  Snackbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules"; // Import Autoplay
import { Swiper, SwiperSlide } from "swiper/react";
import { getAllEvents } from "../../api/Event/event";
import { getAllJobPosts } from "../../api/Job/job";
import { getAllPosts } from "../../api/Post/post";
import CustomSwiperPagination from "../../component/Common/CustomSwiperPagination";
import { EventCard } from "../../component/Events/EventCard";
import { EventCardSkeleton } from "../../component/Events/EventCardSkeleton";
import { JobsCard } from "../../component/Jobs/JobsCard";
import { JobCardSkeleton } from "../../component/Jobs/JobsCardSkeleton";
import { PostDialog } from "../../component/Posts/PostDialog";
import { PostsCard } from "../../component/Posts/PostsCard";
import { GET_STATUS } from "../../constants/constants";
import { JobPostDialog } from "../../component/Jobs/JobPostDialog";
import { CreateEventDialog } from "../../component/Events/CreateEventDialog";
import AddButton from "../../component/Common/AddButton";
import SearchInput from "../../component/Layout/SearchInput";
import { useTheme } from "@emotion/react";

export const Home = () => {
  const [openPostDialog, setOpenPostDialog] = useState(false);
  const [postsFetchingError, setPostsFetchingError] = useState("");
  const [jobFetchingError, setJobFetchingError] = useState("");
  const [jobFetchingStatus, setJobFetchingStatus] = useState();
  const [postsFetchingStatus, setPostsFetchingStatus] = useState();
  const [eventFetchingError, setEventFetchingError] = useState("");
  const [eventFetchingStatus, setEventFetchingStatus] = useState();
  const [openPostJobDialog, setOpenPostJobDialog] = useState(false);
  const [openCreateEventDialog, setOpenCreateEventDialog] = useState(false);
  const [events, setEvents] = useState([]);
  const [posts, setPosts] = useState([]);
  const [jobs, setJobs] = useState([]);

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const userId = useSelector((state) => state.user.userId);
  const { postActionId, jobPostActionId, eventActionId } = useSelector(
    (state) => state.eventListener
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const fetchPosts = async () => {
    try {
      setPostsFetchingError("");
      setPostsFetchingStatus(GET_STATUS.FETCHING);
      const response = await getAllPosts(limit, offset);
      if (response.status === 200) {
        const posts = response.data.data;
        // Remove duplicates from newJobs based on job.id
        // const uniqueJobs = [
        //   ...jobs,
        //   ...newJobs.filter(
        //     (job) =>
        //       !jobs.some(
        //         (existingJob) => existingJob.job_post_id === job.job_post_id
        //       )
        //   ),
        // ];
        setPosts(posts);
        // setOffset((prevOffset) => prevOffset + limit);
        // Determine if more posts are available
        // if (newJobs.length < limit) {
        //   setHasMore(false); // No more posts to load
        // }
        setPostsFetchingStatus(GET_STATUS.FETCHED);
      } else {
        setPostsFetchingError("Error while fetching posts.");
        setPostsFetchingStatus(GET_STATUS.FAILED);
      }
    } catch (error) {
      setPostsFetchingError("Error while fetching posts.");
      setPostsFetchingStatus(GET_STATUS.FAILED);
    }
  };

  const fetchJobPosts = async () => {
    try {
      setJobFetchingError("");
      setJobFetchingStatus(GET_STATUS.FETCHING);
      const response = await getAllJobPosts(4, 0);
      if (response.status === 200) {
        const newJobs = response.data.data;
        setJobs(newJobs);
        setJobFetchingStatus(GET_STATUS.FETCHED);
      } else {
        setJobFetchingError("Error while fetching jobs.");
        setJobFetchingStatus(GET_STATUS.FAILED);
      }
    } catch (error) {
      setJobFetchingError("Error while fetching jobs.");
      setJobFetchingStatus(GET_STATUS.FAILED);
    }
  };

  const fetchEventsPosts = async (tabIndex) => {
    try {
      setEventFetchingError("");
      setEventFetchingStatus(GET_STATUS.FETCHING);
      let eventType = "upcoming";
      const response = await getAllEvents(eventType, userId);
      if (response.status === 200) {
        const events = response.data.data;
        setEvents(events);
        setEventFetchingStatus(GET_STATUS.FETCHED);
      } else {
        setEventFetchingError("Error while fetching events.");
        setEventFetchingStatus(GET_STATUS.FAILED);
      }
    } catch (error) {
      setEventFetchingError("Error while fetching events.");
      setEventFetchingStatus(GET_STATUS.FAILED);
    }
  };

  const handleOpenSnackbar = (message, severity = "success") => {
    setSnackbar({
      open: true,
      message,
      severity,
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleOpenPostDialog = () => {
    setOpenPostDialog(true);
  };

  const handleClose = () => {
    setOpenPostDialog(false);
  };

  const handleOpenPostJobDialog = () => {
    setOpenPostJobDialog(true);
  };
  const handleOpenCreateEventDialog = () => {
    setOpenCreateEventDialog(true);
  };

  const handleClosePostJobDialog = () => {
    setOpenPostJobDialog(false);
  };

  const handleCloseCreateEventDialog = () => {
    setOpenCreateEventDialog(false);
  };

  useEffect(() => {
    fetchPosts();
    fetchJobPosts();
    fetchEventsPosts();
  }, [postActionId, jobPostActionId, eventActionId]);

  return (
    <Box sx={{ overFlowX: "hidden" }}>
      {isMobile ? (
        <SearchBoxContainer maxWidth="xl" sx={{ pb: 1.5 }}>
          <SearchInput placeholder="Search people by job title or industry" />
          <Box sx={{ display: "flex", alignItems: "center", gap: 1.5 }}>
            <AddButton onClick={handleOpenPostJobDialog} isFullWidth={true}>
              Post Job
            </AddButton>
            <AddButton isFullWidth={true} onClick={handleOpenCreateEventDialog}>
              Create Event
            </AddButton>
          </Box>
        </SearchBoxContainer>
      ) : (
        <FlexContainer>
          <FlexItem>
            <SearchInput placeholder="Search people by job title or industry" />
          </FlexItem>
          <ButtonContainer>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between", // Ensure equal space between buttons
                width: "100%",
                gap: "16px", // Adjust the gap between buttons
              }}
            >
              <AddButton
                onClick={handleOpenPostJobDialog}
                sx={{
                  width: "50%",
                }}
              >
                Post Job
              </AddButton>
              <AddButton
                onClick={handleOpenCreateEventDialog}
                sx={{
                  width: "50%",
                }}
              >
                Create Event
              </AddButton>
            </Box>
          </ButtonContainer>
        </FlexContainer>
      )}

      <Container maxWidth="xl" sx={{ py: 3 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={7} sx={{ order: { xs: 2, sm: 2, md: 1 } }}>
            <SectionTitle>Start a post</SectionTitle>
            <StyledPostBox onClick={handleOpenPostDialog}>
              <Typography>Write a post</Typography>
            </StyledPostBox>

            <SectionTitle mt={2}>Recent feed</SectionTitle>

            {postsFetchingStatus === GET_STATUS.FETCHING ? (
              <JobCardSkeleton />
            ) : postsFetchingStatus === GET_STATUS.FAILED ? (
              <Box>
                <Alert severity="error">
                  Error fetching posts. Please try again later.
                </Alert>
              </Box>
            ) : posts.length === 0 ? (
              <Box>
                <Alert severity="info">No posts found.</Alert>
              </Box>
            ) : (
              <Box>
                {posts.map((post) => (
                  <PostsCard
                    key={post?.post_id}
                    userName="Prasad Lad"
                    userRole="Senior Software Engineer"
                    post={post}
                    postId={post?.post_id}
                  />
                ))}
              </Box>
            )}
          </Grid>

          <Grid item xs={12} sm={5} sx={{ order: { xs: 1, sm: 1, md: 2 } }}>
            <SectionTitle>New jobs</SectionTitle>
            {jobFetchingStatus === GET_STATUS.FETCHING ? (
              <JobCardSkeleton />
            ) : jobFetchingStatus === GET_STATUS.FAILED ? (
              <Box mt={1}>
                <Alert severity="error">
                  Error fetching new jobs. Please try again later.
                </Alert>
              </Box>
            ) : jobs.length === 0 ? (
              <Box>
                <Alert severity="info">No new jobs found.</Alert>
              </Box>
            ) : (
              <CustomSwiperPagination>
                <Swiper
                  spaceBetween={30}
                  pagination={{ clickable: true }}
                  modules={[Pagination, Autoplay]}
                  centeredSlides={true}
                  autoplay={{ delay: 3000 }}
                >
                  {jobs.map((job) => (
                    <SwiperSlide>
                      <JobsCard key={jobs?.job_post_id} job={job} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </CustomSwiperPagination>
            )}
            <SectionTitle mt={2}>New events</SectionTitle>
            {eventFetchingStatus === GET_STATUS.FETCHING ? (
              <EventCardSkeleton /> // Show loading skeleton while fetching data
            ) : eventFetchingStatus === GET_STATUS.FAILED ? (
              <Box>
                <Alert severity="error">
                  Error fetching events. Please try again later.
                </Alert>
              </Box>
            ) : events.length === 0 ? (
              <Box>
                <Alert severity="info">No upcoming events found.</Alert>
              </Box>
            ) : (
              <CustomSwiperPagination>
                <Swiper
                  spaceBetween={30}
                  pagination={{ clickable: true }}
                  modules={[Pagination, Autoplay]}
                  centeredSlides={true}
                  autoplay={{ delay: 3000 }}
                >
                  {events.map((event) => (
                    <SwiperSlide>
                      <EventCard
                        key={event?.event_id}
                        image="/assets/images/events.png"
                        event={event}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </CustomSwiperPagination>
            )}
          </Grid>
        </Grid>
      </Container>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      <JobPostDialog
        openPostJobDialog={openPostJobDialog}
        handleClose={handleClosePostJobDialog}
        onOpenSnackbar={handleOpenSnackbar}
      />
      <CreateEventDialog
        openCreateEventDialog={openCreateEventDialog}
        handleClose={handleCloseCreateEventDialog}
        onOpenSnackbar={handleOpenSnackbar}
      />
      <PostDialog
        onOpenSnackbar={handleOpenSnackbar}
        openPostDialog={openPostDialog}
        handleClose={handleClose}
      />
    </Box>
  );
};

const SectionTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  font-size: 24px;
  font-weight: 500;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    font-size: 20px;
  }
`;

const StyledPostBox = styled(Box)`
  width: 100%;
  padding: 8px 12px;
  color: #a1a1a1;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid #a1a1aa;
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: 12px;
`;

const SearchBoxContainer = styled(Container)`
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.neutral[200]};
  border-top: 1px solid ${({ theme }) => theme.palette.border.neutral[200]};
`;

const FlexContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.neutral[200]};
  border-top: 1px solid ${({ theme }) => theme.palette.border.neutral[200]};

  ${({ theme }) => theme.breakpoints.down("sm")} {
    flex-direction: column; // Stack items vertically on small screens
    padding: 0 12px; // Adjust padding for small screens
    width: 100%; // Ensure full width
  }
`;

const FlexItem = styled(Box)`
  flex: 1;
  width: 100%;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin-bottom: 16px; // Add space between FlexItem and buttons on small screens
  }
`;

const ButtonContainer = styled(Box)`
  display: flex;
  gap: 16px;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    flex-direction: column; // Stack buttons vertically on mobile screens
    width: 100%; // Ensure ButtonContainer takes full width on mobile screens
    gap: 0px; // Reduce gap for small screens
  }
`;

import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  FormControl,
  FormLabel,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { RequiredMark } from "./RequiredMark";
import StyledHelperText from "./StyledHelperText";

const FormInput = ({
  label,
  value,
  onChange,
  type = "text",
  autoComplete,
  required = false,
  fullWidth = true,
  autoFocus = false,
  margin = "normal",
  placeholder,
  containerStyle,
  inputStyle,
  error,
  helperText,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const showPasswordToggle = type === "password";

  return (
    <StyledFormControl
      fullWidth={fullWidth}
      margin={margin}
      sx={containerStyle}
    >
      {label && (
        <StyledFormLabel error={error}>
          {required && <RequiredMark />}
          {label}
        </StyledFormLabel>
      )}
      <StyledTextField
        required={required}
        fullWidth={fullWidth}
        sx={inputStyle}
        value={value}
        onChange={onChange}
        type={showPassword ? "text" : type}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        variant="outlined"
        placeholder={placeholder}
        error={error}
        InputProps={{
          ...(showPasswordToggle && {
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={togglePasswordVisibility}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }),
        }}
        {...props}
      />
      {helperText && (
        <StyledHelperText error={error}>{helperText}</StyledHelperText>
      )}
    </StyledFormControl>
  );
};

export default FormInput;

const StyledFormControl = styled(FormControl)`
  width: 100%;
  margin-top: 8px;

  .MuiOutlinedInput-root {
    &:hover fieldset {
      border: none;
    }
    &.Mui-focused fieldset {
      border: none;
    }
  }

  ${(props) =>
    props.sx &&
    css`
      ${props.sx}
    `}
`;

const StyledFormLabel = styled(FormLabel)`
  color: ${({ theme, error }) =>
    error
      ? theme.palette.text.negative[600] + " !important"
      : theme.palette.text.neutral[400]};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.14px;
`;

const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.palette.border.neutral[400]};
    margin-top: 4px;
    font-size: 14px;
    font-weight: 400;
    border: none;

    &:hover fieldset {
      border: 1px solid ${({ theme }) => theme.palette.common.purple};
    }
    &.Mui-focused fieldset {
      border: 1px solid ${({ theme }) => theme.palette.common.purple};
    }
  }

  .MuiOutlinedInput-input {
    padding: 10px 12px;
  }
  ${(props) =>
    props.sx &&
    css`
      ${props.sx}
    `}
`;

import { createSlice } from "@reduxjs/toolkit";
import { HP_POST_STATUS } from "../../constants/constants";

const initialState = {
  title: "",
  description: "",
  post_status: HP_POST_STATUS.INIT,
};

const postSlice = createSlice({
  name: "post",
  initialState,
  reducers: {
    setPostData: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetPostState: () => initialState,
  },
});

export const { setPostData, resetPostState } = postSlice.actions;
export default postSlice.reducer;


import styled from "@emotion/styled";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {
  Alert,
  Avatar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { updatePost } from "../../api/Post/post";
import { POST_STATUS } from "../../constants/constants";
import { resetPostState, setPostData } from "../../redux/Slices/postSlice";
import FormBtn from "../Common/FormBtn";
import FormInput from "../Common/FormInput";
import { Label } from "../Common/Label";
import { RequiredMark } from "../Common/RequiredMark";
import StyledHelperText from "../Common/StyledHelperText";
import { setActionData } from "../../redux/Slices/eventListnerSlice";
import dayjs from "dayjs";

export const EditPostDialog = ({
  openPostDialog,
  handleClose,
  onOpenSnackbar,
  post,
}) => {
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.post);
  const [postingError, setPostingError] = useState("");
  const [postingStatus, setPostingStatus] = useState();
  const eventListenerData = useSelector((state) => state.eventListener);
  const { userId, email, firstName, lastName } = useSelector(
    (state) => state.user
  );

  const {
    control,
    handleSubmit,
    trigger,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: formData,
  });

  const handleEditPostSuccess = () => {
    setPostingStatus(POST_STATUS.SAVED);
    dispatch(resetPostState());
    dispatch(
      setActionData({
        ...eventListenerData,
        postActionId: `post_edit_${post?.post_id}_${dayjs()}`,
      })
    );
    onOpenSnackbar("Post updated successfully!", "success");
    handleClose();
  };

  const handleEditPostFailure = () => {
    setPostingError("Error while updating post.");
    setPostingStatus(POST_STATUS.FAILED);
    onOpenSnackbar("Error while updating post.", "error");
    handleClose();
  };

  const onSubmit = async (data) => {
    if (data.title === post?.title && data.description === post?.description) {
      setPostingError("Please modify the post before updating.");
      return;
    }

    try {
      setPostingError("");
      const postData = {
        ...data,
        post_status: post?.post_status,
        created_by: post?.created_by,
      };
      setPostingStatus(POST_STATUS.POSTING);
      const response = await updatePost(post?.post_id, postData);
      if (response.status === 200) {
        handleEditPostSuccess();
      } else {
        handleEditPostFailure();
      }
    } catch (error) {
      handleEditPostFailure();
    }
  };

  const handleInputChange = async (name, value) => {
    setPostingError("");
    dispatch(
      setPostData({
        ...formData,
        [name]: value,
      })
    );
    await trigger(name);
  };

  const handleFormSubmit = () => {
    handleSubmit(onSubmit)();
  };

  useEffect(() => {
    if (openPostDialog) {
      reset(formData);
    }
  }, [openPostDialog, formData, reset]);

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={openPostDialog}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <CardContainer component="div" noValidate>
        <DialogTitle>
          <Header>
            <UserInfo>
              <Avatar src="/assets/images/avatar-1.png" />
              <UserDetails>
                <UserName>{`${firstName} ${lastName}`}</UserName>
                <UserEmail>{email}</UserEmail>
              </UserDetails>
            </UserInfo>
            <CloseOutlinedIconStyled onClick={handleClose} />
          </Header>
        </DialogTitle>
        <DialogContent>
          {postingError && (
            <Box>
              <Alert severity="info">{postingError}</Alert>
            </Box>
          )}

          <Box mt={1}>
            <Controller
              name="title"
              control={control}
              rules={{
                required: "Post title is required.",
                minLength: {
                  value: 3,
                  message: "Post title must be between 3-75 characters long",
                },
                maxLength: {
                  value: 70,
                  message: "Post title must be between 3-75 characters long",
                },
              }}
              render={({ field }) => (
                <FormInput
                  label="Post title"
                  id="title"
                  type="text"
                  value={formData.title}
                  placeholder="Application of Gen-AI"
                  required
                  {...field}
                  error={!!errors.title}
                  helperText={errors.title ? errors.title.message : ""}
                  onChange={(e) => {
                    field.onChange(e);
                    handleInputChange("title", e.target.value);
                  }}
                />
              )}
            />
          </Box>

          <Box mt={1}>
            <Controller
              name="description"
              control={control}
              rules={{
                required: "Description is required.",
                minLength: {
                  value: 50,
                  message: "Description must be between 50-500 characters long",
                },
                maxLength: {
                  value: 500,
                  message: "Description must be between 50-500 characters long",
                },
              }}
              render={({ field }) => (
                <>
                  <Label error={errors.description}>
                    <RequiredMark />
                    Post description
                  </Label>
                  <TextArea
                    id="description"
                    value={formData.description}
                    name="description"
                    rows="10"
                    placeholder="Short post description"
                    required
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                      handleInputChange("description", e.target.value);
                    }}
                  />
                  <StyledHelperText error={errors.description}>
                    {errors?.description?.message}
                  </StyledHelperText>
                </>
              )}
            />
          </Box>
        </DialogContent>
        <DialogActionsBox>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}></Grid>
            <Grid item xs={12} sm={12} md={6}>
              <FormBtn
                sx={{ mb: { xs: 1, sm: 1, md: 2 } }}
                onClick={handleFormSubmit}
                disabled={postingStatus === POST_STATUS.POSTING}
                loading={postingStatus === POST_STATUS.POSTING}
              >
                Update post
              </FormBtn>
            </Grid>
          </Grid>
        </DialogActionsBox>
      </CardContainer>
    </Dialog>
  );
};

// Styled Components using Emotion
const CardContainer = styled(Box)`
  background: white;
  border-radius: 8px;
`;

const Header = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const UserInfo = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const UserDetails = styled(Box)`
  margin-left: 8px;
`;

const UserName = styled(Typography)`
  font-size: 20px;
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  font-weight: 600;
  word-wrap: break-word;
  text-transform: capitalize;
`;

const UserEmail = styled(Typography)`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  font-weight: 400;
  word-wrap: break-word;
  text-transform: capitalize;
`;

const CloseOutlinedIconStyled = styled(CloseOutlinedIcon)`
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  padding: 8px;
  cursor: pointer;
`;

const TextArea = styled.textarea`
  width: 100%;
  box-sizing: border-box;
  background: #fff;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.palette.text.secondary};
  padding: 8px;
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 14px;
  font-weight: 400;
  font-family: "Inter";
`;

const DialogActionsBox = styled(DialogActions)`
  margin: 0px 16px;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin: 0px 16px 16px 16px;
  }
`;

import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Alert, Box, Container, Grid, Paper, Typography } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { useTheme } from "@mui/material/styles";
import styled from "@emotion/styled";
import FormBtn from "../../component/Common/FormBtn";
import FormInput from "../../component/Common/FormInput";
import TextLink from "../../component/Common/TextLink";
import { TermsAndPolicyFooter } from "../../component/Common/TermsAndPolicyFooter";
import CorosoleContent from "../../component/OnBoarding/CorosoulContent";
import { Link, useNavigate } from "react-router-dom";
import { signIn } from "../../api/Auth/auth";
import { LOADING_STATUS, POST_STATUS } from "../../constants/constants";
import { StyledFormApiErrorText } from "../../component/Common/StyledFormApiErrorText";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { setUserProfile } from "../../redux/Slices/userSlice";
import { jwtDecode } from "jwt-decode";

export const SignIn = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
  });

  const userData = useSelector((state) => state.user);
  const [signInError, setSignInError] = useState("");
  const [signInStatus, setSignInStatus] = useState();
  const {
    handleSubmit,
    control,
    trigger,
    formState: { errors },
  } = useForm();

  const onSubmit = async () => {
    setSignInStatus(POST_STATUS.INITIAL);

    const user = {
      email: formValues.email,
      password: formValues.password,
    };

    try {
      setSignInStatus(POST_STATUS.POSTING);
      const response = await signIn(user);

      if (response.status === 200) {
        const { userId, accessToken, refreshToken } = response?.data?.data;
        Cookies.set("accessToken", accessToken);
        Cookies.set("refreshToken", refreshToken);
        const decodedAccessToken = jwtDecode(accessToken);
        dispatch(
          setUserProfile({
            ...userData,
            userId,
            email: decodedAccessToken.email,
            firstName: decodedAccessToken.firstName,
            lastName: decodedAccessToken.lastName,
            country: decodedAccessToken.country,
            state: decodedAccessToken.state,
            city: decodedAccessToken.city,
            isUserVerified: decodedAccessToken.isUserVerified,
            isPhoneVerified: decodedAccessToken.isPhoneVerified,
            isEmailVerified: decodedAccessToken.isEmailVerified,
            isUserOnboarded: decodedAccessToken.isUserOnboarded,
          })
        );
        setSignInStatus(POST_STATUS.SAVED);
        navigate("/");
      } else if (response.status === 401) {
        setSignInError("Invalid credentials.");
        setSignInStatus(POST_STATUS.FAILED);
      } else {
        setSignInError("Unexpected error occurred.");
        setSignInStatus(POST_STATUS.FAILED);
      }
    } catch (error) {
      console.log(error);
      setSignInError("Error while login.");
      setSignInStatus(POST_STATUS.FAILED);
    }
  };

  const handleInputChange = async (name, value) => {
    setSignInError("");
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
    await trigger(name);
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid item xs={12} sm={6} md={6} component={Paper} elevation={6} square>
        <Container maxWidth="sm">
          <Box
            sx={{
              mx: { xs: 1, sm: 4, md: 8 },
              my: { xs: 4, sm: 6, md: 10 },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              component="form"
              onSubmit={handleSubmit(onSubmit)}
              sx={{ mt: 1 }}
            >
              <Box sx={{ alignSelf: "flex-start", mb: 2 }}>
                <StyledTitle>Login</StyledTitle>
                <StyledSubTitle>
                  or
                  <StyledJoinLink to={"/signup"}>
                    Join the community
                  </StyledJoinLink>
                </StyledSubTitle>
              </Box>

              {signInError && (
                <Alert sx={{ mb: 1 }} severity="error">
                  {signInError}
                </Alert>
              )}

              <Controller
                name="email"
                control={control}
                defaultValue=""
                rules={{
                  required: "Email is required",
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    message: "Invalid email address",
                  },
                }}
                render={({ field }) => (
                  <FormInput
                    label="Email"
                    id="email"
                    type="email"
                    placeholder="Enter valid e-mail id"
                    required
                    error={!!errors.email}
                    helperText={errors.email ? errors.email.message : ""}
                    value={formValues.email}
                    onChange={(e) => {
                      field.onChange(e);
                      handleInputChange("email", e.target.value);
                    }}
                  />
                )}
              />

              <Controller
                name="password"
                control={control}
                defaultValue=""
                rules={{
                  required: "Password is required",
                }}
                render={({ field }) => (
                  <FormInput
                    label="Password"
                    id="password"
                    type="password"
                    required
                    error={!!errors.password}
                    helperText={errors.password ? errors.password.message : ""}
                    value={formValues.password}
                    onChange={(e) => {
                      field.onChange(e);
                      handleInputChange("password", e.target.value);
                    }}
                  />
                )}
              />

              <Box sx={{ mt: 1, display: "flex", justifyContent: "flex-end" }}>
                <TextLink to={"/forgot-passward"} title="Forgot password" />
              </Box>

              <Box sx={{ mt: 2 }}>
                <FormBtn
                  type="submit"
                  disabled={signInStatus === POST_STATUS.POSTING}
                  loading={signInStatus === POST_STATUS.POSTING}
                >
                  Login
                </FormBtn>
              </Box>
              <TermsAndPolicyFooter />
            </Box>
          </Box>
        </Container>
      </Grid>
      <Grid
        item
        xs={false}
        sm={6}
        md={6}
        sx={{
          display: { xs: "none", sm: "block" },
          backgroundColor: theme.palette.primary[200],
        }}
      >
        <CorosoleContent />
      </Grid>
    </Grid>
  );
};

const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  font-size: 24px;
  font-style: normal;
  font-weight: 500;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    font-size: 16px;
  }
`;

const StyledSubTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.neutral[700]};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
`;

const StyledJoinLink = styled(Link)`
  color: ${({ theme }) => theme.palette.primary[600]};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  text-decoration-line: underline;
  cursor: pointer;
  margin-left: 6px;
`;
